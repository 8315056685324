<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Cajero
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text Cajero
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#01458c", centered, dark)
          //- v-tab(:key='0', ripple='') Facturación
          v-tab(:key='1', ripple='') Reporte de Pago
          //- v-tab-item(:key='0')
          //-   v-card(flat='')
              //- v-flex(xs12)
              //-   v-dialog(v-model='dialog', persistent max-width='700px')
              //-         v-container(style='background-color:white !important;max-width: 700px;')
              //-           v-card-title.justify-center
              //-               span.headline Registro de Transacción
              //-           div(style="margin-top: 20px;")
              //-               v-flex(xs12 sm12)
              //-                   v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-                       v-flex(xs8)
              //-                         v-form(enctype="multipart/form-data")
              //-                           v-select(id="categoria"
              //-                           outline
              //-                           :items="options"
              //-                           v-model="form_bank.categoria"
              //-                           prepend-inner-icon="chevron_right",
              //-                           label="Seleccione la categoría de la cuenta:")
                                        
              //-                           v-text-field(id="nombre"
              //-                           v-if='form_bank.categoria === "Efectivo" || form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form_bank.nombre"
              //-                           prepend-inner-icon="description"
              //-                           label="Nombre:")
                                        
              //-                           v-text-field(id="apellido"
              //-                           v-if='form_bank.categoria === "Efectivo" || form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form_bank.apellido"
              //-                           prepend-inner-icon="description"
              //-                           label="Apellido:")

              //-                           v-text-field(id="identificacion"
              //-                           v-if='form_bank.categoria === "Efectivo" || form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form_bank.identificacion"
              //-                           prepend-inner-icon="description"
              //-                           label="Identificación:")
                                        
              //-                           v-text-field(id="telefono"
              //-                           v-if='form_bank.categoria === "Efectivo" || form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form.telefono"
              //-                           prepend-inner-icon="description"
              //-                           label="Teléfono:")

              //-                           v-text-field(id="banco"
              //-                           v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional"'
              //-                           outline
              //-                           v-model="form_bank.banco"
              //-                           prepend-inner-icon="description"
              //-                           label="Nombre del banco:")
                                        
              //-                           v-text-field(id="referencia"
              //-                           v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form_bank.referencia"
              //-                           prepend-inner-icon="description"
              //-                           label="Número de referencia:")

              //-                           v-text-field(id="correo"
              //-                           v-if='form_bank.categoria === "Efectivo" || form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
              //-                           outline
              //-                           v-model="form_bank.correo"
              //-                           prepend-inner-icon="description"
              //-                           label="Correo electrónico:")

              //-                           div
              //-                             v-flex(v-if="loading === true", xs12 sm12 md12)
              //-                               v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-                                 v-progress-circular(indeterminate='', color='primary')
              //-                             v-flex(v-if="loading === false", xs12 sm12 md12)
              //-                               v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-                                 v-btn(style="background:grey;border-radius:10px;", @click.native='save') Guardar
              //-                                 v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
              //-   //- v-dialog(v-model='dialog', persistent max-width='700px')
              //-   //-   v-container(style='background-color:white !important;max-width: 700px;')
              //-   //-     div(style="margin-top: 20px;")
              //-   //-       v-flex(xs12, sm12, md12)
              //-   //-         v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-   //-           v-flex(xs12, md6, sm6)
              //-   //-             v-form(enctype="multipart/form-data")

              //-   //-               v-text-field(id="Nombre"
              //-   //-               outline
              //-   //-               required
              //-   //-               v-model="form.cliente"
              //-   //-               prepend-inner-icon="person"
              //-   //-               label="Nombre del Cliente")

              //-   //-               v-text-field(id="Telefono"
              //-   //-               outline
              //-   //-               required
              //-   //-               v-model="form.telefono"
              //-   //-               prepend-inner-icon="person"
              //-   //-               label="Teléfono")

              //-   //-               v-text-field(id="Dirección"
              //-   //-               outline
              //-   //-               required
              //-   //-               v-model="form.address"
              //-   //-               prepend-inner-icon="person"
              //-   //-               label="Dirección")

              //-   //-               //- v-text-field(id="Tarjeta"
              //-   //-               //- outline
              //-   //-               //- required
              //-   //-               //- v-model="card.number"
              //-   //-               //- prepend-inner-icon="credit_card"
              //-   //-               //- label="Número de tarjeta")

              //-   //-               //- template
              //-   //-               //-   v-row
              //-   //-               //-     v-col(cols='11' sm='5')
              //-   //-               //-       v-menu(ref='menu' v-model='menu' :close-on-content-click='false' :return-value.sync='date' transition='scale-transition' offset-y='' max-width='290px' min-width='290px')
              //-   //-               //-         template(v-slot:activator='{ on }')
              //-   //-               //-           v-text-field(v-model='card.expiry' outline label='Fecha de vencimiento' prepend-inner-icon='event' readonly='' v-on='on')
              //-   //-               //-         v-date-picker(v-model='date' type='month' no-title='' scrollable='' locale='es')
              //-   //-               //-           v-spacer
              //-   //-               //-           v-btn(color='grey' @click='menu = false') Cancel
              //-   //-               //-           v-btn(color='blue' @click='$refs.menu.save(date)') OK

              //-   //-               //- v-text-field(id="CVC"
              //-   //-               //- outline
              //-   //-               //- required
              //-   //-               //- v-model="card.cvc"
              //-   //-               //- prepend-inner-icon="credit_card"
              //-   //-               //- label="Codigo CVC")

              //-   //-               div(ref='card')

              //-   //-               div
              //-   //-                 v-flex(v-if="loading === true", xs12 sm12 md12 style='margin-top:10px')
              //-   //-                   v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-   //-                     v-progress-circular(indeterminate='', color='#01458c')
              //-   //-                 v-flex(v-if="loading === false", xs12 sm12 md12)
              //-   //-                   v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-   //-                     v-btn(style="background:grey;border-radius:10px;" @click='save()') Guardar
              //-   //-                     v-btn(style="background:#01458c;border-radius:10px;" @click='close()') Salir
              //-   v-container
              //-     v-flex(xs12)
              //-       v-text-field(v-if="cre" id="sku" ref="sku2" v-model="sku" prepend-inner-icon="description" label="SKU:" v-on:keyup.enter="save")
              //-     //- v-flex(xs12 style='padding: 5px;display:flex;justify-content:center')
              //-     //-   span(style='font-weight: 600;text-decoration: underline;') Facturación
              //-     v-flex(xs12 style='padding: 5px;')
              //-       v-layout(wrap style='display:flex;justify-content:center')
              //-         v-card(style='width: 85%;height: auto;border-radius: 10px;').elevation-3
              //-           div(style='height:303px;overflow-y: auto;')
              //-             div(v-for='(item,i) in form.products' style='padding: 10px;border-bottom: 1px dashed grey;position: relative;')
              //-               v-flex(xs12)
              //-                 v-layout(wrap)
              //-                   v-flex(xs1 style='display: flex;justify-content: center;')
              //-                     img(:src='ruta + item.imagen[0].url', v-if='item.imagen[0].url' style='width: 90px;height: 80px;')
              //-                   v-flex(xs8 style='display: grid;align-content: center;line-height: 1;padding-left: 20px;margin-top: 8px;')
              //-                     p(style='font-size: 12px') <b style='font-size:13px !important'> Producto: </b> {{item.nombre}}
              //-                     p(style='font-size: 12px') <b style='font-size:13px !important'> Sku: </b> {{item.sku}}
              //-                     v-layout(wrap)
              //-                       v-flex(xs2)
              //-                         p(style='font-size: 12px') <b style='font-size:13px !important'> Cantidad: </b> {{item.cantidad}}
              //-                       v-flex(xs10)
              //-                         div
              //-                           v-btn(@click='agregar(i)' color='gren').btnnn5 +
              //-                         div(style='margin-left:25px')
              //-                           v-btn(@click='quitar(i)' color='error').btnnn6 -
              //-                   v-flex(xs3 style='display: flex;justify-content: flex-end;align-items: center;padding-right:20px')
              //-                     span(style='font-size: 14px;font-weight: 600;') {{item.cantidad * item.precio}}$
              //-                 div(style='top: 2px;right: 3px;position:absolute;width:100%;display:flex;justify-content:flex-end')
              //-                   v-btn(@click="indice(i)",color='error').btnnn4 X
              //-           div(style='padding: 10px;')
              //-             v-flex(xs12 style='line-height: 1;padding-top: 10px;')
              //-               v-layout(wrap)
              //-                 v-flex(xs6 style='display:grid;padding-left: 20px;')
              //-                   p(style='font-size: 14px !important;font-weight: 600;') Sub-Total:
              //-                   p(style='font-size: 14px !important;font-weight: 600;') Iva:
              //-                   p(style='font-size: 14px !important;font-weight: 600;') Total:
              //-                 v-flex(xs6 style='display:grid;justify-content:flex-end;padding-right:20px')
              //-                   p(style='font-size: 14px !important;font-weight: 500;') {{subtotal}}$
              //-                   p(style='font-size: 14px !important;font-weight: 500;') {{iva}}$
              //-                   p(style='font-size: 14px !important;font-weight: 500;') {{total}}$
              //-     v-flex(xs12 style='padding: 5px;display:flex;justify-content:center' v-if='form.products.length > 0')
              //-       v-btn.mb-2(style="border-radius:10px;background-color:#01458c;" @click='abrir()') Procesar
              //-   v-container
              //-     v-flex(xs12)
              //-       v-layout(row='' wrap='')
              //-         v-flex(xs2 v-for='item in pdnts' style='padding: 10px;')
              //-           a(v-if='item.state === "Pendiente"')
              //-             v-badge(left=true style='width: 95%;' color="#fab824")
              //-               template(v-slot:badge='')
              //-                 v-icon(style='color:white')
              //-                   | pause_circle_outline
              //-               v-card(style='width:100%;height:60px;background:#01458c;display: flex;justify-content: center;align-items: center;').elevation-3
              //-                 span(style='color: white;font-size: 15px;font-weight: bold;text-align: center;') {{item.cliente}}
              //-           a(v-if='item.state === "Rechazado"' @click='ver_detalle(item)')
              //-             v-badge(left=true style='width: 95%;' color="red")
              //-               template(v-slot:badge='')
              //-                 v-icon(style='color:white')
              //-                   | remove_shopping_cart
              //-               v-card(style='width:100%;height:60px;background:#01458c;display: flex;justify-content: center;align-items: center;').elevation-3
              //-                 span(style='color: white;font-size: 15px;font-weight: bold;text-align: center;') {{item.cliente}}
              //-   v-dialog(v-model='dialog2', persistent max-width='500px')
              //-     v-container(style='background-color:white !important;max-width: 500px;')
              //-       v-card-title.justify-center
              //-         span.headline Nota de Rechazo
              //-       div(style="margin-top: 20px;")
              //-         v-flex(xs12 sm12)
              //-           v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-             v-textarea(id="nota"
              //-               height='140'
              //-               outline
              //-               readonly
              //-               v-model="nota_rech"
              //-               prepend-inner-icon="description"
              //-               label="Nota:")
              //-         v-flex(xs12 sm12 md12)
              //-           v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              //-             v-btn(style="background:green;border-radius:10px;", @click.native='cerrar_detalle') Reordenar
              //-             v-btn(style="background:#fab824;border-radius:10px;", @click.native='cerrar_detalle') Finalizar
              //-             v-btn(style="background:#01458c;border-radius:10px;", @click.native='cerrar_detalle') Salir
          v-tab-item(:key='1')
            v-card(flat='')
              v-flex(xs12 style='height:60vh')
                v-layout(align-center='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:#01458c;border-radius:10px;", @click.native='dialog3=true') Reportar
              v-flex(xs12)
                v-dialog(v-model='dialog3', persistent max-width='700px')
                  v-container(style='background-color:white !important;max-width: 700px;')
                    v-card-title.justify-center
                        span.headline Registro de Transacción
                    div(style="margin-top: 20px;")
                        v-flex(xs12 sm12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs8)
                                  v-form(enctype="multipart/form-data")
                                    v-select(id="categoria"
                                    outline
                                    :items="options2"
                                    v-model="form_bank.categoria"
                                    prepend-inner-icon="chevron_right",
                                    label="Seleccione la categoría de la cuenta:")
                                    
                                    v-text-field(id="nombre"
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.nombre"
                                    prepend-inner-icon="description"
                                    label="Nombre:")
                                    
                                    v-text-field(id="apellido"
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.apellido"
                                    prepend-inner-icon="description"
                                    label="Apellido:")

                                    v-text-field(id="identificacion"
                                    v-if=' form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.identificacion"
                                    prepend-inner-icon="description"
                                    label="Identificación:")
                                    
                                    v-text-field(id="telefono"
                                    v-if=' form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.telefono"
                                    prepend-inner-icon="description"
                                    label="Teléfono:")

                                    v-select(id="banke"
                                    outline
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional"'
                                    :items="bank_e"
                                    v-model="form_bank.banco"
                                    prepend-inner-icon="chevron_right",
                                    label="Seleccione el banco emisor:")

                                    v-select(id="bankr"
                                    outline
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional"'
                                    :items="bank_r"
                                    v-model="form_bank.banco_r"
                                    prepend-inner-icon="chevron_right",
                                    label="Seleccione el banco receptor:")
                                    
                                    v-text-field(id="referencia"
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.referencia"
                                    prepend-inner-icon="description"
                                    label="Número de referencia:")

                                    v-menu(v-model='menu2'
                                    :close-on-content-click='false'
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    :nudge-right='40'
                                    transition='scale-transition'
                                    offset-y=''
                                    min-width='290px')
                                      template(v-slot:activator='{ on }')
                                        v-text-field(v-model='form_bank.fecha' outline label='Fecha' prepend-inner-icon='event' readonly='' v-on='on')
                                      v-date-picker(v-model='date2' @input='menu2 = false' locale='es' dark='')

                                    v-text-field(id="correo"
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    v-model="form_bank.correo"
                                    prepend-inner-icon="description"
                                    label="Correo electrónico:")

                                    v-text-field(id="monto"
                                    v-if='form_bank.categoria === "Transferencia Nacional" || form_bank.categoria === "Transferencia Internacional" || form_bank.categoria === "Zelle"'
                                    outline
                                    type="number"
                                    min='0'
                                    v-model="form_bank.monto"
                                    prepend-inner-icon="description"
                                    label="Monto:")

                                    div
                                      v-flex(v-if="loading === true", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-progress-circular(indeterminate='', color='primary')
                                      v-flex(v-if="loading === false", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-btn(style="background:grey;border-radius:10px;", @click.native='save_pago') Guardar
                                          v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
              v-container
                v-flex(xs12)
                  v-layout(row='' wrap='')
                    v-flex(xs2 v-for='item in pdnts2' style='padding: 10px;')
                      a(v-if='item.estatus === "Pendiente"')
                        v-badge(left=true style='width: 95%;' color="#fab824")
                          template(v-slot:badge='')
                            v-icon(style='color:white')
                              | pause_circle_outline
                          v-card(style='width:100%;height:60px;background:#01458c;display: flex;justify-content: center;align-items: center;').elevation-3
                            span(style='color: white;font-size: 15px;font-weight: bold;text-align: center;') {{item.nombre}} {{item.apellido}}
                      a(v-if='item.estatus === "Rechazado"' @click='ver_detalle2(item)')
                        v-badge(left=true style='width: 95%;' color="red")
                          template(v-slot:badge='')
                            v-icon(style='color:white')
                              | remove_shopping_cart
                          v-card(style='width:100%;height:60px;background:#01458c;display: flex;justify-content: center;align-items: center;').elevation-3
                            span(style='color: white;font-size: 15px;font-weight: bold;text-align: center;') {{item.nombre}} {{item.apellido}}
                      a(v-if='item.estatus === "Aprobado"' @click='ver_detalle2(item)')
                        v-badge(left=true style='width: 95%;' color="green")
                          template(v-slot:badge='')
                            v-icon(style='color:white')
                              | shopping_cart
                          v-card(style='width:100%;height:60px;background:#01458c;display: flex;justify-content: center;align-items: center;').elevation-3
                            span(style='color: white;font-size: 15px;font-weight: bold;text-align: center;') {{item.nombre}} {{item.apellido}}
                v-dialog(v-model='dialog4', persistent max-width='500px')
                  v-container(style='background-color:white !important;max-width: 500px;')
                    v-card-title(v-if='nota_rech2.nota').justify-center
                      span.headline Nota de Rechazo
                    div(style="margin-top: 20px;")
                      v-flex(xs12 sm12 v-if='nota_rech2.nota')
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                          v-textarea(id="nota"
                            height='140'
                            outline
                            readonly
                            v-model="nota_rech2.nota"
                            prepend-inner-icon="description"
                            label="Nota:")
                      v-flex(xs12 sm12 md12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                          v-btn(style="background:green;border-radius:10px;", @click.native='finalizar_detalle') Finalizar
                          v-btn(style="background:#01458c;border-radius:10px;", @click.native='cerrar_detalle') Salir

</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mCajero",
  components: {
  },
  data: vm => ({
    date2: new Date().toISOString().substr(0, 10),
    menu2: false,
    active: null,
    search: '',
    dialog2: false,
    dialog3: false,
    stripe: '',
    pdnts: [],
    dialog4: false,
    pdnts2: [],
    loading: false,
    elements: '',
    card: undefined,
    date: '',
    menu: false,
    sku:'',
    options: ["Efectivo", "Transferencia Nacional", "Transferencia Internacional", "Zelle"],
    options2: ["Transferencia Nacional", "Transferencia Internacional", "Zelle"],
    form_bank: {
      nombre: '',
      apellido: '',
      identificacion: '',
      referencia: '',
      banco: '',
      banco_r:"",
      categoria: '',
      correo: '',
      monto: "",
      fecha: vm.formatDate2(new Date().toISOString().substr(0, 10)),
      cajero: "",
      telefono: "",
      visto: false,
    },
    loading: false,
    defaultBank: {
      nombre: '',
      apellido: '',
      identificacion: '',
      referencia: '',
      banco: '',
      categoria: '',
      correo: '',
      monto: "",
      fecha: vm.formatDate2(new Date().toISOString().substr(0, 10)),
      cajero: "",
      banco_r:"",
      telefono: "",
      visto: false
    },
    nota_rech: "",
    nota_rech2: "",
    form: {
      cajero: "",
      cliente: "",
      telefono: "",
      address: "",
      products: [],
      iva: "", 
      subtotal: "",
      payment: "",
      token: "",
      total: '',
    },
    subtotal:0.00,
    settings: {},
    dialog: false,
    iva:0.00,
    total:0.00,
    sett_iva: 0,
    card: {
      cvc: '',
      number: '',
      expiry: ''
    },
    productos: [],
    bank_e: [],
    bank_r: [],
    produc_fac: [],
    dialog: false,
    stripe: null,
    ruta: server + ":" + port,
    defaultForm: {
      cajero: "",
      cliente: "",
      telefono: "",
      address: "",
      products: [],
      iva: "", 
      subtotal: "",
      token: "",
      payment: "",
      total: '',
    },
    nombres_recomendacion: [],
    imagenes_recomendacion: [],
    descripcion_recomendacion: [],
    searchsku: '',
    cre:"",
    mod:"",
    elm: ""
  }),
  sockets: {
    caja(data) {
      Api.get('pagos/caja/'+this.$store.state.merkafur._id).then(res=>{
        this.pdnts2 = res.data
      })
    }
  },
  created() {
    this.verificacion()
    this.initialize();
  },
  watch: {
    date2 (val) {
      this.form_bank.fecha = this.formatDate2(this.date2)
    },
    'date'(data) {
      this.card.expiry = this.formatDate(this.date)
    },
    'sku'(data){
      if(data.length >= 10){
        this.searchsku = data
        setTimeout(() => {
          if (data === this.searchsku) {
            for (let i = 0; i < this.productos.length; i++) {
              if (data === this.productos[i].sku) {
                if (this.productos[i].existencia > 0) {
                  if (this.productos[i].estatus === "Disponible") {
                    if (this.form.products.length > 0) {
                      let bool = false
                      let index
                      for (let j = 0; j < this.form.products.length; j++) {
                        if (this.form.products[j]._id.toString() === this.productos[i]._id.toString()) {
                          bool = true
                          index = j
                        }
                      }
                      if (bool === true) {
                        this.form.products[index].cantidad = parseInt(this.form.products[index].cantidad) + 1
                        this.iva = parseFloat((this.iva + this.form.products[index].iva).toFixed(2))
                        this.subtotal = parseFloat((this.subtotal + this.form.products[index].subtotal).toFixed(2))
                        this.total = this.subtotal + this.iva
                      } else {
                        this.iva = parseFloat((this.iva + (this.productos[i].precio * this.sett_iva)).toFixed(2))
                        this.subtotal = parseFloat((this.subtotal + (this.productos[i].precio - ((this.productos[i].precio * this.sett_iva)))).toFixed(2))
                        this.total = this.subtotal + this.iva
                        this.form.products.push(
                          {
                            _id: this.productos[i]._id, 
                            nombre: this.productos[i].nombre, 
                            sku: this.productos[i].sku, 
                            cantidad: 1, 
                            imagen: this.productos[i].imagen, 
                            precio:this.productos[i].precio,
                            iva: this.productos[i].precio * this.sett_iva,
                            subtotal: this.productos[i].precio - (this.productos[i].precio * this.sett_iva)
                          }
                        )
                      }
                    } else {
                      this.iva = parseFloat((this.iva + (this.productos[i].precio * this.sett_iva)).toFixed(2))
                      this.subtotal = parseFloat((this.subtotal + (this.productos[i].precio - ((this.productos[i].precio * this.sett_iva)))).toFixed(2))
                      this.total = this.subtotal + this.iva
                      this.form.products.push(
                        {
                          _id: this.productos[i]._id, 
                          nombre: this.productos[i].nombre, 
                          sku: this.productos[i].sku, 
                          cantidad: 1, 
                          imagen: this.productos[i].imagen, 
                          precio:this.productos[i].precio,
                          iva: this.productos[i].precio * this.sett_iva,
                          subtotal: this.productos[i].precio - (this.productos[i].precio * this.sett_iva)
                        }
                      )
                    }
                  } else {
                    this.$swal.fire(
                      "Información",
                      "Estimado usuario, este producto no se encuentra disponible en este momento.",
                      "warning"
                    );
                  }
                } else {
                  this.$swal.fire(
                    "Información",
                    "Estimado usuario, el producto seleccionado se encuentra agotado.",
                    "warning"
                  );
                }
              }
            }
          }
        }, 600)
      }
    },
  },
  methods: {
    formatDate2 (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    ver_detalle(item){
      this.dialog2 = true
      this.nota_rech = item.nota
    },
    ver_detalle2(item){
      this.dialog4 = true
      this.nota_rech2 = item
    },
    cerrar_detalle(item){
      this.dialog2 = false
      this.dialog4 = false
      this.nota_rech = ""
      this.nota_rech2 = {}
    },
    finalizar_detalle(){
      this.dialog4 = false
      let info = {
        _id: this.nota_rech2._id,
        visto: true
      }
      Api.put('pagos', info).then(res=>{
        this.$swal.fire(
          "Felicidades.!",
          "Pago finalizado exitosamente.",
          "success"
        );
        this.nota_rech2 = {}
        this.cerrar_detalle()
        this.initialize()
      }).catch(err=>{
        console.log(err)
        this.$swal.fire(
          "Oops... Error encontrado",
          "Problemas de conexión, intente nuevamente o contacte con el administrador.",
          "error"
        );
      })
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Cajero"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    abrir() {
      this.dialog = true
      // setTimeout(function(){ 
      //   this.card.mount(this.$refs.card);
      // }.bind(this), 100);
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `${month}/${year}`
    },
    close() {
      this.form.cliente = ""
      this.form.address = ""
      this.form.telefono = ""
      this.form_bank = Object.assign({}, this.defaultBank);
      this.dialog = false
      this.dialog3 = false
      // this.card.unmount(this.$refs.card);
    },
    existencia(item) {
      for (let i = 0; i < this.productos.length; i++) {
        if (item.toString() === this.productos[i]._id.toString()) {
          return this.productos[i].existencia
        }
      }
    },
    quitar(indice) {
      if (this.form.products[indice].cantidad === 1) {
        this.subtotal = parseFloat((this.subtotal - this.form.products[indice].subtotal).toFixed(2))
        this.iva = parseFloat((this.iva - this.form.products[indice].iva).toFixed(2))
        this.total = this.total - this.form.products[indice].precio
        this.form.products.splice(indice, 1);
      } else {
        this.form.products[indice].cantidad = this.form.products[indice].cantidad - 1
        this.subtotal = parseFloat((this.subtotal - this.form.products[indice].subtotal).toFixed(2))
        this.iva = parseFloat((this.iva - this.form.products[indice].iva).toFixed(2))
        this.total = this.total - this.form.products[indice].precio
      }
    },
    agregar(indice) {
      this.form.products[indice].cantidad = this.form.products[indice].cantidad + 1
      this.subtotal = parseFloat((this.subtotal + this.form.products[indice].subtotal).toFixed(2))
      this.iva = parseFloat((this.iva + this.form.products[indice].iva).toFixed(2))
      this.total = this.total +  this.form.products[indice].precio
    },
    indice(indice) {
      this.subtotal = (this.subtotal - (this.form.products[indice].cantidad * this.form.products[indice].subtotal)).toFixed(2)
      this.iva = (this.iva - (this.form.products[indice].cantidad * this.form.products[indice].iva)).toFixed(2)
      this.total = this.total - (this.form.products[indice].cantidad * this.form.products[indice].precio)
      this.form.products.splice(indice, 1);
      if (this.form.products.length === 0) {
        this.subtotal = 0.00
        this.iva = 0.00
        this.total = 0.00
      }
    },
    initialize() {
      this.iva = 0.00
      this.subtotal = 0.00
      this.total = 0.00
      // this.stripe = Stripe(`pk_test_ctcrmivUzyMZdP1vs2SgsA54`)
      // this.elements = this.stripe.elements()
      // this.card = this.elements.create('card',{hidePostalCode: true});
      Api.get("productos").then(res => {
        this.productos = res.data
      });
      Api.get("bancos").then(res => {
        this.bank_e = []
        this.bank_r = []
        res.data.map(cat => {
          this.bank_e.push({ value: cat.banco,  text: cat.banco });
        });
        this.bank_e.push({value: "Otro", text: "Otro"})
        res.data.map(cat => {
          this.bank_r.push({ value: cat.banco, text: cat.banco });
        });
      });
      Api.get("settings").then(res=>{
        this.sett_iva = parseFloat(res.data[0].iva)
      })
      Api.get('orden/caja/'+this.$store.state.merkafur._id).then(res=>{
        this.pdnts = res.data
      })
      Api.get('pagos/caja/'+this.$store.state.merkafur._id).then(res=>{
        this.pdnts2 = res.data
      })
    },
    save() {
      if (this.form_bank.categoria && this.form.telefono && this.form_bank.nombre && this.form_bank.apellido && this.form_bank.correo && this.form_bank.identificacion) {
        this.loading = true
        this.form.cliente = this.form_bank.nombre + " " + this.form_bank.apellido
        this.form.pago = this.form_bank
        this.form.iva = this.iva
        this.form.subtotal = this.subtotal
        this.form.total = this.total
        this.form.cajero = this.$store.state.merkafur._id
        this.form.payment = this.form_bank.categoria
        Api.post('orden', this.form).then(res=>{
          this.$swal.fire(
            "Felicidades.!",
            "Orden creada exitosamente.",
            "success"
          );
          this.loading = false
          this.form = Object.assign({}, this.defaultForm);
          this.form.products = []
          this.form_bank = Object.assign({}, this.defaultBank);
          this.close()
          this.initialize()
        }).catch(err=>{
          this.loading = false
          console.log(err)
          this.$swal.fire(
            "Oops... Error encontrado",
            "Verifique los datos o llame al administrador.",
            "error"
          );
        })
      } else {
        this.$swal.fire(
          "Oops... Error encontrado",
          "Por favor ingrese todos los datos correspondientes.",
          "error"
        );
      }
    },
    save_pago() {
      if (this.form_bank.categoria && this.form_bank.telefono && this.form_bank.fecha && this.form_bank.monto && this.form_bank.nombre && this.form_bank.apellido && this.form_bank.correo && this.form_bank.identificacion) {
        this.loading = true
        this.form_bank.cajero = this.$store.state.merkafur._id
        this.form_bank.fecha = this.$moment(this.form_bank.fecha, 'DD/MM/YYYY').format()
        Api.post('pagos', this.form_bank).then(res=>{
          this.$swal.fire(
            "Felicidades.!",
            "Orden creada exitosamente.",
            "success"
          );
          this.loading = false
          this.form_bank = Object.assign({}, this.defaultBank);
          this.$socket.emit('actualizar_pag','h')
          this.close()
          this.initialize()
        }).catch(err=>{
          this.loading = false
          console.log(err)
          this.$swal.fire(
            "Oops... Error encontrado",
            "Verifique los datos o llame al administrador.",
            "error"
          );
        })
      } else {
        this.$swal.fire(
          "Oops... Error encontrado",
          "Por favor ingrese todos los datos correspondientes.",
          "error"
        );
      }
    },
    // save() {
    //   if (this.form.cliente && this.form.address && this.form.telefono && this.total && this.iva && this.subtotal) {
    //     this.loading = true
    //     this.stripe.createToken(this.card).then(result=> {
    //       this.form.total = this.total
    //       this.form.total2 = this.total
    //       this.form.iva = this.iva
    //       this.form.subtotal = this.subtotal
    //       this.form.total = parseFloat(this.form.total.toFixed(2).replace('.',''))
    //       this.form.token = result.token.id
    //       Api.post('orden', this.form).then(res=>{
    //         this.$swal.fire(
    //           "Felicidades.!",
    //           "Orden creada exitosamente.",
    //           "success"
    //         );
    //         this.form = Object.assign({}, this.defaultForm);
    //         this.close()
    //         this.initialize()
    //       }).catch(error=>{
    //         console.log(error)
    //         this.$swal.fire(
    //           "Oops...",
    //           "Error encontrado, verifique los datos o llame al administrador.",
    //           "error"
    //         );
    //       })
    //     }).catch(err=>{
    //       console.log(err)
    //       this.$swal.fire(
    //         "Oops...",
    //         "Error encontrado, problemas con el servidor de Stripe o información erronea de la tarjeta, intente nuevamente.",
    //         "error"
    //       );
    //     })
    //   } else {
    //     this.$swal.fire(
    //       "Oops...",
    //       "Error encontrado, por favor ingrese todo los datos.",
    //       "error"
    //     );
    //   }
    // }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/cajero.scss";
</style>
<style>
/* .StripeElement {
  box-sizing: border-box;
  height: 55px;
  padding: 18px 12px;
  border: 2px solid #757575;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
} */
.v-date-picker-title__date > div {
    display: none !important;
}
.v-date-picker-table .v-btn {
    display: flex !important;
    justify-content: flex-end !important;
    padding: 0px !important;
}
.v-badge--left .v-badge__badge {
    left: -10px !important;
}
.v-badge__badge .v-icon {
    font-size: 20px !important;
}
.v-badge__badge {
    height: 30px !important;
    width: 30px !important;
}
</style>