import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    tokenmerkafur: '',
    merkafur: []
  },
  mutations: {
    login(state,data){
      state.merkafur = data.user
      state.tokenmerkafur = data.token
    },
    logout(state){
      state.sesion = []
      state.merkafur = []
      state.tokenmerkafur = ''
    },
    merkafur(state, data) {
      state.merkafur = data
    },
  },
  actions: {
    login({commit}, data){
      commit("login", data)
    },
    logout({commit}){
      commit("logout")
    },
    setuser({commit},data) {
      commit("merkafur", data)
    },
  },
});