// const server = "http://localhost";
// const domain = "localhost";
// const port = 9035;
// const server = "http://192.168.3.106";
// const domain = "192.168.3.106";
// const port = 9035;
const server = "https://api.merkafur.com";
const domain = "admin.merkafur.com";
const port = 443;
export { server, port, domain };
