<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Banners
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Traslados
    v-dialog(v-model='dialog', persistent max-width='1500px' )
          v-container(style='background-color:white !important;max-width: 700px;' ref="impresionPdf")
            h5 Merkafur C.A
            h1 Orden de Traslado N {{form.orden}}
            h3 Sucursal: {{form.sucursal}}
            h3 Fecha: {{form.fecha | moment("DD-MM-YYYY, h:mm a") }}
            h3 Empleado: {{form.empleado.nombre }} {{form.empleado.apellido }}
            v-data-table.elevation-1(disable-sortable:true, :headers='headers2', :items="form.productos", style="margin-top: 5px;")
              template(slot='items', slot-scope='props')
                td {{ props.item.sku }}
                td {{ props.item.nombre }}
                td {{ props.item.cant }}
          div
              v-btn(style="background:#01458c;border-radius:10px;", @click.native='downloadWithCSS(form)') Imprimir
              v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir

    v-layout(align-start='', justify-start='', row='', fill-height='' )
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Traslados
        v-data-table.elevation-1(:headers='headers', :items="traslados", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.orden }}
            td {{ props.item.sucursal }}
            td {{ props.item.fecha | moment("DD-MM-YYYY, h:mm a") }}
            td.justify-start.layout.px-0
                    v-btn.mx-0(con, @click='ver(props.item)', style='margin-top: -9px;')
                        v-icon(color='teal') edit
</template>

<script>
import * as jsPDF from 'jspdf'; 
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mTraslado",
  data: () => ({
    ruta: server + ":" + port,
    loading: false,
    options: ["Arriba", "Centro", "Abajo"],
    form: {
      orden: "",
      productos: [],
      empleado: {},
      sucursal:'',
      cantidad:'',
      fecha:''
    },
    dialog: false,
    editedIndex: -1,
    traslados: [],
    headers: [
      { text: "Numero de traslado", value: "orden" },
      { text: "Sucursal", value: "Sucursal" },
      { text: "Fecha", value: "fecha" }
    ],
    headers2: [
      { text: "SKU", value: "sku", sortable:false },
      { text: "Nombre", value: "nombre",sortable:false },
      { text: "Cantidad", value: "cantidad",sortable:false }
    ],
    defaultForm: {
      orden: "",
      productos: [],
      empleado: {},
      sucursal:'',
      fecha:''
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  sockets: {
    reportados() {
      this.initialize();
    }
  },
  created() {
    this.verificacion()
    this.initialize();
  },
  methods: {
    ver(data){
      this.form.orden = data.orden 
      this.form.sucursal = data.sucursal
      this.form.cantidad = data.cant
      this.form.productos = data.productos
      this.form.fecha = data.fecha
      this.form.empleado = data.empleado
    this.dialog = true
    },
    downloadWithCSS(data) {
       
      
      const doc = new jsPDF('p', 'pt', 'a4');
      const contentHtml = this.$refs.impresionPdf.innerHTML;
      doc.fromHTML(contentHtml, {
        'x': 15,
        'y': 15,
        'width': 200,
        'height': 200,
      });
      doc.save("arqueoCaja.pdf");
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Banners"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    initialize() {
      this.editedIndex = -1;
      Api.get("traslado").then(res => {
        console.log(res.data)
        this.traslados = res.data;
      });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("traslados/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    save() {
      this.loading = true
      if (this.form.nombre) {
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile)
          data.append("imagen", this.form.imagen.imageFile);
        if (this.editedIndex > -1) {
          Api.put("traslado", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          Api.post("traslado", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.loading = false
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.loading = false
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
</style>