<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Orden
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Ordenes
    v-dialog(v-model='dialog', persistent max-width='1028px')
      v-container(style='background-color:white !important;max-width: 1028px;')
        v-flex(xs12)
          template
            v-tabs(v-model='active', color="#01458c", centered, dark)
              v-tab(:key='0', ripple='') Orden
              v-tab(:key='1', ripple='') Información de pago
              v-tab-item(:key='0')
                v-card(flat='')
                  v-flex(xs12 md12 sm12 style='margin-top:10px')
                    v-layout(row, wrap)
                      v-flex(xs6 style='display: flex;')
                        div(style='display: flex;align-items: center;')
                          img(src='../assets/logo3.jpeg', with='60' ,height='60' style='border-radius:10px')
                          span(style='font-size: 30px;font-weight: 600;padding-left: 10px;') Merkafur
                      v-flex(xs6)
                        div(style='display: flex;justify-content: flex-end;align-items: center;')
                          img(:src='ruta + form.QR', style='border-radius:10px;width:100px;height:auto;')
                      v-flex(xs12 style='margin-top: 20px;background: #bfbebe;height: 108px;display: flex;align-items: flex-end;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6)
                            div(style='padding-left: 15px;')
                              p(style='font-weight: 500;') Nro. Orden: {{form.nro}}
                              p(style='font-weight: 500;') Fecha: {{form.orden_time}}
                              p(style='font-weight: 500;') Cliente: {{form.cliente}}
                              p(style='font-weight: 500;') Teléfono: {{form.telefono}}
                          v-flex(xs6 style='display: flex;justify-content: center;align-items: center;')
                            p(style='font-size: 21px !important;font-weight: 600;') {{form.state}}
                      v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;border-bottom: 1px solid;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6 style='padding-left: 15px;')
                            span(style='font-weight: 500;') Producto
                          v-flex(xs3)
                            span(style='font-weight: 500;display: flex;justify-content: center;') Cantidad
                          v-flex(xs3)
                            span(style='font-weight: 500;display: flex;justify-content: center;') Monto
                      v-flex(v-for='(item,index) in form.products' xs12 v-bind:class="{ 'active': par(index), 'pasive': comparar(index) }" style='height: 45px;background: #f3f3f3;display: flex;align-items: center;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6 style='padding-left: 15px;')
                            span(style='font-weight: 400;font-size: 14px;') {{item.nombre}}
                          v-flex(xs3)
                            span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;' v-if='item.cantidad') {{item.cantidad}}
                            span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;' v-if='item.cant') {{item.cant}}
                          v-flex(xs3)
                            span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') {{item.precio}}
                      v-flex(xs12 style='height: 45px;background: #fff;display: flex;align-items: center;')
                      v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6)
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 500;') SubTotal:
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 400 !important;font-style: italic;') {{form.subtotal}}
                      v-flex(xs12 style='height: 45px;background: #fff;display: flex;align-items: center;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6)
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 500;') Iva:
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 400 !important;font-style: italic;') {{form.iva}}
                      v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs6)
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 500;') Total:
                          v-flex(xs3 style='display: flex;justify-content: center;')
                            span(style='font-weight: 400 !important;font-style: italic;') {{form.total}}

              v-tab-item(:key='1')
                v-card(flat='')
                  v-flex(xs12 md12 sm12 style='margin-top:10px')
                    v-layout(row, wrap)
                      v-flex(xs6 style='display: flex;')
                        div(style='display: flex;align-items: center;')
                          img(src='../assets/logo3.jpeg', with='60' ,height='60' style='border-radius:10px')
                          span(style='font-size: 30px;font-weight: 600;padding-left: 10px;') Merkafur
                      v-flex(xs6)
                        div(style='display: flex;justify-content: flex-end;align-items: center;')
                          img(:src='ruta + form.QR', style='border-radius:10px;width:100px;height:auto;')
                      v-flex(xs12 style='height: 60px;margin-top: 20px;display: flex;justify-content: center;align-items: center;background: rgb(191, 190, 190);')
                        span(style='font-size: 25px;font-weight: 500;') {{form.payment}}
                      v-flex(xs12 style='height: 60px;margin-top: 20px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs2)
                          v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                            span(style='font-weight: 400;') Nombre: 
                          v-flex(xs4)
                            span {{pago.nombre}} {{pago.apellido}}
                          v-flex(xs2)
                      v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs2)
                          v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                            span(style='font-weight: 400;') Identificación: 
                          v-flex(xs4)
                            span {{pago.identificacion}}
                          v-flex(xs2)
                      v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs2)
                          v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                            span(style='font-weight: 400;') Correo: 
                          v-flex(xs4)
                            span {{pago.correo}}
                          v-flex(xs2)
                      v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs2)
                          v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                            span(style='font-weight: 400;') Referencia: 
                          v-flex(xs4)
                            span {{pago.referencia}}
                          v-flex(xs2)
                      v-flex(xs12 v-if='pago.banco' style='height: 60px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                        v-layout(row, wrap style='line-height: 1;')
                          v-flex(xs2)
                          v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                            span(style='font-weight: 400;') Banco: 
                          v-flex(xs4)
                            span {{pago.banco}}
                          v-flex(xs2)
                      v-flex(xs12 v-if='pago.image')
                        img(:src='ruta + pago.image', style='width:100%')
          v-flex(xs12 sm12 style='margin-top:20px')
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(v-if='form.state === "Pendiente"' style="background:green;border-radius:10px;", @click.native='save(form,pago)') Aprobar
                  v-btn(v-if='form.state === "Pendiente"' style="background:red;border-radius:10px;", @click.native='rech(form,pago)') Rechazar
                  v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
    div(style="margin-top:20px")
      v-flex(xs12)
        v-layout(wrap)
          v-flex(xs5)
            v-select(:items='items3' v-model="seleccion" label='Filtro de busqueda' outlined='' style='width:100%')
          v-flex(xs2)
          v-flex(xs5)
            v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:100%;')
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Ordenes
        v-data-table.elevation-1(:headers='headers', :items="orden", :pagination.sync="pagination", :search='search', style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.orden_time | moment("DD-MM-YYYY, h:mm a") }}
            td(v-if='props.item.client && props.item.client.nombre') {{props.item.client.nombre}} {{props.item.client.apellido}}
            td(v-else) {{props.item.cliente}}
            td(v-if='props.item.client && props.item.client.telefono && props.item.client.telefono[0] && !props.item.telefono') {{ props.item.client.telefono[0] }}
            td(v-if='props.item.telefono') {{props.item.telefono}}
            td(v-if='(!props.item.client || !props.item.client.telefono || !props.item.client.telefono[0]) && !props.item.telefono') - -
            td(v-if='props.item.payment') {{ props.item.payment}}
            td(v-else) - -
            td(v-if='props.item.total') {{ props.item.total}}
            td(v-else) - -
            td {{ props.item.state }}
            td.justify-start.layout.px-0
                    v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                        v-icon(v-if="mod" color='teal') edit
                    //- v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
                    //-     v-icon(v-if="elm" color='pink') delete
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mOrden",
  data: () => ({
    active: null,
    search: '',
    items3: ["Todos", "Pendiente", "Aprobado", "Entregado", "Rechazado"],
    seleccion: '',
    form: {
      address: "",
      QR: "",
      nro: "",
      cliente: "",
      cajero: "",
      client: "",
      pais: "",
      telefono: "",
      cost_shipping: "",
      iva: "",
      orden_time: "",
      order: "",
      payment: "",
      products: "",
      rnc: "",
      social: "",
      state: "",
      subtotal: "",
      total: "",
      _id: ""
    },
    pago: {},
    ruta: server + ":" + port,
    dialog: false,
    orden: [],
    ordenres: [],
    pagination: {
      sortBy: 'orden_time', // The field that you're sorting by
      descending: true
    },
    headers: [
      { text: "Fecha", value: "orden_time" },
      { text: "Cliente", value: "client.nombre" },
      { text: "Teléfono", value: "telefono"},
      { text: "Tipo de pago", value: "payment"},
      { text: "Total", value: "total" },
      { text: "Estatus", value: "state" },
      { text: "Acciones"}
    ],
    editedIndex: -1,
    defaultForm: {
      address: "",
      client: "",
      QR: "",
      nro: "",
      cliente: "",
      cajero: "",
      pais: "",
      telefono: "",
      cost_shipping: "",
      iva: "",
      orden_time: "",
      order: "",
      payment: "",
      products: "",
      rnc: "",
      social: "",
      state: "",
      subtotal: "",
      total: "",
      _id: ""
    },
    fecha: "",
    cre: "",
    mod: "",
    elm:""
  }),
  computed: {
    estatus() {
      let estado = "";
      if (this.form.order === "1") {
        estado = "Pendiente";
      }
      if (this.form.order === "2") {
        estado = "Aprobado";
      }
      if (this.form.order === "3") {
        estado = "Entregado";
      }
      return estado;
    }
  },
  watch: {
    seleccion() {
      if (this.seleccion === "Todos") {
        this.orden = this.ordenres
      } else {
        this.orden = []
        for (let i = 0; i < this.ordenres.length; i++) {
          if (this.ordenres[i].state === this.seleccion) {
            this.orden.push(this.ordenres[i])
          }
        }
      }
    },
  },
  sockets: {
    ord() {
      this.initialize();
    }
  },
  created() {
    this.verificacion()
    this.initialize();
  },
  methods: {
    comparar(data) {
      if ((data+1) === this.form.products.length) {
        return true
      } else {
        return false
      }
    },
    par(data) {
      if (data % 2==0) {
          return false
      } else {
          return true
      }
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Ordenes"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    estado(data) {
      if (data === "1") {
        return "Pendiente";
      }
      if (data === "2") {
        return "Aprobado";
      }
      if (data === "3") {
        return "Entregado";
      }
    },
    initialize() {
      Api.get("orden").then(res => {
        this.orden = res.data
        this.ordenres = res.data
      });
    },
    rech(form,pago) {
      this.$swal.fire({
        title: "Motivo de Rechazo",
        input: 'textarea',
        inputPlaceholder: 'Ingrese el mensaje aquí...',
        inputAttributes: {
          'aria-label': 'Ingrese el mensaje aquí'
        },
        confirmButtonColor: "green",
        cancelButtonColor: "#01458c",
        showCancelButton: true
      })
      .then(result=>{
        if (result.value) {
          let info = {
            _id: form._id,
            nota: result.value,
            client: form.client._id,
            pago: {
              _id: pago._id,
              estatus: "Rechazado"
            },
            state: "Rechazado"
          }
          Api.put('orden', info).then(res=>{
            this.$swal.fire(
              "Felicidades.!",
              "Orden rechazada exitosamente.",
              "success"
            );
            this.close()
            this.initialize()
          }).catch(err=>{
            console.log(err)
            this.$swal.fire(
              "Oops... Error encontrado",
              "Problemas de conexión, intente nuevamente o contacte con el administrador.",
              "error"
            );
          })
        } else {
          if (!result.value && !result.dismiss) {
            this.$swal.fire({
              title: "Oops... Error encontrado",
              text: "Por favor ingrese una nota para continuar",
              type: "error",
            }).then(res=>{
              this.rech(form,pago)
            })
          }
        }
      })
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("orden/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.orden.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (!item.rnc && !item.social) {
        this.form.rnc = "N/A";
        this.form.social = "N/A";
      }
      this.form.cliente = item.client.nombre + ' '+item.client.apellido
      this.fecha = this.form.orden_time;
      this.form.orden_time = this.$moment(item.orden_time).format(
        "DD-MM-YYYY, h:mm a"
      );
      if (item.client && item.client.telefono && item.client.telefono[0]) {
        this.form.telefono = item.client.telefono[0]
      } else {
        this.form.telefono = item.telefono
      }
      Api.get('pagos/orden/'+item._id).then(res=>{
        this.pago = res.data[0]
      })
      this.active = 1
      this.form._id = item._id;
      this.dialog = true;
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    save(form,pago) {
      let info = {
        _id: form._id,
        client: form.client._id,
        pago: {
          _id: pago._id,
          estatus: "Aprobado"
        },
        state: "Aprobado"
      }
      if (this.editedIndex > -1) {
        Api.put("orden", info)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Orden modificada exitosamente.",
              "success"
            );
            this.close()
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.$message.error("A ocurrido un error." + err);
          });
      }
    }
  }
};
</script>
<style lang="scss">
  .active{
      background: #fff !important;
  }
  .pasive {
    border-bottom: 1px solid !important;
  }
</style>
