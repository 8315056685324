import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from "vue-cookies";
import * as VueGoogleMaps from "vue2-google-maps";
import * as jsPDF from "jspdf";
import VueFlashMessage from "vue-flash-message";
import VueSweetalert2 from "vue-sweetalert2";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import VueGoogleCharts from "vue-google-charts";
import VueApexCharts from "vue-apexcharts";
import "element-ui/lib/theme-chalk/index.css";
import { computeArea } from "spherical-geometry-js";
import { DatePicker } from "element-ui";
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";
import VueSocketIO from "vue-socket.io";
import draggable from "vuedraggable";
import JsonExcel from "vue-json-excel";
import excel from 'vue-excel-export'
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(excel)
Vue.component("downloadExcel", JsonExcel);
Vue.use(Croppa);
Vue.use(draggable);
Vue.use(VueExcelXlsx);
// import firebase from "firebase"
Vue.use(
  new VueSocketIO({
    debug: true,
    // connection: "http://192.168.3.106:9035",
    // connection: "http://localhost:9035",
    connection: "https://api.merkafur.com:443",
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

// Initialize Firebase
// let config = {
//   apiKey: "AIzaSyBRpCCElS01eGX097g5r66aCbGf65piKCA",
//   authDomain: "apolo-taxi-547f9.firebaseapp.com",
//   databaseURL: "https://apolo-taxi-547f9.firebaseio.com",
//   projectId: "apolo-taxi-547f9",
//   storageBucket: "apolo-taxi-547f9.appspot.com",
//   messagingSenderId: "133377262328"
// };
// firebase.initializeApp(config);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// configure language
locale.use(lang);
Vue.use(DatePicker);
Vue.use(Loading);
Vue.use(computeArea);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const moment = require("moment");
require("moment/locale/es");
Vue.use(require("vue-moment"), {
  moment
});
Vue.use(jsPDF);
Vue.use(VueGoogleCharts);
Vue.use(VueSweetalert2);
require("vue-flash-message/dist/vue-flash-message.min.css");
Vue.use(VueFlashMessage);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCuJd1VMfmKwi1bW7yjCXysZ1gND1QqTE8",
    libraries: "places,directions" // This is required if you use the Autocomplete plugin
    // OR: libraries: "places,drawing"
    // OR: libraries: "places,drawing,visualization"
    // (as you require)
    //// If you want to set the version, you can do so:
    // v: "3.26",
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
