<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Dashboard
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
          v-card-title.justify-center
            img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
          v-card-title(style="padding: 7px;").justify-center
            span.black--text  Dashboard
        v-container(style='background-color:white !important;')
          div(style="margin-top: 20px;")
            v-flex(xs12 sm12 md12)
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-flex(xs12 style='display:flex;justify-content:center;')
                  v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                    span(style='display:flex;justify-content:center;font-size:20px') Productos Registrados
                    div(style='display: flex;justify-content: center;align-items: center;height: 75%;' v-if='!productos')
                      v-progress-circular(indeterminate='', color='primary')
                    h1(style='display:flex;justify-content:center') {{productos}}
                v-flex(xs12 sm12 style='margin-top:30px;')
                  v-layout(wrap align-start='', justify-center='')
                    v-flex(xs12 sm6 style='display: flex;')
                      v-dialog(ref='dialog' v-model='modal' :return-value.sync='month1' persistent='' width='290px')
                        template(v-slot:activator='{ on, attrs }')
                          v-text-field(outline v-model='month1' label='Seleccione mes a consultar' prepend-inner-icon='event' readonly='' v-bind='attrs' v-on='on')
                        v-date-picker(v-model='month1' :max="actual" scrollable='' :show-current='false' type='month')#mdl
                          v-spacer
                          v-btn(text='' color='#01458c' @click='modal = false') Cancel
                          v-btn(text='' color='#01458c' @click='$refs.dialog.save(month1)') OK
                      div
                        v-btn(icon='' style='background: #01458c;height: 58px;margin: 0;width: 55px;margin-left: 10px;' @click='buscar()')
                          v-icon(style='color:white !important;font-size:24px') search
                        v-btn(icon='' style='background: grey;height: 58px;margin: 0;width: 55px;margin-left: 10px;' @click='reset()')
                          v-icon(style='color:white !important;font-size:24px') refresh
                v-flex(xs12 style='margin-top:30px;')
                  #chart
                    apexchart(type='line' height='350' :options='chartOptions' :series='series')
                v-flex(xs12 style='margin-top:30px;')
                  #chart2
                    apexchart(type='line' height='350' :options='chartOptions2' :series='series2')
    //===========================================================Modal Espera==============================================================================================
    v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
      v-container(style='max-width: 400px;background:#f1f2f1')
        div(style="margin-top: 20px;")
            v-flex(xs12 sm12)
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h4.black--text  Esperando Respuesta del Servidor.!!
                v-flex(xs12)
                    div(style='display: flex;justify-content: center;').text-xs-center
                      img(src='../assets/waiting.gif', with='200' ,height='150')
                v-flex(xs12)
                  v-card-title(style="padding: 7px;").justify-center
                    h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mDashboard",
  data: () => ({
    productos: "",
    dialog4: false,
    month1: new Date().toISOString().substr(0, 7),
    actual: new Date().toISOString().substr(0, 7),
    modal: false,
    ruta: server + ":" + port,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "Ventas en el último mes",
        style: {
          colors: ["#333"]
        },
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: []
      }
    },
    series2: [],
    chartOptions2: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "Pedidos en el último mes",
        style: {
          colors: ["#333"]
        },
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: []
      }
    }
  }),
  sockets: {
    act() {
      this.initialize();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    buscar() {
      this.dialog4 = true;
      Api.get("estadisticas/graficas/ventas?hasta=" + this.month1).then(res => {
        this.series = res.data.series;
        this.chartOptions = {
          xaxis: {
            categories: res.data.fechas
          }
        };
      });
      Api.get("estadisticas/graficas/pedidos?hasta=" + this.month1).then(
        res => {
          this.series2 = res.data.series;
          this.chartOptions2 = {
            xaxis: {
              categories: res.data.fechas
            }
          };
          this.dialog4 = false;
        },
        err => {
          this.dialog4 = false;
        }
      );
    },
    reset() {
      this.dialog4 = true;
      Api.get("estadisticas/graficas/ventas").then(res => {
        this.series = res.data.series;
        this.chartOptions = {
          xaxis: {
            categories: res.data.fechas
          }
        };
      });
      Api.get("estadisticas/graficas/pedidos").then(
        res => {
          this.series2 = res.data.series;
          this.chartOptions2 = {
            xaxis: {
              categories: res.data.fechas
            }
          };
          this.dialog4 = false;
        },
        err => {
          this.dialog4 = false;
        }
      );
    },
    initialize() {
      this.dialog4 = true;
      Api.get("productos/all").then(res => {
        this.productos = res.data.length;
      });
      Api.get("estadisticas/graficas/ventas").then(res => {
        this.series = res.data.series;
        this.chartOptions = {
          xaxis: {
            categories: res.data.fechas
          }
        };
      });
      Api.get("estadisticas/graficas/pedidos").then(
        res => {
          this.series2 = res.data.series;
          this.chartOptions2 = {
            xaxis: {
              categories: res.data.fechas
            }
          };
          this.dialog4 = false;
        },
        err => {
          this.dialog4 = false;
        }
      );
    }
  }
};
</script>
<style lang="scss">
.apexcharts-title-text {
  font-size: 26px !important;
  font-weight: 500 !important;
}
#mdl {
  .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: #01458c !important;
    color: white !important;
    padding: 10px !important;
  }
  .v-picker__title {
    background: #01458c !important;
    color: white !important;
}
}
</style>
