<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Banners
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Inventario
    v-dialog(v-model='dialog', persistent max-width='1500px' )
          v-container(style='background-color:white !important;max-width: 700px;' ref="impresionPdf")
            h5 Merkafur C.A
            h1 Orden de Inventario {{form.orden}}
            h3 Sucursal: {{form.sucursal}}
            h3 Fecha: {{form.fecha | moment("DD-MM-YYYY, h:mm a") }}
            h3 Empleado: {{form.empleado.nombre }} {{form.empleado.apellido }}
            v-data-table.elevation-1(disable-sortable:true, :headers='headers2', :items="form.productos", style="margin-top: 5px;")
              template(slot='items', slot-scope='props')
                td {{ props.item.sku }}
                td {{ props.item.nombre }}
                td {{ props.item.cant }}
          div
              v-btn(style="background:#01458c;border-radius:10px;", @click.native='downloadWithCSS(form)') Imprimir
              v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir

    v-layout(align-start='', justify-start='', row='', fill-height='' )
      v-flex(xs12 style='margin-top: 5px;')
        v-layout(align-start='', justify-start='', row='', fill-height='')
            v-flex(xs6)
              //- export-excel(:data="global()" :fields="json_fields")2
              export-excel(v-if='globallisto' :data="inventarioglobal" :fields = "json_fields2" name="global.xls")
                v-btn.mb-2(style="border-radius:5px;background-color:#01458c;") Descargar Global
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Inventarios
        v-data-table.elevation-1(:headers='headers', :items="traslados", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item._id.ano }}
            td {{ props.item._id.semana }}
            td {{ props.item._id.sucursal }}
            td {{ props.item.cantidad }}
            //- td {{ formateo(props.item) }}
            td(@click.native='formateo(props.item)').justify-start.layout.px-0
              vue-excel-xlsx(:data="formateo(props.item)" :columns="columns"  :file-name= "props.item._id.sucursal +'-'+props.item._id.semana" :file-type="'xlsx'" sheet-name="Sheet1")
                v-icon(color='teal') download
</template>

<script>
import * as jsPDF from 'jspdf'; 
import Api from "@/services/methods";
import { server, port } from "@/services/environment";

export default {
  name: "mInventario",
  data: () => ({
    ruta: server + ":" + port,
    loading: false,
    options: ["Arriba", "Centro", "Abajo"],
    form: {
      orden: "",
      productos: [],
      empleado: {},
      sucursal:'',
      cantidad:'',
      fecha:''
    },
    inventario:[],
    inventarioglobal:[],
    globallisto: false,
    json_fields: {
            'Año': 'ano',
            'Semana': 'semana',
            'Sucursal': 'sucursal',
            'Correlativo': 'correlativo',
            'Numero': 'numero',
            'Nombre': 'nombre',
            'Sku': 'sku',
            'Codigo': 'codigo',
            'Fecha': 'fecha',
            'Exhibicion': 'exhibicion',
            'Ubicacion': 'ubicacion',
            'Fecha': 'fecha',
            'Empleado': 'empleado',
            'Diferencial': 'diferencial'
        },
        columns : [
                    {
                        label: "Año",
                        field: "ano",
                    },
                    {
                        label: "Semana",
                        field: "semana",
                    },
                    {
                        label: "Sucursal",
                        field: "sucursal",
                    },
                    {
                        label: "Correlativo",
                        field: "correlativo",
                    },
                    {
                        label: "Numero",
                        field: "numero",
                    },
                    {
                        label: "Nombre",
                        field: "nombre",
                    },
                    {
                        label: "Sku",
                        field: "sku",
                    },
                    {
                        label: "Codigo",
                        field: "codigo",
                    },
                    {
                        label: "Fecha",
                        field: "fecha",
                    },
                    {
                        label: "Exhibicion",
                        field: "exhibicion",
                    },
                    {
                        label: "Ubicacion",
                        field: "ubicacion",
                    },
                    {
                        label: "Empleado",
                        field: "empleado",
                    },
                    {
                        label: "Diferencial",
                        field: "diferencial",
                    }
                ],
        json_fields2: {
            'Nombre': 'nombre',
            'Sku': 'sku',
            'Codigo': 'codigo',
            'Exhibicion Cabudare': 'exhibicionCabudare',
            'Ubicacion Cabudare': 'ubicacionCabudare',
            'Exhibicion Sambil': 'exhibicionSambil',
            'Ubicacion Sambil': 'ubicacionSambil',
            'Exhibicion Segovia': 'exhibicionSegovia',
            'Ubicacion Segovia': 'ubicacionSegovia',
        },
        json_data: [],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
    dialog: false,
    editedIndex: -1,
    traslados: [],
    
    headers: [
    { text: "Año", value: "_id.ano" },
      { text: "Semana", value: "_id.semana" },
      { text: "Sucursal", value: "_id.sucursal", sortable:true },
      { text: "Cantidad contados", value: "cantidad" }
    ],
    headers2: [
      { text: "SKU", value: "sku", sortable:false },
      { text: "Nombre", value: "nombre",sortable:false },
      { text: "Cantidad", value: "cantidad",sortable:false }
    ],
    defaultForm: {
      orden: "",
      productos: [],
      empleado: {},
      sucursal:'',
      fecha:''
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  sockets: {
    reportados() {
      this.initialize();
    }
  },
  created() {
    this.verificacion()
    this.initialize();
  },
  methods: {
    formateo(data){
      let info = []
      for (let i = 0; i < data.productos.length; i++) {
        let numerosucursal = ''
        if (data._id.sucursal === 'Nueva Segovia') {
          numerosucursal = 1
        }else if(data._id.sucursal === 'Sambil'){
          numerosucursal = 3
        }else if(data._id.sucursal === 'Cabudare'){
          numerosucursal = 2
        }
        info.push({
        ano:data._id.ano,
        semana:data._id.semana,
        sucursal:data._id.sucursal,
        nombre:data.productos[i].nombre,
        correlativo:data.productos[i].correlativo,
        numero:data._id.ano + data._id.semana + numerosucursal,
        sku: data.productos[i].sku,
        codigo: data.productos[i].co_art,
        fecha: data.productos[i].fecha,
        exhibicion: data.productos[i].exhibicion,
        ubicacion: data.productos[i].ubicacion,
        fecha: data.productos[i].fecha,
        empleado: data.productos[i].empleado,
        diferencial: data.productos[i].diferencial
      })
      }
      console.log(info)
      return info
    },
    global(){
      console.log('se llama el global')
      let info = []
      let data = this.inventario
      console.log(data)
      for (let i = 0; i < data.length; i++) {
        console.log(data[i]._id.sku)
        console.log(data[i]._id.sku)
        // let res = info.filter(categoria => categoria.sku === data[i]._id.sku)
        let result = info.map(e => e.sku).indexOf(data[i]._id.sku);
        console.log(result)
        if(result != -1){
          console.log(data[i]._id.sucursal)
          if(data[i]._id.sucursal === 'Cabudare'){
            info[result].exhibicionCabudare = data[i].productos[0].exhibicion
            info[result].ubicacionCabudare = data[i].productos[0].ubicacion
          }else if(data[i]._id.sucursal === 'Nueva Segovia'){
            info[result].exhibicionSegovia = data[i].productos[0].exhibicion
            info[result].ubicacionSegovia = data[i].productos[0].ubicacion
          }else if(data[i]._id.sucursal === 'Sambil'){
            info[result].exhibicionSambil = data[i].productos[0].exhibicion
            info[result].ubicacionSambil = data[i].productos[0].ubicacion
          }
        }else{
          console.log('pasa')
          if(data[i]._id.sucursal === 'Cabudare'){
            info.push({
            nombre:data[i].productos[0].nombre,
            sku: data[i].productos[0].sku,
            codigo: data[i].productos[0].co_art,
            exhibicionCabudare: data[i].productos[0].exhibicion,
            ubicacionCabudare: data[i].productos[0].ubicacion
          })
          }else if(data[i]._id.sucursal === 'Nueva Segovia'){
            info.push({
            nombre:data[i].productos[0].nombre,
            sku: data[i].productos[0].sku,
            codigo: data[i].productos[0].co_art,
            exhibicionSegovia: data[i].productos[0].exhibicion,
            ubicacionSegovia: data[i].productos[0].ubicacion
          })
          }else if(data[i]._id.sucursal === 'Sambil'){
            info.push({
            nombre:data[i].productos[0].nombre,
            sku: data[i].productos[0].sku,
            codigo: data[i].productos[0].co_art,
            exhibicionSambil: data[i].productos[0].exhibicion,
            ubicacionSambil: data[i].productos[0].ubicacion
          })
          }
          
        }
      }
    
      // for (let i = 0; i < data.productos.length; i++) {
      //   info.push({
      //   sucursal:data._id.sucursal,
      //   nombre:data.productos[i].nombre,
      //   sku: data.productos[i].sku,
      //   codigo: data.productos[i].co_art,
      //   fecha: data.productos[i].fecha,
      //   exhibicion: data.productos[i].exhibicion,
      //   ubicacion: data.productos[i].ubicacion,
      //   fecha: data.productos[i].fecha,
      //   empleado: data.productos[i].empleado,
      //   diferencial: data.productos[i].diferencial
      // }),
      this.inventarioglobal = info
      this.globallisto = true
      console.log(info)
      return info
    },
    ver(data){
      this.form.orden = data.orden 
      this.form.sucursal = data.sucursal
      this.form.cantidad = data.cant
      this.form.productos = data.productos
      this.form.fecha = data.fecha
      this.form.empleado = data.empleado
    this.dialog = true
    },
    downloadWithCSS(data) {
       
      
      const doc = new jsPDF('p', 'pt', 'a4');
      const contentHtml = this.$refs.impresionPdf.innerHTML;
      doc.fromHTML(contentHtml, {
        'x': 15,
        'y': 15,
        'width': 200,
        'height': 200,
      });
      doc.save("arqueoCaja.pdf");
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Banners"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    initialize() {
      this.editedIndex = -1;
      Api.get("inventario").then(res => {

        this.traslados = res.data;
      });
      Api.get("inventario/global").then(res => {

      this.inventario = res.data;
      this.global()
      });
      
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("traslados/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    save() {
      this.loading = true
      if (this.form.nombre) {
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile)
          data.append("imagen", this.form.imagen.imageFile);
        if (this.editedIndex > -1) {
          Api.put("traslado", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          Api.post("traslado", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.loading = false
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.loading = false
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
</style>