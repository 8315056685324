<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Historias
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Historias
    v-dialog(v-model='dialog', persistent max-width='900px')
          v-btn.mb-2(v-if='cre' slot='activator', style="border-radius:5px;background-color:#01458c;") Nueva Historia
          v-container(style='background-color:white !important;max-width: 900px;')
            v-card-title.justify-center
                span.headline Banner
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md6, sm6)
                          v-form(enctype="multipart/form-data")

                            v-text-field(id="Nombre"
                            outline
                            required
                            v-if='editedIndex === -1'
                            v-model="form.userName"
                            prepend-inner-icon="chevron_right"
                            label="Nombre la historia")

                            v-text-field(id="Nombre"
                            outline
                            required
                            readonly
                            v-if='editedIndex !== -1'
                            v-model="form.userName"
                            prepend-inner-icon="chevron_right"
                            label="Nombre la historia")

                            v-flex(xs12)
                              v-layout(wrap)
                                v-flex(xs4 v-for='(item,i) in form.imagen' style='padding: 10px;')
                                  v-card(style='width: 100%;height: 190px;' v-if='item.imageName').elevation-3
                                    div(style='position:absolute;width:100%;display:flex;justify-content:flex-end' v-if='editedIndex === -1')
                                      v-btn(@click="indice(i, item.imageName)",color='error').btnnn X
                                    img(:src='item.imageUrl', v-if='item.imageUrl' style='width: 100%;height:100%')
                                  v-card(style='width: 100%;height: 190px;' v-if='item.media').elevation-3
                                    div(style='position:absolute;width:100%;display:flex;justify-content:flex-end' v-if='editedIndex === -1')
                                      v-btn(@click="indice(i, item.media)",color='error').btnnn X
                                    img(:src='ruta + item.media', v-if='item.media' style='width: 100%;height:100%')
                              v-text-field(v-if='editedIndex === -1' outline label='Seleccione imagenes', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file', multiple)
                              input(
                                type="file"
                                style="display: none;"
                                ref="image"
                                accept="image/*"
                                @change="onFilePicked"
                                multiple)

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:grey;border-radius:10px;", @click.native='save' v-if='editedIndex === -1') Guardar
                                    v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Historias
        v-data-table.elevation-1(:headers='headers', :items="historias", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.userPicture')
              img(:src='ruta + props.item.userPicture' style='width: 70px;height: auto;')
            td(v-else) N/A
            td {{ props.item.userName }}
            td
              v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                v-icon(color='teal') visibility
              v-btn.mx-0(v-if='elm' icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
                v-icon(color='pink') delete
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mHistorias",
  data: () => ({
    ruta: server + ":" + port,
    loading: false,
    form: {
      userPicture: "",
      userName: "",
      currentItem: 0,
      seen: false,
      imagen: [],
      items: []
    },
    dialog: false,
    editedIndex: -1,
    historias: [],
    headers: [
      { text: "Portada", value: "userPicture" },
      { text: "Nombre historia", value: "userName" },
      { text: "Acción" }
    ],
    defaultForm: {
      userPicture: "",
      userName: "",
      imagen: [],
      currentItem: 0,
      seen: false,
      items: []
    },
    files: [],
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  created() {
    this.verificacion()
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (this.$store.state.merkafur.permisos[i].ruta === "Historias") {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (this.$store.state.merkafur.permisos[i].accion[j] === "Crear") {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      this.files = [];
      this.form.imagen = [];
      Array.prototype.push.apply(this.files, e.target.files);
      const files = e.target.files;
      if (files !== undefined) {
        for (let i = 0; i < files.length; i++) {
          const fr = new FileReader();
          fr.readAsDataURL(files[i]);
          fr.addEventListener("load", () => {
            this.form.imagen.push({
              imageName: this.files[i].name,
              imageUrl: fr.result
            });
          });
        }
      } else {
        this.form.imagen[0].imageName = "";
        this.form.imagen[0].imageFile = "";
        this.form.imagen[0].imageUrl = "";
      }
    },
    initialize() {
      this.files = [];
      this.form.imagen = [];
      this.editedIndex = -1;
      Api.get("historias").then(res => {
        this.historias = res.data;
      });
    },
    close() {
      this.files = [];
      this.form.imagen = [];
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("historias/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.historias.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.items && item.items.length > 0) {
        this.form.imagen = [];
        this.form.imagen = item.items;
      }
      this.form._id = item._id;
      this.dialog = true;
    },
    save() {
      this.loading = true;
      if (this.form.userName && this.files.length > 0) {
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          data.append("imagen", file);
        }
        Api.post("historias", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        this.loading = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
</style>