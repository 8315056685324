<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Clientes
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Clientes
    v-dialog(v-model='dialog', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Cliente
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs6)
                          v-form(enctype="multipart/form-data")
                            v-text-field(id="nombre"
                            outline
                            disabled
                            v-model="form.nombre"
                            prepend-inner-icon="description"
                            label="Nombre:")

                            v-text-field(id="apellido"
                            outline
                            disabled
                            v-model="form.apellido"
                            prepend-inner-icon="description"
                            label="Apellido:")

                            v-text-field(id="identificacion"
                            outline
                            disabled
                            v-model="form.identificacion"
                            prepend-inner-icon="description"
                            label="Identificación:")

                            v-textarea(id="direccion"
                            outline
                            disabled
                            v-model="form.direccion"
                            prepend-inner-icon="description",
                            required
                            label="Dirección:")

                            v-text-field(id="pais"
                            outline
                            disabled
                            v-model="form.pais"
                            prepend-inner-icon="description"
                            label="País:")

                            v-text-field(id="telefono"
                            outline
                            disabled
                            v-model="form.telefono"
                            prepend-inner-icon="phone",
                            required
                            label="Teléfono:")

                            v-text-field(id="correo"
                            outline
                            disabled 
                            v-model="form.email"
                            prepend-inner-icon="email",
                            required
                            label="Correo:")

                            v-text-field(id="estatus"
                            outline
                            disabled
                            v-model="form.estatus"
                            prepend-inner-icon="description",
                            required
                            label="Estatus:")

                            v-text-field(id="password"
                            outline
                            disabled
                            v-model="form.password"
                            prepend-inner-icon="description",
                            required
                            label="Password:")


                            img(:src='ruta + form.imagen', height='150', width='310', v-if='form.imagen')

                            div
                                v-flex(xs12 sm12)
                                    v-layout(row='', wrap fill-height='')
                                        v-flex(xs1)
                                        v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:50%;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Clientes
        v-data-table.elevation-1(:headers='headers', :search='search', :items="clientes", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.nombre') {{ props.item.nombre }}
            td(v-if='!props.item.nombre') {{ 'N/A' }}
            td(v-if='props.item.apellido') {{ props.item.apellido }}
            td(v-if='!props.item.apellido') {{ 'N/A' }}
            td(v-if='props.item.identificacion') {{ props.item.identificacion }}
            td(v-if='!props.item.identificacion') {{ 'N/A' }}
            td {{ props.item.email }}
            td(v-if='props.item.direccion') {{ props.item.direccion }}
            td(v-if='!props.item.direccion') {{ 'N/A' }}
            td(v-if='props.item.pais') {{ props.item.pais }}
            td(v-if='!props.item.pais') {{ 'N/A'}}
            td {{ props.item.estatus }}
            td.justify-start.layout.px-0
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(color='teal' @click='editItem(props.item)' v-on="on") visibility
                | Ver
              //- v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
              //-     v-icon(color='red') delete
              v-btn.mx-0(v-if='props.item.rol === "Admin"', icon, @click='AsignarRol(props.item, "Cliente")', style='margin-top: -9px;')
                  v-icon(color='red') person
              v-btn.mx-0(v-if='props.item.rol !== "Admin"', icon, @click='AsignarRol(props.item, "Admin")', style='margin-top: -9px;')
                  v-icon(color='teal') person
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mClientes",
  data: () => ({
    search: '',
    form: {
      nombre: "",
      apellido: "",
      identificacion: "",
      direccion: "",
      telefono: "",
      email: "",
      pais: "",
      imagen: "",
      estatus: "",
      password:""
    },
    dialog: false,
    ruta: server + ":" + port,
    clientes: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Apellido", value: "apellido" },
      { text: "Identificación", value: "identificacion" },
      { text: "Correo", value: "email" },
      { text: "Dirección", value: "direccion" },
      { text: "País", value: "pais" },
      { text: "Estatus", value: "estatus" },
      { text: "Acción" }
    ],
    defaultForm: {
      nombre: "",
      apellido: "",
      identificacion: "",
      direccion: "",
      telefono: "",
      email: "",
      pais: "",
      imagen: "",
      estatus: "",
      password:""
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      Api.get("cliente").then(res => {
        this.clientes = res.data;
      });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    editItem(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.dialog = true;
    },
    AsignarRol(data, item) {
      let info = {
        _id: data._id,
        rol: item
      };
      Api.put("cliente/rol", info).then(res => {
        this.$swal.fire(
          "Felicidades.!",
          "Rol asignado exitosamente.",
          "success"
        );
        this.initialize();
      })
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("cliente/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    }
  }
};
</script>
