import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import LoginApi from "@/services/login";
import Productos from "./views/Productos.vue";
import Settings from "./views/Settings.vue";
import Categoria from "./views/Categoria.vue";
import Subcategoria from "./views/Subcategoria.vue";
import Orden from "./views/Orden.vue";
import Clientes from "./views/Clientes.vue";
import Banners from "./views/Banners.vue";
import Dashboard from "./views/Dashboard.vue";
import Permisos from "./views/Permisos.vue";
import Cajero from "./views/Cajero.vue";
import SinPermiso from "./views/SinPermiso.vue";
import Supervision from "./views/Supervision.vue";
import Pagos from "./views/Pagos.vue";
import Reporte from "./views/Reporte.vue";
import Directorio from "./views/Directorio.vue";
import Historias from "./views/Historias.vue";
import Geocerca from "./views/Geocerca.vue";
import Traslados from "./views/Traslado.vue";
import Inventario from "./views/Inventario.vue";
import { Loading } from "element-ui";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        isPublic: true,
        isLog: true
      }
    },
    {
      path: "/clientes",
      name: "clientes",
      component: Clientes,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/directorio",
      name: "directorio",
      component: Directorio,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/geocerca",
      name: "geocerca",
      component: Geocerca,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/reporte",
      name: "reporte",
      component: Reporte,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/historias",
      name: "historias",
      component: Historias,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/banners",
      name: "banners",
      component: Banners,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/pagos",
      name: "pagos",
      component: Pagos,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/categoria",
      name: "categoria",
      component: Categoria,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/traslados",
      name: "Traslado",
      component: Traslados,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/inventario",
      name: "Inventario",
      component: Inventario,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/subcategoria",
      name: "subcategoria",
      component: Subcategoria,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/supervision",
      name: "supervision",
      component: Supervision,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/cajero",
      name: "cajero",
      component: Cajero,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/productos",
      name: "productos",
      component: Productos,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/orden",
      name: "orden",
      component: Orden,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/sinpermiso",
      name: "sinpermiso",
      component: SinPermiso,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/permisos",
      name: "permisos",
      component: Permisos,
      meta: {
        isPublic: false
      }
    }
  ]
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.isPublic);
  if (requiresAuth === false) {
    LoginApi.refreshtoken()
      .then(res => {
        store.dispatch("setuser", res.data.usuario);
        if (to.path == "/pagos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Pagos") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/orden") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Ordenes") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/banners") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Banners") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/cajero") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Cajero") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/settings") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Configuraciones") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/supervision") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (
                store.state.merkafur.permisos[i].ruta ===
                "Supervisión de Empleados"
              ) {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/productos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Productos") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/subcategoria") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Categorías") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/categoria") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Categorías") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        if (to.path == "/permisos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.merkafur.permisos) {
            for (let i = 0; i < store.state.merkafur.permisos.length; i++) {
              if (store.state.merkafur.permisos[i].ruta === "Empleados") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.merkafur.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.merkafur.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        }
        next();
      })
      .catch(error => {
        console.log(error);
        next("/");
      });
  } else {
    if (to.path == "/") {
      LoginApi.refreshtoken()
        .then(res => {
          store.dispatch("setuser", res.data.usuario);
          next("/home");
        })
        .catch(error => {
          console.log(error);
          //next("/login")
        });
    }
    next();
  }
});

export default router;
