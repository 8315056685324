<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Geocerca
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Geocercas
        GmapMap(id='mapa' :center='centrarmapa', :zoom='zooom', style='width: 100%; height: 500px' @click='eventomap($event.latLng)')
            // GmapMarker(v-for = 'index in coordinates', :position='index', :clickable='true', :draggable='true')
            GmapPolygon(:paths='paths', :editable='true', @paths_changed='updateEdited($event)', :draggable='true')
            // GmapPolygon(v-if='form.polygono', v-for='list in form.polygono', :paths='list.info', :draggable='false' @click='obt(list)')
            GmapPolygon(v-if='pol && elm', v-for='list in pol', :paths='list.info', :draggable='false' @click="openInfoWindowTemplate(list)")
            GmapPolygon(v-if='pol && !elm', v-for='list in pol', :paths='list.info', :draggable='false' @click="openInfoWindowTemplate(list)")
            gmap-info-window(:options='{maxWidth: 300}', :position='infoWindow.position', :opened='infoWindow.open', @closeclick='infoWindow.open=false')
              | <b> Nombre: </b> {{info.nombre}}
              p
              | <b> Area: </b> {{info.perimetro}} Mts²
              p
        v-btn(v-if='cre').mb-2(style="border-radius:5px;background-color: #01458c;" @click='continuar()') Continuar
        v-btn.mb-2(style="border-radius:5px;background-color: #01458c;" @click='elim()') Eliminar Punto Anterior
        v-dialog(v-model='dialog', persistent max-width='1028px')
              v-container(style='background-color:white !important;max-width: 1028px;')
                v-card-title.justify-center
                    span.headline Geocerca
                div(style="margin-top: 20px;")
                    v-flex(xs12 sm12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-flex(xs6)
                              v-form(enctype="multipart/form-data")
                                v-card(style="margin-right: 0px;")
                                    v-text-field(id="Nombre"
                                    outline
                                    required
                                    v-model="form.nombre"
                                    prepend-inner-icon="person"
                                    label="Nombre de la Zona:"
                                    style="height: 56px;")

                                    v-text-field(id="Precio"
                                    outline
                                    required
                                    v-model="form.precio"
                                    type='number'
                                    prepend-inner-icon="attach_money"
                                    label="Precio de la Zona:"
                                    style="height: 56px;margin-top:10px;")

                                div(style="display: flex;justify-content: center;")
                                  v-btn(style="background:grey;border-radius:10px;", @click.native='save') Guardar
                                  v-btn(style="background:#01458c;;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 0px;')
        v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:50%;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color: #01458c;")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text Lista de Geocercas
        v-data-table.elevation-1(:headers='headers', :items="geocerca", :search="search", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.nombre }}
            td {{ props.item.perimetro }} Mts²
            td(v-if='props.item.precio') {{ props.item.precio }}
            td(v-else) N/A
            td.justify-start.layout.px-0
                v-btn(v-if='mod').mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                    v-icon(color='teal') edit
                v-btn.mx-0(icon, @click='buscar(props.item)', style='margin-top: -9px;')
                    v-icon(color='teal') visibility
                v-btn.mx-0(icon, @click='obt(props.item)', style='margin-top: -9px;')
                    v-icon(color='red') delete
</template>

<script>
import Api from "@/services/methods";
import { computeArea } from "spherical-geometry-js";
export default {
  name: "mGeocerca",
  data: () => ({
    centrarmapa: { lat: 10.0738900, lng: -69.3227800 },
    zooom: 12,
    cre: "",
    mod: "",
    elm: "",
    infoWindow: {
      position: { lat: 0, lng: 0 },
      open: false,
      template: ""
    },
    search: "",
    dialog: false,
    pol: [],
    edited: null,
    none: false,
    paths: [],
    geocerca: [],
    cont: 0,
    path: [],
    id: "",
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Perimetro", value: "perimetro" },
      { text: "Precio", value: "precio" },
      { text: "Acciones" }
    ],
    form: {
      nombre: "",
      precio: null,
      perimetro: 0
    },
    editedIndex: -1,
    defaultForm: {
      nombre: "",
      precio: null,
      perimetro: 0
    },
    info: []
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (this.$store.state.merkafur.permisos[i].ruta === "Geocerca") {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (this.$store.state.merkafur.permisos[i].accion[j] === "Crear") {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    openInfoWindowTemplate(item) {
      this.infoWindow.position = item.info[0][0];
      this.infoWindow.open = true;
      this.info = item;
    },
    distancia(item) {
      let area = computeArea(item, 6371e3);
      this.form.perimetro = area.toFixed(2);
    },
    eventomap(data) {
      this.infoWindow.open = false;
      this.path.push({ lat: data.lat(), lng: data.lng() });
      this.paths.push(this.path);
      this.crear();
    },
    crear() {
      this.paths = [];
      this.paths.push(this.path);
    },
    continuar() {
      if (this.paths.length > 0) {
        if (this.paths[0].length < 3) {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, para guardar la información es necesario tener más de 3 puntos en el polygono.",
            "error"
          );
        } else {
          this.dialog = true;
        }
      } else {
        this.$swal.fire(
          "Oops...",
          "Error encontrado, para guardar la información es necesario tener más de 3 puntos en el polygono.",
          "error"
        );
      }
    },
    buscar(item) {
      this.centrarmapa = {
        lat: item.polygono[0][0][0].lat,
        lng: item.polygono[0][0][0].lng
      };
      this.zooom = 12;
      document.getElementById("mapa").scrollIntoView();
    },
    guardar() {
      if (this.paths[0].length > 2) {
        this.cont++;
        this.form.polygono.push({
          id: this.cont,
          info: this.paths
        });
        this.paths = [];
        this.path = [];
        this.edited = null;
      } else {
        this.$swal.fire(
          "Oops...",
          "Error encontrado, para guardar la información es necesario tener más de 3 puntos en el polygono.",
          "error"
        );
      }
    },
    elim() {
      this.path.splice(this.path.length - 1, 1);
      this.paths.push(this.path);
      this.crear();
    },
    obt(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("geocerca/" + item._id)
              .then(res => {
                this.form = Object.assign({}, this.defaultForm);
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.infoWindow.open = false;
                this.initialize();
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.geocerca.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    updateEdited(mvcArray) {
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        this.path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
          this.path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      this.crear();
      this.edited = paths;
    },
    close() {
      this.zooom = 12;
      this.editedIndex = -1;
      this.form.nombre = "";
      this.dialog = false;
      this.centrarmapa = { lat: 10.0738900, lng: -69.3227800 };
    },
    initialize() {
      this.zooom = 12;
      this.centrarmapa = { lat: 10.0738900, lng: -69.3227800 };
      this.editedIndex = -1;
      this.form.polygono = [];
      this.pol = [];
      this.paths = [];
      this.path = [];
      this.edited = null;
      this.geocerca = [];
      this.none = false;
      Api.get("geocerca")
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].polygono.length; j++) {
              this.cont++;
              this.pol.push({
                id: res.data[i]._id,
                nombre: res.data[i].nombre,
                perimetro: res.data[i].perimetro,
                info: res.data[i].polygono[j]
              });
            }
          }
          if (res.data.length > 0) {
            this.id = res.data[0]._id;
            this.geocerca = res.data;
          }
        })
        .catch(err => {
          console.log(err);
          this.none = true;
        });
    },
    save() {
      if (this.form.nombre && this.form.precio) {
        if (this.editedIndex > -1) {
          Api.put("geocerca", this.form)
            .then(res => {
              this.paths = [];
              this.path = [];
              this.edited = null;
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.infoWindow.open = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          this.form.polygono.push(this.paths);
          this.distancia(this.form.polygono[0][0]);
          Api.post("geocerca", this.form)
            .then(res => {
              this.paths = [];
              this.path = [];
              this.edited = null;
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.infoWindow.open = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.$swal.fire(
          "Oops...",
          "Error encontrado, para realizar el registro no debe dejar campos vacios.",
          "error"
        );
      }
    }
  }
};
</script>
