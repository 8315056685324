<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Cajero
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
          v-card-title.justify-center
            img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
          v-card-title(style="padding: 7px;").justify-center
            span.black--text SMS
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#01458c", centered, dark)
          v-tab(:key='0', ripple='') SMS
          v-tab(:key='1', ripple='') Directorio Teléfonico
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12 style='margin-top:40px;')
                v-layout(wrap align-center justify-center)
                  v-flex(xs7 v-if='cantCont === false')
                    v-combobox(:disabled='bloquear' v-on:keypress="isNumber($event)" append-icon="contact_phone" @click:append="dialog=true" type='phone' solo dense v-model='formSMS.telefonos' :hide-selected='true' label='Ingrese teléfono' :multiple='true' persistent-hint='' :small-chips='true' :clearable='false')
                      template(v-if='noData' v-slot:no-data='')
                        div
                          span(style='padding-left: 5px;') Escriba el número teléfonico y presione 
                          kbd enter
                          p
                          span(style='padding-left: 5px;') para agregar uno nuevo
                  v-flex(xs7)
                    v-textarea(solo v-model='formSMS.mensaje' placeholder='Mensaje' maxlength='761')
                    div(style='text-align:end;')
                      span(v-if='formSMS.mensaje.length < 761') {{formSMS.mensaje.length}} / 761 (SMS: {{cantSMS}})
                      span(v-else style='color:red') {{formSMS.mensaje.length}} / 761 (SMS: {{cantSMS}})
                  v-flex(v-if="loading3 === true" xs12 style='display:flex;justify-content:center;margin-bottom:10px;')
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                      v-progress-circular(indeterminate='', color='primary')
                  v-flex(v-if="loading3 === false && cantCont === false" xs12 style='display:flex;justify-content:center;')
                    v-checkbox(v-model='formSMS.todos' label='Enviar a todos los contactos')

                  v-flex(v-if="loading3 === false && bloquear3 === false" xs12 style='display:flex;justify-content:center;')
                    v-checkbox(v-model='cantCont' label='Enviar a una cantidad especifica de contactos.')
                  v-flex(xs7 v-if='cantCont')
                    v-autocomplete(v-model='formSMS.cantidad' :chips='true' solo :items="cantDirectorio" multiple label=' Seleccione la cantidad de clientes a enviar mensaje')
                      template(v-slot:prepend='')
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ on }')
                            v-icon(v-on='on') help
                          | Para enviar un SMS desde el primer contacto hasta un limite seleccione una sola cantidad, si desea enviar en un rango especifico (500 al 1000), seleccione 2 cantidades.
                  v-flex(v-if="loading3 === false && bloquear3 === false && cantCont" xs12 style='display:flex;justify-content:center;')
                    v-checkbox(v-model='numEsp' label='¿Desea agregar algún número en especifico?')
                  v-flex(xs7 v-if='cantCont && bloquear4')
                    v-combobox(v-on:keypress="isNumber($event)" append-icon="person" type='phone' solo dense v-model='formSMS.telefonos' :hide-selected='true' label='Ingrese teléfono' :multiple='true' persistent-hint='' :small-chips='true' :clearable='false')
                      template(v-if='noData' v-slot:no-data='')
                        div
                          span(style='padding-left: 5px;') Escriba el número teléfonico y presione 
                          kbd enter
                          p
                          span(style='padding-left: 5px;') para agregar uno nuevo

                  v-flex(v-if="loading3 === false" xs12 style='display:flex;justify-content:center;')
                    v-btn(style="background:#01458c;;border-radius:10px;" @click='sendSMS()') Enviar
            v-dialog(v-model='dialog', persistent max-width='800px')
              v-container(style='background-color:white !important;max-width: 800px;')
                v-card-title.justify-center
                  span.headline Clientes
                div
                  v-flex(xs12 sm12 style='height: 380px;overflow-y: auto;' id='templt')
                      v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(v-if='directorioSel.length > 0' xs10 style='padding: 10px;').elevation-2
                          v-layout(wrap)
                            v-flex(xs12)
                              b(style='font-size: 16px;text-decoration: underline;text-align:center;') Seleccionados
                            v-flex(xs12 v-for='item in directorioSel' style='margin-bottom:8px;padding: 10px;').elevation-2
                              v-layout(wrap)
                                v-flex(xs7 style='display: flex;justify-content: flex-start;align-items: flex-end;')
                                  span {{item.nombre}}
                                v-flex(xs3 style='display: flex;justify-content: flex-start;align-items: flex-end;')
                                  span {{item.telefono}}
                                v-flex(xs2 style='display: flex;justify-content: center;align-items: flex-end;')
                                  v-icon(style='color:red;' @click='borarSel(item)') delete
                        v-flex(xs10 style='margin-top:10px;')
                          v-text-field(append-icon="search" solo dense v-model='search' label='Buscar...')
                        v-flex(xs10 style='padding: 10px;').elevation-2
                          v-layout(wrap)
                            v-flex(xs7)
                              b(style='font-size: 16px;text-decoration: underline;') Nombre
                            v-flex(xs3)
                              b(style='font-size: 16px;text-decoration: underline;') Teléfono
                            v-flex(xs2 style='text-align:center')
                              b(style='font-size: 16px;text-decoration: underline;') Selección
                        v-flex(xs10 v-for='item in directorio2' style='margin-bottom:8px;padding: 10px;').elevation-2
                          v-layout(wrap)
                            v-flex(xs7 style='display: flex;justify-content: flex-start;align-items: flex-end;')
                              span {{item.nombre}}
                            v-flex(xs3 style='display: flex;justify-content: flex-start;align-items: flex-end;')
                              span {{item.telefono}}
                            v-flex(xs2 style='display: flex;justify-content: center;align-items: flex-end;')
                              v-icon(style='color:#01458c;' @click='seleccionados(item)') add_circle
                  v-flex(xs12 sm12)
                    div
                      v-flex(v-if="loading2 === true", xs12 sm12 md12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                          v-progress-circular(indeterminate='', color='primary')
                      v-flex(xs12 sm12 md12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                          v-btn(style="background:grey;;border-radius:10px;", @click.native='seleccionar()') Agregar
                          v-btn(style="background:#01458c;;border-radius:10px;", @click.native='close2()') Salir
          v-tab-item(:key='1')
            v-card(flat='')
              v-flex(xs12)
                v-layout(wrap)
                  v-flex(xs6)
                    v-btn.mb-2(@click='dialog2 = true' style="border-radius:5px;background-color:#01458c;") Nuevo Cliente
                  v-flex(xs6 style='display:flex;justify-content:flex-end;')
                    v-btn.mb-2(style="border-radius:5px;background-color:#01458c;" @click='pickFile') Importar Excel
                    input(
                    type="file"
                    style="display: none;"
                    ref="exceel"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFilePicked")
              v-flex(xs12)
                v-dialog(v-model='dialog2', persistent max-width='700px')
                  v-container(style='background-color:white !important;max-width: 700px;')
                    v-card-title.justify-center
                        span.headline Cliente
                    div(style="margin-top: 20px;")
                        v-flex(xs12, sm12, md12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs12, md6, sm6)
                                  v-form(enctype="multipart/form-data")

                                    v-text-field(outline
                                    required
                                    v-model="form.nombre"
                                    prepend-inner-icon="chevron_right"
                                    label="Cliente")

                                    v-text-field(outline
                                    required
                                    v-model="form.identificacion"
                                    prepend-inner-icon="chevron_right"
                                    label="Identificación")

                                    v-textarea(outline
                                    required
                                    v-model="form.direccion"
                                    prepend-inner-icon="chevron_right"
                                    label="Dirección")

                                    v-text-field(outline
                                    required
                                    v-model="form.telefono"
                                    prepend-inner-icon="chevron_right"
                                    label="Teléfono")

                                    div
                                      v-flex(v-if="loading === true", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-progress-circular(indeterminate='', color='primary')
                                      v-flex(v-if="loading === false", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                            v-btn(style="background:grey;border-radius:10px;", @click.native='save') Guardar
                                            v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:50%;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
                      v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                        span.white--text Lista de Clientes
                    v-data-table.elevation-1(:headers='headers', :items="directorio", :search='search2' style="margin-top: 5px;", hide-actions, :pagination.sync="pagination")
                      template(slot='items', slot-scope='props')
                        td(v-if='props.item.nombre') {{ props.item.nombre }}
                        td(v-else) N/A
                        td(v-if='props.item.identificacion') {{ props.item.identificacion }}
                        td(v-else) N/A
                        td(v-if='props.item.direccion') {{ props.item.direccion }}
                        td(v-else) N/A
                        td(v-if='props.item.telefono') {{ props.item.telefono }}
                        td(v-else) N/A
                        td.justify-start.layout.px-0
                          v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                            v-icon(color='teal') edit
                          v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
                            v-icon(color='pink') delete
                    v-card
                      v-flex(xs12)
                        v-layout(wrap='')
                          v-flex(xs6)
                          v-flex(xs5 style='display: flex;justify-content: flex-end;align-items: center;')
                            div(style='margin: 10px;')
                              span {{skip}} a {{max}} elementos de {{total}}
                            div(style='margin: 2px;')
                              v-btn.mx-0(id='prev' icon, @click='prev()', style='background: gainsboro;border-radius:50%;' hidden)
                                v-icon(color='black' style='font-size: 1.8rem;') arrow_left
                            div(style='margin: 2px;' v-for='(item,index) in listado')
                              v-btn.mx-0(:id='"num"+item', @click='seleccion(item)' icon, style='background: gainsboro;border-radius:50%;')
                                span(style='font-weight: bold;') {{item}}
                            div(style='margin: 2px;')
                              v-btn.mx-0(id='next' icon, @click='next()', style='background: gainsboro;border-radius:50%;')
                                v-icon(color='black' style='font-size: 1.8rem;') arrow_right
                          v-flex(xs1)
                          v-flex(xs11 style='display: flex;justify-content: flex-end;')
                            v-flex#sll(xs2 style='display: flex;justify-content: flex-end;align-items: center;margin-right: 17px;')
                              v-select(id="paginas"
                              outline
                              @change='seleccion(selpag)'
                              :items="paginas"
                              v-model="selpag"
                              dense
                              required
                              label="Página:")
                //===========================================================Modal Espera==============================================================================================
                v-dialog(v-model='dialog3', persistent max-width='400px' style='background:#f1f2f1')
                  v-container(style='max-width: 400px;background:#f1f2f1')
                    div(style="margin-top: 20px;")
                        v-flex(xs12 sm12)
                          v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-flex(xs12)
                              v-card-title(style="padding: 7px;").justify-center
                                h4.black--text  Esperando Respuesta del Servidor.!!
                            v-flex(xs12)
                                div(style='display: flex;justify-content: center;').text-xs-center
                                  img(src='../assets/waiting.gif', with='200' ,height='150')
                            v-flex(xs12)
                              v-card-title(style="padding: 7px;").justify-center
                                h5.black--text  Por favor espere...

</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mDirectorio",
  components: {},
  data: () => ({
    ruta: server + ":" + port,
    dialog: false,
    loading: false,
    dialog2: false,
    cantSMS: 0,
    cantCont: false,
    bloquear: false,
    numEsp: false,
    skip: 0,
    skip2: 0,
    paginas: [],
    cantDirectorio: [],
    loading2: false,
    loading3: false,
    listado: [],
    selpag: "",
    dialog3: false,
    actual: 0,
    total: 0,
    directorioSel: [],
    search: "",
    search2: "",
    noData: true,
    pagination: {
      rowsPerPage: 20
    },
    active: null,
    excel: {
      excelName: "",
      excelUrl: "",
      excelFile: ""
    },
    headers: [
      { text: "Cliente", value: "nombre" },
      { text: "Identificación", value: "identificacion" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "telefono" },
      { text: "Acción" }
    ],
    bloquear4: false,
    bloquear3: false,
    formSMS: {
      telefonos: [],
      todos: false,
      cantidad: [],
      mensaje: ""
    },
    defaultFormSMS: {
      telefonos: [],
      todos: false,
      cantidad: [],
      mensaje: ""
    },
    form: {
      nombre: "",
      identificacion: "",
      direccion: "",
      telefono: ""
    },
    editedIndex: -1,
    defaultForm: {
      nombre: "",
      identificacion: "",
      direccion: "",
      telefono: ""
    },
    max: 0,
    directorio: [],
    directorio2: []
  }),
  watch: {
    "formSMS.cantidad"() {
      if (this.formSMS.cantidad.length === 3) {
        this.formSMS.cantidad.splice(1, 1);
      }
    },
    "formSMS.mensaje"() {
      if (this.formSMS.mensaje) {
        if (
          this.formSMS.mensaje.length >= 1 &&
          this.formSMS.mensaje.length <= 160
        ) {
          this.cantSMS = 1;
        } else if (
          this.formSMS.mensaje.length >= 161 &&
          this.formSMS.mensaje.length <= 305
        ) {
          this.cantSMS = 2;
        } else if (
          this.formSMS.mensaje.length >= 306 &&
          this.formSMS.mensaje.length <= 457
        ) {
          this.cantSMS = 3;
        } else if (
          this.formSMS.mensaje.length >= 458 &&
          this.formSMS.mensaje.length <= 609
        ) {
          this.cantSMS = 4;
        } else if (
          this.formSMS.mensaje.length >= 610 &&
          this.formSMS.mensaje.length <= 761
        ) {
          this.cantSMS = 5;
        }
      } else {
        this.cantSMS = 0;
      }
    },
    "formSMS.todos"() {
      if (this.formSMS.todos) {
        this.bloquear = true;
        this.bloquear3 = true;
        this.formSMS.telefonos = [];
        this.directorioSel = [];
      } else {
        this.bloquear = false;
        this.bloquear3 = false;
      }
    },
    numEsp(val) {
      if (val) {
        this.bloquear4 = true;
        this.formSMS.telefonos = [];
        this.directorioSel = [];
      } else {
        this.formSMS.telefonos = [];
        this.directorioSel = [];
        this.bloquear4 = false;
      }
    },
    cantCont(val) {
      if (val) {
        this.bloquear = true;
        this.formSMS.telefonos = [];
        this.directorioSel = [];
        this.numEsp = false;
      } else {
        this.formSMS.telefonos = [];
        this.directorioSel = [];
        this.bloquear = false;
        this.formSMS.cantidad = [];
      }
    },
    "formSMS.telefonos"() {
      if (this.formSMS.telefonos && this.formSMS.telefonos.length > 0) {
        for (let i = 0; i < this.formSMS.telefonos.length; i++) {
          this.formSMS.telefonos[i] = this.formSMS.telefonos[i].substring(0,11);
        }
      }
    },
    search(data) {
      if (data) {
        this.skip2 = 0;
        this.busqDir(data);
      } else {
        this.getDat();
      }
    },
    search2(data) {
      if (data) {
        this.busqinit(data);
      }
    }
  },
  mounted() {
    this.scroll();
  },
  created() {
    this.initialize();
  },
  methods: {
    busqDir(data) {
      Api.get("directorio/search?search=" + data + "&skip=" + this.skip2).then(
        res => {
          this.directorio2 = res.data;
        }
      );
    },
    getDat() {
      Api.get("directorio/skip2?skip=" + this.skip2).then(res => {
        this.directorio2 = res.data;
      });
    },
    async sendSMS() {
      if (
        (this.formSMS.mensaje && this.formSMS.telefonos.length > 0 && !this.cantCont) ||
        (this.formSMS.mensaje && this.formSMS.todos && !this.cantCont) ||
        (this.formSMS.mensaje && this.cantCont && this.formSMS.cantidad.length > 0 && !this.numEsp) ||
        (this.formSMS.mensaje && this.cantCont && this.formSMS.cantidad.length > 0 && this.numEsp && this.formSMS.telefonos.length > 0)
      ) {
        var normalize = (function() {
          var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
            to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
            mapping = {};
          for (let i = 0; i < from.length; i++) {
            mapping[from.charAt(i)] = to.charAt(i);
          }
          return function(str) {
            var ret = [];
            for (let i = 0; i < str.length; i++) {
              var c = str.charAt(i);
              if (mapping.hasOwnProperty(str.charAt(i))) {
                ret.push(mapping[c]);
              } else {
                ret.push(c);
              }
            }
            return ret.join("");
          };
        })();
        this.formSMS.mensaje = await normalize(this.formSMS.mensaje);
        this.loading3 = true;
        Api.post("directorio/SMS", this.formSMS)
          .then(res => {
            console.log(res.data)
            this.loading3 = false;
            if (res.data.message) {
              this.$swal.fire("Error encontrado", res.data.message, "error");
            } else {
              this.$swal.fire("Felicidades.!", res.data.sucess, "success");
              this.search = "";
              this.directorioSel = [];
              this.cantCont = false;
              this.bloquear4 = false;
              this.bloquear = false;
              this.formSMS.cantidad = [];
              this.bloquear = false;
              this.bloquear3 = false;
              this.numEsp = false;
              this.formSMS = Object.assign({}, this.defaultFormSMS);
              this.initialize();
            }
          })
          .catch(err => {
            this.loading3 = false;
            this.$swal.fire(
              "Error de conexión",
              "Problemas al conectar al servidor, por favor intente nuevamente o contacte con el administrador.",
              "error"
            );
          });
      } else {
        this.$swal.fire(
          "Datos incompletos",
          "Estimado cliente, por favor ingrese todos los datos solicitados para continuar con la operación.",
          "error"
        );
      }
    },
    scroll() {
      document.getElementById("templt").onscroll = () => {
        let bottomOfWindow =
          document.getElementById("templt").scrollHeight - document.getElementById("templt").scrollTop ===
          document.getElementById("templt").offsetHeight;
        let bottomOfWindow2 =
          Math.trunc(document.getElementById("templt").scrollHeight - document.getElementById("templt").scrollTop) ===
          document.getElementById("templt").offsetHeight - 1;
        if (bottomOfWindow || bottomOfWindow2) {
          this.loading2 = true;
          this.skip2 = this.skip2 + 20;
          Api.get("directorio/skip2?skip=" + this.skip2 + "&search=" + this.search).then(res => {
            for (let i = 0; i < res.data.length; i++) {
              this.directorio2.push(res.data[i]);
            }
            this.loading2 = false;
          });
        }
      };
    },
    close2() {
      this.skip2 = 0;
      this.dialog = false;
      this.loading2 = false;
      this.direccion = false;
      this.getDat();
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    borarSel(item) {
      for (let i = 0; i < this.directorioSel.length; i++) {
        if (this.directorioSel[i].telefono === item.telefono) {
          this.directorioSel.splice(i, 1);
        }
      }
    },
    seleccionados(item) {
      let bool = false;
      for (let i = 0; i < this.directorioSel.length; i++) {
        if (item.telefono === this.directorioSel[i].telefono) {
          bool = true;
        }
      }
      if (bool) {
        this.$swal.fire(
          "Error encontrado",
          "Estimado cliente, el número que quiere seleccionar ya esta en la lista de seleccionados.",
          "error"
        );
      } else {
        this.directorioSel.push(item);
      }
    },
    seleccionar() {
      this.formSMS.telefonos = [];
      for (let i = 0; i < this.directorioSel.length; i++) {
        this.formSMS.telefonos.push(this.directorio2[i].telefono);
      }
      this.dialog = false;
    },
    seleccion(item) {
      this.dialog3 = true;
      Api.get("directorio/pag?search=" + this.search2 + "&id=" + item)
        .then(res => {
          this.dialog3 = false;
          this.max = res.data.max;
          this.skip = this.max - res.data.resultados.length;
          this.directorio = res.data.resultados;
          for (let i = 1; i <= res.data.paginas; i++) {
            this.paginas.push(i);
          }
          document
            .getElementById("num" + this.actual)
            .setAttribute(
              "style",
              "background-color:gainsboro;border-radius:50%;"
            );
          document.getElementById("next").hidden = false;
          document.getElementById("prev").hidden = false;
          if (this.skip === 0) {
            document.getElementById("prev").hidden = true;
          }
          if (res.data.tope === true) {
            document.getElementById("next").hidden = true;
          }
          let a = item;
          this.listado = [];
          for (let i = 0; i < 4; i++) {
            if (a <= res.data.pag) {
              this.listado.push(a);
            }
            a++;
          }
          this.$nextTick(() => {
            this.actual = item;
            this.selpag = item;
            if (
              this.listado.length > 0 &&
              document.getElementById("num" + this.actual)
            ) {
              document
                .getElementById("num" + this.actual)
                .setAttribute(
                  "style",
                  "background-color:#adadad;border-radius:50%;"
                );
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.dialog3 = false;
        });
    },
    prev() {
      let info = {
        max: this.skip,
        ult: false,
        num: null
      };
      let dat = this.actual - 1;
      if (dat < this.listado[0]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog3 = true;
      Api.post("directorio/prev?search=" + this.search2, info)
        .then(res => {
          this.total = res.data.total;
          if (res.data.resultados.length > 0) {
            this.paginas = [];
            this.max = res.data.max + 20;
            this.skip = res.data.max;
            this.directorio = res.data.resultados;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("next").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("prev").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[0] - 5;
            this.listado = [];
            if (a < 0) {
              a = 0;
              document.getElementById("prev").hidden = true;
            }
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual - 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual-1))) {
                  document.getElementById("num"+(this.actual-1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-2))) {
                  document.getElementById("num"+(this.actual-2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-3))) {
                  document.getElementById("num"+(this.actual-3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual - 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog3 = false;
        })
        .catch(err => {
          this.dialog3 = false;
          console.log(err);
        });
    },
    next() {
      let info = {
        max: this.max,
        ult: false,
        num: null
      };
      let dat = this.actual + 1;
      if (dat > this.listado[this.listado.length - 1]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog3 = true;
      Api.post("directorio/next?search=" + this.search2, info)
        .then(res => {
          this.total = res.data.total;
          if (res.data.resultados.length > 0) {
            this.max = res.data.max;
            this.skip = this.max - res.data.resultados.length;
            this.directorio = res.data.resultados;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("prev").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("next").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[this.listado.length - 1];
            this.listado = [];
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual + 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual+3))) {
                  document.getElementById("num"+(this.actual+3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+2))) {
                  document.getElementById("num"+(this.actual+2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+1))) {
                  document.getElementById("num"+(this.actual+1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual + 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog3 = false;
        })
        .catch(err => {
          this.dialog3 = false;
          console.log(err);
        });
    },
    pickFile() {
      this.$refs.exceel.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.excel.excelName = files[0].name;
        if (this.excel.excelName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", async () => {
          this.excel.excelUrl = fr.result;
          this.excel.excelFile = files[0]; // this is an image file that can be sent to server...
          this.subirExcel();
        });
      } else {
        this.excel.excelName = "";
        this.excel.excelFile = "";
        this.excel.excelUrl = "";
      }
    },
    subirExcel() {
      this.dialog3 = true;
      const data = new FormData();
      if (this.excel.excelFile) {
        data.append("excel", this.excel.excelFile);
      }
      Api.post("directorio/excel", data)
        .then(res => {
          console.log(res.data);
          this.dialog3 = false;
          this.initialize();
          this.$swal.fire(
            "Felicidades.!",
            "El documento excel ha sido procesado exitosamente.",
            "success"
          );
        })
        .catch(err => {
          this.dialog3 = false;
          console.log(err);
          this.$swal.fire(
            "Error de conexión",
            "Problemas al conectarse con el servidor, por favor intente nuevamente.",
            "error"
          );
        });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog2 = false;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("directorio/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
                this.$swal.fire(
                  "Error de conexión",
                  "Problemas al conectarse con el servidor, por favor intente nuevamente.",
                  "error"
                );
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.directorio.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog2 = true;
    },
    busqinit(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      Api.get("directorio/skip?search=" + item)
        .then(res => {
          this.directorio = [];
          this.listado = [];
          this.total = res.data.total;
          for (let i = 0; i < res.data.resultados.length; i++) {
            this.directorio.push(res.data.resultados[i]);
          }
          this.max = this.directorio.length;
          if (this.max === 0) {
            this.skip = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.skip = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.skip = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    initialize() {
      this.dialog3 = true;
      this.editedIndex = -1;
      this.listado = [];
      this.actual = 0;
      this.max = 0;
      this.skip = 0;
      this.cantDirectorio = [];
      Api.get("directorio/skip2?skip=" + this.skip2).then(res => {
        this.directorio2 = res.data;
      });
      Api.get("directorio/skip?search=" + this.search2)
        .then(res => {
          this.dialog3 = false;
          this.directorio = res.data.resultados;
          this.paginas = [];
          this.total = res.data.total;
          for (let i = 1; i <= res.data.total; i++) {
            this.cantDirectorio.push(i);
          }
          this.max = res.data.resultados.length;
          if (this.max === 0) {
            this.skip = 0;
            document.getElementById("next").hidden = true;
          } else {
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            if (this.max < 20) {
              this.skip = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.skip = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
            });
            this.selpag = 1;
            this.actual = 1;
          }
        })
        .catch(err => {
          console.log(err);
          this.dialog3 = false;
        });
    },
    save() {
      this.loading = true;
      if (
        this.form.nombre &&
        this.form.identificacion &&
        this.form.direccion &&
        this.form.telefono
      ) {
        if (this.editedIndex > -1) {
          Api.put("directorio", this.form)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog2 = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false;
              this.close();
              for (let i = 0; i < this.directorio.length; i++) {
                if (this.directorio[i]._id === res.data._id) {
                  this.$set(this.directorio, i, res.data);
                }
              }
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          Api.post("directorio", this.form)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog2 = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.loading = false;
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.loading = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
<style lang="scss">
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  -webkit-transform: none !important;
  transform: none !important;
}
.v-text-field__details {
  margin: 0px !important;
  padding: 0 !important;
  height: 0 !important;
}
.v-input--selection-controls {
  margin: 0px !important;
  display: flex;
  justify-content: center;
}
@import "~@/assets/scss/cajero.scss";
</style>
