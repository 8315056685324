<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Rporte
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Reporte de Productos
    v-dialog(v-model='dialog', persistent max-width='900px')
          v-container(style='background-color:white !important;max-width: 900px;')
            v-card-title.justify-center
                span.headline Usuario
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs8)
                          v-form(enctype="multipart/form-data")
                            v-text-field(id="nomb"
                                outline
                                readonly
                                v-model="seleccion.nombre"
                                prepend-inner-icon="description"
                                label="Nombre:")

                            v-text-field(id="sk"
                                outline
                                readonly
                                v-model="seleccion.sku"
                                prepend-inner-icon="description"
                                label="SKU:")

                            v-text-field(id="precio"
                                outline
                                readonly
                                v-if="seleccion.precio"
                                v-model="seleccion.precio"
                                prepend-inner-icon="description"
                                label="Precio en $:")

                            v-text-field(id="preciobs"
                                outline
                                readonly
                                v-if="seleccion.preciobs"
                                v-model="seleccion.preciobs"
                                prepend-inner-icon="description"
                                label="Precio en BsF:")

                            v-text-field(id="moneda"
                                outline
                                readonly
                                v-if="seleccion.moneda"
                                v-model="seleccion.moneda"
                                prepend-inner-icon="description"
                                label="Moneda:")

                            v-text-field(id="existencia"
                                outline
                                readonly
                                v-if="seleccion.existencia"
                                v-model="seleccion.existencia"
                                prepend-inner-icon="description"
                                label="Existencia:")
                            div(style='display: flex;justify-content: center;margin-bottom: 10px;')
                                img(:src='ruta + seleccion.imagen[0].url', height='150', width='310', v-if='seleccion.imagen && seleccion.imagen[0] && seleccion.imagen[0].url')

                            div(style="display: flex;justify-content: center;")
                                v-btn(style="background:grey;border-radius:10px;", @click.native='save') Listo
                                v-btn(style="background:#01458c;;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:50%;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color: #01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Reportes
        v-data-table.elevation-1(:headers='headers', :items="productos", :search='search', style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.producto.nombre }}
            td {{ props.item.fecha | moment('DD-MM-YYYY H:mm A')}}
            td {{ props.item.estatus }}
            td.justify-start.layout.px-0
                v-btn(v-if='mod').mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                    v-icon(color='teal') visibility
</template>

<script>
import Api from "@/services/methods";
export default {
  name: "mReporte",
  data: () => ({
    search: "",
    mod: "",
    ruta: "",
    dialog: false,
    editedIndex: -1,
    productos: [],
    id: "",
    seleccion: {},
    headers: [
      { text: "Producto", value: "producto.nombre" },
      { text: "Fecha", value: "fecha" },
      { text: "Estatus", value: "estatus" },
      { text: "Acciones" }
    ]
  }),
  sockets: {
    rep() {
      this.initialize();
    }
  },
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (this.$store.state.merkafur.permisos[i].ruta === "Reporte") {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
          }
        }
      }
    },
    initialize() {
      this.id = "";
      this.seleccion = {};
      this.editedIndex = -1;
      Api.get("reportes").then(res => {
        this.productos = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].estatus === "Pendiente") {
            this.productos.push(res.data[i]);
          }
        }
      });
    },
    close() {
      this.seleccion = {};
      this.id = "";
      this.dialog = false;
      this.initialize();
    },
    editItem(item) {
      this.editedIndex = this.productos.indexOf(item);
      this.seleccion = item.producto;
      this.id = item._id;
      this.dialog = true;
    },
    save() {
      let info = {
        _id: this.id,
        producto: this.seleccion._id,
        estatus: "Verificado"
      };
      Api.put("reportes", info)
        .then(res => {
          this.dialog = false;
          this.$swal.fire(
            "Felicidades.!",
            "Reporte verificado exitosamente.",
            "success"
          );
          this.initialize();
        })
        .catch(err => {
          console.log(err);
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
        });
    }
  }
};
</script>
