<template lang="pug">
div(style="margin-top: -24px;margin-left: 0px;margin-right: 0px;")
  #Productos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Productos
        div(style="margin-top: 20px;")
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinEst("c")')
                      v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                          span(style='display:flex;justify-content:center;font-size:20px') Total de Productos
                          h1(style='display:flex;justify-content:center') {{tot}}
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                      v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                          span(style='display:flex;justify-content:center;font-size:20px') Productos Disponibles
                          h1(style='display:flex;justify-content:center') {{disp}}
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                      v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                          span(style='display:flex;justify-content:center;font-size:20px') Productos No Disponibles
                          h1(style='display:flex;justify-content:center') {{nodisp}}
              v-flex(xs4 sm4 style='margin-top:15px')
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinEst(null)')
                      v-card(style='width:300px;height:120px;border-radius:10px' ).elevation-3
                        span(style='display:flex;justify-content:center;font-size:20px') Productos Sin Estatus
                        h1(style='display:flex;justify-content:center') {{sines}}

              v-flex(xs4 sm4 style='margin-top:15px')
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinImg()')
                      v-card(style='width:300px;height:120px;border-radius:10px' ).elevation-3
                        span(style='display:flex;justify-content:center;font-size:20px') Productos Sin Imagenes
                        h1(style='display:flex;justify-content:center') {{sinimg}}
        v-layout(align-start='', justify-start='', row='', fill-height='')
          v-flex(xs6)
            v-btn.mb-2(@click='abrir()' style="border-radius:5px;background-color:#01458c;") Nuevo Producto
          v-flex(xs6 style='display:flex;justify-content: flex-end')
            download-excel(:data='json_data' name='Reporte Productos' v-if='json_data.length > 0' :fields="json_fields")
              v-btn.mb-2(style="border-radius:5px;background-color:#01458c;") Exportar Productos
            v-btn.mb-2(@click='actualizartasa()' style="border-radius:5px;background-color:#01458c;") Actualizar Precios
    v-dialog(v-model='dialog2', persistent max-width='800px')
      v-container(style='background-color:white !important;max-width: 800px;')
        v-card-title.justify-center
          span.headline Imagenes
        div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(wrap='')
              v-flex(xs4 style='padding: 5px;height: 200px;' v-for='(item,i) in photos')
                a(style='width:100%;height:100%' @click='imagenselect2(i,ruta + item)')
                  img(:src='ruta + item' style='width:100%;height:100%;border-radius: 15px;' v-if='mostrar').elevation-6
              v-flex(xs12 sm12)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:grey;border-radius:10px;", ) Guardar
                  v-btn(style="background:#01458c;border-radius:10px;", @click.native='close2') Salir
    v-dialog(v-model='dialog3', persistent max-width='400px')
      v-container(style='background-color:white !important;max-width: 400px;overflow:hidden;')
        div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(wrap='')
              v-flex(xs12 sm12)
                croppa(v-model='myCroppa' :width='350' :height='350' canvas-color='white').elevation-6
                  img(slot="initial" :src="phot" crossorigin="anonymous")
              v-flex(xs12 sm12)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:grey;border-radius:10px;", @click='modific()') Modificar
                  v-btn(style="background:#01458c;border-radius:10px;", @click.native='close3') Salir
    v-dialog(v-model='dialog', persistent max-width='1000px')
          v-container(style='background-color:white !important;max-width: 1000px;')
            v-card-title.justify-center
                span.headline Producto
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                  #diag
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs6 style='padding: 5px;')
                          v-text-field(
                            outline
                            ref="sku2"
                            v-model="form.sku"
                            prepend-inner-icon="description"
                            label="SKU:"
                            v-on:keyup.enter="save"
                            )
                        v-flex(xs6 style='padding: 5px;')
                          v-text-field(
                            outline
                            v-model="form.nombre"
                            prepend-inner-icon="description"
                            v-on:keyup.enter="save"
                            label="Nombre:")
                          div.recomen
                            div(v-for='(item,i) in nombres_recomendacion')
                              a(@click='nombreselect(item)') {{item}}
                        v-flex(xs6 style='padding: 5px;')
                          v-text-field(
                            outline
                            v-model="form.descripcion"
                            prepend-inner-icon="description"
                            v-on:keyup.enter="save"
                            label="Descripción:")
                          div.recomen
                            div(v-for='(item,i) in descripcion_recomendacion')
                              a(@click='descripcionselect(item)') {{item}}
                        v-flex(xs6 style='padding: 5px;')
                          div
                            v-combobox(prepend-inner-icon="description" outline v-model='form.keyword' :items='items2' :search-input.sync='search2' :hide-selected='hideSelected' label='Agregar keyword' :multiple='multiple' persistent-hint='' :small-chips='chips' :clearable='clearable')
                              template(v-if='noData' v-slot:no-data='')
                                v-list-item
                                  v-list-item-content
                                    v-list-item-title
                                    span No existen resultados para 
                                    strong "{{ search2 }}",
                                    span(style='padding-left: 5px;') Escriba el nombre del keyword y presione 
                                    kbd enter
                                    p
                                    span(style='padding-left: 5px;') para crear uno nuevo
                        v-flex(xs6 style='padding: 5px;')
                          div
                            //- v-combobox(prepend-inner-icon="description" outline v-model='model' :items='categoria' :search-input.sync='search3' :hide-selected='hideSelected' label='Categoría' persistent-hint='' :small-chips='chips' :clearable='clearable')
                            //-   template(v-slot:no-data='')
                            //-     v-list-item
                            //-       v-list-item-content
                            //-         v-list-item-title
                            //-           span No existen resultados para 
                            //-           strong "{{ search3 }}",
                            //-           span(style='padding-left: 5px;') Escriba el nombre de la categoría y presione 
                            //-           kbd enter
                            //-           p
                            //-           span(style='padding-left: 5px;') para crear uno nuevo
                            v-autocomplete(
                            outline
                            :items="categoria"
                            v-model="model"
                            prepend-inner-icon="chevron_right",
                            required
                            label="Categoria:")
                        v-flex(xs6 style='padding: 5px;')
                            v-autocomplete(
                            outline
                            :items="subcategorias"
                            v-model="form.categoria"
                            prepend-inner-icon="chevron_right",
                            required
                            label="Subcategoria:")
                        v-flex(xs6 style='padding: 5px;')
                            v-text-field(
                            outline
                            v-model="form.existencia"
                            prepend-inner-icon="chevron_right",
                            v-on:keyup.enter="save"
                            required
                            label="Existencia:")
                        v-flex(xs6 style='padding: 5px;')
                            v-text-field(
                            outline
                            v-model="form.limite"
                            prepend-inner-icon="chevron_right",
                            v-on:keyup.enter="save"
                            required
                            label="Mínimo de existencia para busqueda:")
                        v-flex(xs2)
                          //- v-switch(v-model='switch3' :label='`Precio en: ${sw3.toString()}`')
                          v-checkbox(v-model='switch3' label='Dolar' color='indigo' hide-details='')
                        v-flex(xs2)
                          v-checkbox(v-model='switch4' label='Bs' color='indigo' hide-details='')
                        v-flex(xs3 style='padding: 5px;' v-if='this.form.moneda == "dolar"')
                            v-text-field(
                            type='number'
                            outline
                            v-model="form.precio"
                            prepend-inner-icon="attach_money"
                            v-on:keyup.enter="save"
                            label="Precio en $US:")
                        v-flex(xs3 style='padding: 5px;'  v-if='this.form.moneda != "dolar"')
                            v-text-field(
                            type='number'
                            outline
                            disabled
                            readonly
                            v-model="form.precio"
                            prepend-inner-icon="attach_money"
                            v-on:keyup.enter="save"
                            label="Precio en $US:")
                        //- v-flex(xs3 style='padding: 5px;' v-if="!switch3")
                        //-     v-text-field(id="costo"
                        //-     type='number'
                        //-     outline
                        //-     v-model="form.preciodolar"
                        //-     v-on:keyup.enter="save"
                        //-     readonly
                        //-     disabled
                        //-     prepend-inner-icon="attach_money"
                        //-     label="Precio en $US:")
                        v-flex(xs3 style='padding: 5px;'  v-if='this.form.moneda == "bs"')
                            v-text-field(
                            type='number'
                            outline
                            v-model="form.preciobs"
                            v-on:keyup.enter="save"
                            
                            prepend-inner-icon="attach_money"
                            label="Precio en Bs:")
                        v-flex(xs3 style='padding: 5px;'  v-if='this.form.moneda != "bs"')
                            v-text-field(
                            type='number'
                            outline
                            v-model="form.preciobs"
                            v-on:keyup.enter="save"
                            disabled
                            readonly
                            prepend-inner-icon="attach_money"
                            label="Precio en Bs:")
                        //- v-flex(xs3 style='padding: 5px;' v-if="switch3")
                        //-     v-text-field(id="costo"
                        //-     type='number'
                        //-     outline
                        //-     v-model="form.preciobscalc"
                        //-     v-on:keyup.enter="save"
                        //-     readonly
                        //-     disabled
                        //-     prepend-inner-icon="attach_money"
                        //-     label="Precio en Bs:")
                        
                        v-flex(xs2 style='padding: 5px;')
                            v-text-field(
                            type='number'
                            outline
                            v-model="form.costo"
                            v-on:keyup.enter="save"
                            prepend-inner-icon="attach_money"
                            label="Costo:")
                        v-flex(xs6 style='padding: 5px;')
                          v-switch(v-model='switch2' :label='`Estatus: ${sw2.toString()}`', style='display:flex;justify-content:center')
                        v-flex(xs6 style='padding: 5px;')
                          v-switch(v-model='switch1' :label='`¿Habilitar para la compra?: ${sw1.toString()}`', style='display:flex;justify-content:center')
                        v-flex(xs12 style='padding: 5px;')
                          v-layout(wrap)
                            v-flex(xs4 v-for='(item,i) in form.imagen' style='padding: 10px;')
                              v-card(style='width: 100%;height: 190px;' v-if='item.imageName').elevation-3
                                div(style='position:absolute;width:100%;display:flex;justify-content:flex-end')
                                  v-btn(@click="indice(i, item.imageName)",color='error').btnnn X
                                img(:src='item.imageUrl', v-if='item.imageUrl' style='width: 100%;height:100%')
                              v-card(style='width: 100%;height: 190px;' v-if='item.url').elevation-3
                                div(style='position:absolute;width:100%;display:flex;justify-content:flex-end')
                                  v-btn(@click="indice(i, item.url)",color='error').btnnn X
                                img(:src='ruta + item.url', v-if='item.url' style='width: 100%;height:100%')
                          v-text-field(outline label='Seleccionar imagenes desde pc', @click='pickFile', prepend-inner-icon='attach_file', multiple)
                          input(
                            type="file"
                            style="display: none"
                            ref="image"
                            accept="image/*"
                            @change="onFilePicked"
                            multiple)
                        v-flex(xs12 style='padding: 5px;')
                          div.imagenes
                            div(v-for='(item,i) in imagenes_recomendacion' @click='imagenselect(i, item)')
                              img(:src='item.img')
                              span {{item.medidas}}
                              img(v-if='item.select' src='@/assets/check.png').check
                          div
                            v-flex(xs12 sm12)
                              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-btn(style="background:grey;border-radius:10px;", @click.native='save' :disable='guard') Guardar
                                v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-text-field(v-model='search5', append-icon='search', label='Buscar' style='width:50%;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Productos
        v-data-table.elevation-1(:headers='headers', :items="productos", :search='search5' style="margin-top: 5px;", hide-actions, :pagination.sync="pagination")
          template(slot='items', slot-scope='props')
            td {{ props.item.nombre }}
            td {{ props.item.sku }}
            td(v-if="props.item.precio") {{ props.item.precio }}
            td(v-if="!props.item.precio") {{ "- -" }}
            td(v-if="props.item.preciobs") {{ props.item.preciobs }}
            td(v-if="!props.item.preciobs") {{ "- -" }}
            td(v-if="props.item.existencia") {{ props.item.existencia }}
            td(v-if="!props.item.existencia")  {{ "- -" }}
            td#sll(v-if=" props.item.categoria") 
              v-select(
                outline
                :items="subcategorias"
                v-model="props.item.categoria._id"
                @change='actSubc(props.item, props.item.categoria._id)'
                required
                label="Subcategoria:")
            td(v-if="!props.item.categoria")  {{ "- -" }}
            td(v-if='') {{ props.item.estatus }}
            td(v-if="props.item.moneda") {{ props.item.moneda }}
            td(v-if="!props.item.moneda")  {{ "- -" }}
            td.justify-start.layout.px-0
              v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                v-icon(color='teal') edit
              v-btn.mx-0(v-if='props.item.imagen && props.item.imagen.length > 0' icon, @click='open(props.item)', style='margin-top: -9px;')
                v-icon(color='#01458c') crop
              v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
                v-icon(color='pink') delete
        v-card
          v-flex(xs12)
            v-layout(wrap='')
              v-flex(xs6)
              v-flex(xs5 style='display: flex;justify-content: flex-end;align-items: center;')
                div(style='margin: 10px;')
                  span {{min}} a {{max}} elementos
                div(style='margin: 2px;')
                  v-btn.mx-0(id='prev' icon, @click='prev()', style='background: gainsboro;border-radius:50%;' hidden)
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_left
                //- div(style='margin: 2px;')
                //-   v-btn.mx-0(id='skip-prev' icon, @click='prev()', style='background: gainsboro;border-radius:50%;')
                //-     v-icon(color='black') skip_previous
                div(style='margin: 2px;' v-for='(item,index) in listado')
                  v-btn.mx-0(:id='"num"+item', @click='seleccion(item)' icon, style='background: gainsboro;border-radius:50%;')
                    span(style='font-weight: bold;') {{item}}
                //- div(style='margin: 2px;')
                //-   v-btn.mx-0(id='skip-next' icon, @click='next()', style='background: gainsboro;border-radius:50%;')
                //-     v-icon(color='black') skip_next
                div(style='margin: 2px;')
                  v-btn.mx-0(id='next' icon, @click='next()', style='background: gainsboro;border-radius:50%;')
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_right
              v-flex(xs1)
              v-flex(xs11 style='display: flex;justify-content: flex-end;')
                v-flex#sll(xs2 style='display: flex;justify-content: flex-end;align-items: center;margin-right: 17px;')
                  v-select(id="paginas"
                  outline
                  @change='seleccion(selpag)'
                  :items="paginas"
                  v-model="selpag"
                  dense
                  required
                  label="Página:")
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mProductos",
  data: () => ({
    estatussel: "c",
    json_data: [],
    imgsel: false,
    seleccionado: null,
    listado: [],
    guard: false,
    pagination: {
      rowsPerPage: 20
    },
    dialog4: false,
    mostrar: true,
    paginas: [],
    selpag: "",
    subcategorias: [],
    loading: false,
    subcategoriasres: [],
    switch1: true,
    dialog2: false,
    dialog3: false,
    myCroppa: {},
    phot: "",
    switch2: true,
    switch3: true,
    switch4: false,
    chips: true,
    multiple: true,
    index: null,
    search2: "",
    search3: "",
    search5: "",
    model: "",
    photos: [],
    todos:[],
    disponible:  [],
    nodisponible: [],
    sinestatus: [],
    hideSelected: true,
    noData: true,
    clearable: false,
    tasa: 0,
    actual: 0,
    editar: false,
    form: {
      nombre: "",
      categoria: "",
      descripcion: "",
      precio: 0,
      costo: 0,
      limite: "",
      existencia: 0,
      preciobs: 0,
      preciodolar: 0,
      preciobscalc: 0,
      moneda: 'dolar',
      // empleado: [],
      sku: "",
      keyword: "",
      estatus: "",
      estatus_compra: "",
      imagen: [],
      imagenes_recomend: []
    },
    dialog: false,
    editedIndex: -1,
    options: ["Disponible", "No disponible"],
    options2: ["Si", "No"],
    categoria: [],
    categoria2: [],
    ruta: server + ":" + port,
    productos: [],
    json_fields: {
      nombre: "nombre",
      sku: "sku",
      categoria: "categoria"
    },
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "SKU", value: "sku" },
      { text: "Precio $", value: "precio" },
      { text: "Precio Bs", value: "preciobs" },
      { text: "Existencia", value: "existencia" },
      { text: "SubCategoria", value: "categoria.nombre" },
      { text: "Estatus", value: "estatus" },
      { text: "Guia", value: "moneda" },
      { text: "Acción" }
    ],
    defaultForm: {
      nombre: "",
      categoria: "",
      descripcion: "",
      precio: 0,
      costo: 0,
      existencia: 0,
      limite: "",
      preciodolar: 0,
      preciobscalc: 0,
      moneda: 'dolar',
      // empleado: [],
      sku: "",
      preciobs: 0,
      keyword: "",
      estatus: "",
      estatus_compra: "",
      imagen: [],
      imagenes_recomend: []
    },
    items2: [],
    files: [],
    files2: [],
    idimg: "",
    sw1: "Si",
    sw2: "Disponible",
    sw3: "Dolar",
    seleccionadores: "",
    nombres_recomendacion: [],
    imagenes_recomendacion: [],
    descripcion_recomendacion: [],
    min: 0,
    max: 0,
    disp: 0,
    tot: 0,
    nodisp: 0,
    sines: 0,
    sinimg: 0,
    searchsku: "",
    topesin: false,
    topedis: false,
    topenodis: false
  }),
  watch: {
    search5(data) {
      if (data) {
        this.busqinit(data);
      }
    },
    "form.sku"(data) {
      if (data.length >= 10) {
        this.searchsku = data;
        this.nombres_recomendacion = [];
        this.descripcion_recomendacion = [];
        this.imagenes_recomendacion = [];
        setTimeout(() => {
          if (data === this.searchsku) {
            if (this.editedIndex === -1) {
              this.google(data);
              this.upc(data);
              this.rexdis(data);
            }
          }
        }, 600);
      }
    },
    form(data) {
      console.log(data);
    },
    "form.precio"(data) {
      if(this.form.moneda == 'dolar'){
        this.form.preciobs = (data * this.tasa).toFixed(2)
      }
    },
    "form.preciobs"(data) {
      if(this.form.moneda == 'bs'){
        this.form.precio = (data / this.tasa).toFixed(2)
      }
    },
    "form.categoria"(data) {
      if (data) {
        for (let i = 0; i < this.subcategoriasres.length; i++) {
          if (data === this.subcategoriasres[i].value) {
            this.model = this.subcategoriasres[i].padre;
          }
        }
      }
    },
    switch1() {
      if (this.switch1 === true) {
        this.sw1 = "Si"
      } else {
        this.sw1 = "No"
      }
    },
    model(val) {
      if (val) {
        this.subcategorias = [];
        for (let i = 0; i < this.subcategoriasres.length; i++) {
          if (val === this.subcategoriasres[i].padre) {
            this.subcategorias.push(this.subcategoriasres[i]);
          }
        }
      } else {
        this.subcategorias = []
        for (let i = 0; i < this.subcategoriasres.length; i++) {
          this.subcategorias.push(this.subcategoriasres[i]);
        }
      }
      // if (val.length > 1) {
      //   this.$nextTick(() => this.model = this.model)
      // }
    },
    switch2() {
      if (this.switch2 === true) {
        this.sw2 = "Disponible"
      } else {
        this.sw2 = "No disponible"
      }
    },
    switch3() {
      if (this.switch3 === true) {
        this.switch4 = false
        this.form.moneda = 'dolar'
      } else {
        this.form.moneda = 'bs'
        this.switch4 = true
      }
    },
    switch4() {
      if (this.switch4 === true) {
        this.switch3 = false
        this.form.moneda = 'bs'
      } else {
        this.switch3 = true
        this.form.moneda = 'dolar'
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    actSubc(item, id) {
      let info = {
        _id: item._id,
        categoria: id
      };
      Api.put("productos/subcategoria", info).then(res => {
        this.$swal.fire(
          "Felicidades.!",
          "Modificación realizada exitosamente.",
          "success"
        );
        for (let i = 0; i < this.productos.length; i++) {
          if (this.productos[i]._id === res.data._id) {
            this.$set(this.productos, i, res.data);
          }
        }
      });
    },
    seleccion(item) {
      this.dialog4 = true;
      Api.get(
        "productos/pag?search=" +
          this.search5 +
          "&id=" +
          item +
          "&estatus=" +
          this.estatussel +
          "&img=" +
          this.imgsel
      )
        .then(res => {
          this.dialog4 = false;
          this.max = res.data.max;
          this.min = this.max - res.data.resultado.length;
          this.productos = res.data.resultado;
          for (let i = 1; i <= res.data.paginas; i++) {
            this.paginas.push(i);
          }
          document
            .getElementById("num" + this.actual)
            .setAttribute(
              "style",
              "background-color:gainsboro;border-radius:50%;"
            );
          document.getElementById("next").hidden = false;
          document.getElementById("prev").hidden = false;
          if (this.min === 0) {
            document.getElementById("prev").hidden = true;
          }
          if (res.data.tope === true) {
            document.getElementById("next").hidden = true;
          }
          let a = item;
          this.listado = [];
          for (let i = 0; i < 4; i++) {
            if (a <= res.data.pag) {
              this.listado.push(a);
            }
            a++;
          }
          this.$nextTick(() => {
            this.actual = item;
            this.selpag = item;
            if (
              this.listado.length > 0 &&
              document.getElementById("num" + this.actual)
            ) {
              document
                .getElementById("num" + this.actual)
                .setAttribute(
                  "style",
                  "background-color:#adadad;border-radius:50%;"
                );
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    prev() {
      let info = {
        estatus: this.seleccionado,
        max: this.min,
        ult: false,
        num: null
      };
      let dat = this.actual - 1;
      if (dat < this.listado[0]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "productos/prev?search=" +
          this.search5 +
          "&estatus=" +
          this.estatussel +
          "&img=" +
          this.imgsel,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max + 20;
            this.min = res.data.max;
            this.productos = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("next").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("prev").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[0] - 5;
            this.listado = [];
            if (a < 0) {
              a = 0;
              document.getElementById("prev").hidden = true;
            }
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual - 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual-1))) {
                  document.getElementById("num"+(this.actual-1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-2))) {
                  document.getElementById("num"+(this.actual-2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-3))) {
                  document.getElementById("num"+(this.actual-3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual - 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    next() {
      let info = {
        estatus: this.seleccionado,
        max: this.max,
        ult: false,
        num: null
      };
      let dat = this.actual + 1;
      if (dat > this.listado[this.listado.length - 1]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "productos/next?search=" +
          this.search5 +
          "&estatus=" +
          this.estatussel +
          "&img=" +
          this.imgsel,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max;
            this.min = this.max - res.data.resultado.length;
            this.productos = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("prev").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("next").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[this.listado.length - 1];
            this.listado = [];
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual + 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual+3))) {
                  document.getElementById("num"+(this.actual+3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+2))) {
                  document.getElementById("num"+(this.actual+2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+1))) {
                  document.getElementById("num"+(this.actual+1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual + 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    // estat(estatus) {
    //   if (estatus === "Todos" && estatus != this.seleccionado) {
    //     this.productos = [];
    //     Api.get("productos/sinseleccion").then(res => {
    //       for (let i = 0; i < res.data.resultado.length; i++) {
    //         this.productos.push(res.data.resultado[i]);
    //       }
    //       this.tot = res.data.cantidad;
    //       this.max = this.productos.length;
    //       if (this.max === 0) {
    //         this.min = 0;
    //       } else {
    //         this.min = this.max - 30;
    //       }
    //     });
    //     // for (let i = 0; i < this.disponible.length; i++) {
    //     //   this.productos.push(this.disponible[i]);
    //     // }
    //     // for (let i = 0; i < this.nodisponible.length; i++) {
    //     //   this.productos.push(this.nodisponible[i]);
    //     // }
    //     // for (let i = 0; i < this.sinestatus.length; i++) {
    //     //   this.productos.push(this.sinestatus[i]);
    //     // }
    //     // this.tot = this.disp + this.nodisp + this.sines;
    //   } else if (estatus === "Disponible" && estatus != this.seleccionado) {
    //     this.productos = this.disponible;
    //     if (this.topedis) {
    //       document.getElementById("next").hidden = true;
    //     } else {
    //       document.getElementById("next").hidden = false;
    //     }
    //     // this.tot = this.disp;
    //   } else if (estatus === "No disponible" && estatus != this.seleccionado) {
    //     this.productos = this.nodisponible;
    //     if (this.topenodis) {
    //       document.getElementById("next").hidden = true;
    //     } else {
    //       document.getElementById("next").hidden = false;
    //     }
    //     // this.tot = this.nodisp;
    //   } else if (estatus === null && estatus != this.seleccionado) {
    //     this.productos = this.sinestatus;
    //     // this.tot = this.sines;
    //     if (this.topesin) {
    //       document.getElementById("next").hidden = true;
    //     } else {
    //       document.getElementById("next").hidden = false;
    //     }
    //   } else {
    //     this.productos = [];
    //     Api.get("productos/sinseleccion").then(res => {
    //       for (let i = 0; i < res.data.resultado.length; i++) {
    //         this.productos.push(res.data.resultado[i]);
    //       }
    //       this.tot = res.data.cantidad;
    //       this.max = this.productos.length;
    //       if (this.max === 0) {
    //         this.min = 0;
    //       } else {
    //         this.min = this.max - 30;
    //       }
    //       this.seleccionado = "";
    //       return;
    //     });
    //     // for (let i = 0; i < this.disponible.length; i++) {
    //     //   this.productos.push(this.disponible[i]);
    //     // }
    //     // for (let i = 0; i < this.nodisponible.length; i++) {
    //     //   this.productos.push(this.nodisponible[i]);
    //     // }
    //     // for (let i = 0; i < this.sinestatus.length; i++) {
    //     //   this.productos.push(this.sinestatus[i]);
    //     // }
    //     // this.tot = this.disp + this.nodisp + this.sines;
    //     // this.max = this.productos.length;
    //     // if (this.max === 0) {
    //     //   this.min = 0;
    //     // } else {
    //     //   this.min = this.max - 30;
    //     // }
    //     // this.seleccionado = "";
    //     // return;
    //   }
    //   this.seleccionado = estatus;
    //   this.seleccionadores = estatus;
    //   this.max = this.productos.length;
    //   if (this.max === 0) {
    //     this.min = 0;
    //   } else {
    //     this.min = this.max - 30;
    //   }
    // },
    actualizartasa() {
      let formt = {
        tasa: this.tasa
      };
      Api.post("productos/tasa", formt).then(res => {
        console.log(res);
      });
    },
    force() {
      this.mostrar = false;
      this.$nextTick().then(() => {
        this.mostrar = true;
      });
    },
    modific() {
      this.myCroppa.generateBlob(
        blob => {
          const data = new FormData();
          data.append("imagen", blob, this.phot);
          data.append("id", this.idimg);
          Api.put("productos/recortar", data)
            .then(response => {
              this.$swal
                .fire({
                  title: "Felicidades.!",
                  text: "Su modificación se ha realizado exitosamente.",
                  type: "success"
                })
                .then(result => {
                  if (result.value) {
                    this.close3();
                    this.photos = [];
                    for (let i = 0; i < response.data.imagen.length; i++) {
                      this.photos.push(response.data.imagen[i].url);
                    }
                    this.force();
                  }
                });
            })
            .catch(error => {
              console.log(error);
              this.$swal.fire({
                title: "Error inesperado.!",
                text:
                  "Ha ocurrido un error, por favor verifique la información.",
                type: "error"
              });
            });
        },
        "image/jpeg",
        0.8
      );
    },
    open(item) {
      this.idimg = item._id;
      this.dialog2 = true;
      this.photos = [];
      for (let i = 0; i < item.imagen.length; i++) {
        this.photos.push(item.imagen[i].url);
      }
    },
    close2() {
      this.dialog2 = false;
      this.photos = [];
      this.idimg = "";
    },
    close3() {
      this.myCroppa.remove();
      this.dialog3 = false;
      this.phot = "";
    },
    imagenselect2(i, item) {
      this.index = i;
      this.phot = item;
      this.dialog3 = true;
    },
    imagenselect(i, item){
      if(this.imagenes_recomendacion[i].select){
        this.imagenes_recomendacion[i].select = false
        for (let e = 0; e < this.form.imagenes_recomend.length; e++) {
          if(this.form.imagenes_recomend[e] === item.img) {
            this.form.imagenes_recomend.splice(e, 1)
          }
        }
      }else{
        this.imagenes_recomendacion[i].select = true
        this.form.imagenes_recomend.push(item.img)
      }
    },
    nombreselect(data){
      this.form.nombre = data
    },
    descripcionselect(data){
      this.form.descripcion = data
    },
    rexdis(id) {
      Api.get("productos/rexdis?id=" + id).then(res =>{
        let data =res.data
        if(data.nombre) {
          this.nombres_recomendacion.push(data.nombre)
          let foto = new Image()
          foto.src = data.imagen
          this.imagenes_recomendacion.push({img:data.imagen, medidas: foto.width + "x"+ foto.height, select: false})
        }
      })
      .catch(err =>{
        console.log(err)
      })
    },
    upc(id) {
      Api.get("productos/upc?id=" + id).then(res =>{
        let data =res.data
        if(data.nombre){
          this.nombres_recomendacion.push(data.nombre)
          this.descripcion_recomendacion.push(data.descripcion)
        }
      })
      .catch(err =>{
        console.log(err)
      })
    },
    google(id){
      Api.get("productos/google?id=" + id).then(res =>{
        let data = res.data
        if(data.estatus != false){
          for (let i = 0; i < data.imagenes.length; i++) {
            let foto = new Image()
            foto.src = data.imagenes[i]
            this.imagenes_recomendacion.push({img:data.imagenes[i], medidas: foto.width + "x"+ foto.height, select: false})
          }
          for (let i = 0; i < data.descripciones.length; i++) {
            this.descripcion_recomendacion.push(data.descripciones[i])          
          }
          for (let i = 0; i < data.nombres.length; i++) {
            this.nombres_recomendacion.push(data.nombres[i])       
          }
        }
        
      })
      .catch(err =>{
        console.log(err)
      })
    },
    indice(indice, name) {
      this.form.imagen.splice(indice, 1);
      for (let i = 0; i < this.files.length; i++) {
        if (name === this.files[i].name) {
          this.files.splice(i, 1);
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      this.files = [];
      this.form.imagen = [];
      Array.prototype.push.apply(this.files, e.target.files);
      const files = e.target.files;
      if (files !== undefined) {
        for (let i = 0; i < files.length; i++) {
          const fr = new FileReader();
          fr.readAsDataURL(files[i]);
          fr.addEventListener("load", () => {
            this.form.imagen.push({
              imageName: this.files[i].name,
              imageUrl: fr.result
            });
          });
        }
      } else {
        this.form.imagen[0].imageName = "";
        this.form.imagen[0].imageFile = "";
        this.form.imagen[0].imageUrl = "";
      }
    },
    busqinit(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      Api.get(
        "productos/sinseleccion?search=" +
          item +
          "&estatus=" +
          this.estatussel +
          "&img=" +
          this.imgsel
      )
        .then(res => {
          this.productos = [];
          this.listado = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.disp = res.data.cantdi;
          this.nodisp = res.data.cantno;
          this.sines = res.data.cantnull;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    sinImg() {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      this.imgsel = true;
      this.estatussel = "c";
      Api.get(
        "productos/sinseleccion?estatus=" + this.estatussel + "&img=" + true
      )
        .then(res => {
          this.productos = [];
          this.listado = [];
          this.paginas = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.disp = res.data.cantdi;
          this.nodisp = res.data.cantno;
          this.sines = res.data.cantnull;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    sinEst(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      this.estatussel = item;
      this.imgsel = false;
      Api.get("productos/sinseleccion?estatus=" + item + "&img=" + this.imgsel)
        .then(res => {
          this.productos = [];
          this.listado = [];
          this.paginas = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.disp = res.data.cantdi;
          this.nodisp = res.data.cantno;
          this.sines = res.data.cantnull;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    initialize() {
      this.estatussel = "c";
      this.imgsel = false;
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.editedIndex = -1;
      this.dialog4 = true;
      Api.get("productos/").then(res => {
        this.json_data = [];
        for (let i = 0; i < res.data.length; i++) {
          let fila = {
            "nombre": res.data[i].nombre,
            "sku": res.data[i].sku,
            "categoria": res.data[i].categoria.nombre
          };
          this.json_data.push(fila);
        }
        console.log(this.json_data)
      });
      // Api.get("productos/withoutImagen").then(res => {
      //   this.json_data = [];
      //   for (let i = 0; i < res.data.length; i++) {
      //     let fila = {
      //       "nombre": res.data[i].nombre,
      //       "sku": res.data[i].sku,
      //       "categoria": res.data[i].categoria.nombre
      //     };
      //     this.json_data.push(fila);
      //   }
      // });
      Api.get("productos/sinseleccion?estatus=c&img=" + this.imgsel)
        .then(res => {
          this.productos = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.disp = res.data.cantdi;
          this.nodisp = res.data.cantno;
          this.sines = res.data.cantnull;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
            });
            this.selpag = 1;
            this.actual = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
      // Api.get("productos/estatus/null").then(res => {
      //   this.productos = res.data.resultado;
      //   this.sinestatus = [];
      //   for (let i = 0; i < res.data.resultado.length; i++) {
      //     this.sinestatus.push(res.data.resultado[i]);
      //   }
      //   if (this.seleccionado && this.seleccionado === null) {
      //     this.productos = this.sinestatus;
      //     this.max = res.data.max;
      //     this.min = this.max - 30;
      //   }
      //   this.sines = res.data.cantidad;
      //   if (res.data.tope === true) {
      //     document.getElementById("next").hidden = true;
      //     this.topesin = true;
      //   }
      // });
      // Api.get("productos/estatus/Disponible").then(res => {
      //   this.disponible = [];
      //   for (let i = 0; i < res.data.resultado.length; i++) {
      //     this.disponible.push(res.data.resultado[i]);
      //   }
      //   if (this.seleccionado && this.seleccionado === "Disponible") {
      //     this.productos = this.disponible;
      //     this.max = res.data.max;
      //     this.min = this.max - 30;
      //   }
      //   this.disp = res.data.cantidad;
      //   if (res.data.tope === true) {
      //     document.getElementById("next").hidden = true;
      //     this.topedis = true;
      //   }
      // });
      // Api.get("productos/estatus/no").then(res => {
      //   this.nodisponible = [];
      //   for (let i = 0; i < res.data.resultadolength; i++) {
      //     this.nodisponible.push(res.data.resultadolength[i]);
      //   }
      //   if (this.seleccionado && this.seleccionado === "No disponible") {
      //     this.productos = this.nodisponible;
      //     this.max = res.data.max;
      //     this.min = this.max - 30;
      //   }
      //   this.nodisp = res.data.cantidad;
      //   if (res.data.tope === true) {
      //     document.getElementById("next").hidden = true;
      //     this.topenodis = true;
      //   }
      // });
      Api.get("settings").then(res => {
        this.tasa = res.data[0].tasa;
      });
      Api.get("categoria/admin").then(res => {
        this.categoria = [];
        // this.categoria2 = res.data
        // for (let i = 0; i < res.data.length; i++) {
        //   this.categoria.push(res.data[i].nombre)
        // }
        res.data.map(cat => {
          this.categoria.push({ value: cat._id, text: cat.nombre });
        });
      });
      Api.get("categoria/subs").then(res => {
        this.subcategorias = [];
        this.subcategoriasres = [];
        res.data.map(cat => {
          this.subcategorias.push({ value: cat._id, text: cat.nombre, padre: cat.padre });
          this.subcategoriasres.push({ value: cat._id, text: cat.nombre, padre: cat.padre });
        });
      });
      Api.get("keyword").then(res=>{
        this.items2 = []
        for (let i = 0; i < res.data.length; i++) {
          this.items2.push(res.data[i].nombre)
        }
      });
    },
    abrir() {
      this.guard = false
      this.dialog = true
      this.search2 = ""
      this.search3 = ""
      setTimeout(() => {
        this.$refs.sku2.focus()
      }, 100);
    },
    close() {
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.switch1 = true;
      this.switch2 = true;
      this.switch3 = true;
      this.switch4 = false;
      this.search2 = "";
      this.search3 = "";
      this.files = [];
      this.respaldo = {};
      this.files2 = [];
      this.model = "";
      this.$refs.image.type = "text";
      this.$refs.image.type = "file";
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.form.imagenes_recomend = [];
      this.imagenes_recomendacion = [];
      this.nombres_recomendacion = [];
      this.descripcion_recomendacion = [];
      this.editedIndex = -1;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("productos/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.productos.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.imagen && item.imagen.length > 0) {
        this.files2 = item.imagen;
      }
      // if (item.categoria && item.categoria.nombre) {
      //   this.model = item.categoria.nombre;
      // }
      if (item.categoria && item.categoria.nombre) {
        this.form.categoria = item.categoria._id;
        this.model = item.categoria.padre;
      }
      if (item.moneda === "dolar") {
        this.switch3 = true;
      }
      if (item.moneda === "bs") {
        this.switch4 = true;
      }
      this.form._id = item._id;
      this.dialog = true;
    },
    save() {
      this.guard = true
      this.$refs.sku2.focus()
      // let bool = false
      // for (let i = 0; i < this.categoria2.length; i++) {
      //   if (this.model.toLowerCase() === this.categoria2[i].nombre.toLowerCase()) {
      //     this.form.categoria = this.categoria2[i]._id
      //     bool = true
      //   }
      // }
      // if (bool === false) {
      //   this.form.categoria2 = true
      //   this.form.categoria = this.model.toLowerCase()
      // }
      if (this.editedIndex > -1) {
        this.form.estatus = this.sw2
        this.form.estatus_compra = this.sw1
        this.form.empleado = this.$store.state.merkafur._id
        if (this.files.length === 0) {
          this.form.imagen = this.files2;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          data.append("imagen", file);
        }
        Api.put("productos", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.close();
            for (let i = 0; i < this.productos.length; i++) {
              if (this.productos[i]._id === res.data._id) {
                this.$set(this.productos, i, res.data);
              }
            }
            this.guard = false;
          })
          .catch(err => {
            console.log(err);
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
            this.guard = false
          });
      } else {
        this.form.estatus = this.sw2
        this.form.estatus_compra = this.sw1
        this.form.empleado = {
          fecha: this.$moment().format(),
          empleado: this.$store.state.merkafur._id,
          descripcion: {
            antes: null,
            despues: "Creación de producto"
          }
        }
        if (this.files.length === 0) {
          this.form.imagen = this.files2;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          data.append("imagen", file);
        }
        Api.post("productos", data)
          .then(res => {
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            ).then(res =>{
              this.$refs.sku2.focus()
            })
            this.guard = false
            this.form.imagen.imageName = "";
            this.form.imagen.imageFile = "";
            this.form.imagen.imageUrl = "";
            this.switch1 = true
            this.switch2 = true
            this.search2 = ""
            this.search3 = ""
            this.files = []
            this.respaldo = {}
            this.files2 = []
            this.model = ""
            this.$refs.image.type="text";
            this.$refs.image.type="file";
            this.imagenes_recomendacion = []
            this.nombres_recomendacion = []
            this.descripcion_recomendacion = []
            this.form = Object.assign({}, this.defaultForm);
            
          })
          .catch(err => {
            console.log(err);
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
            this.guard = false
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/productos.scss";
</style>
