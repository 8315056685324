<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #CrearOferta1
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Configuraciones
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#01458c", centered, dark)
          v-tab(:key='0', ripple='') Configuraciones Básicas
          v-tab(:key='1', ripple='') Cuentas Bancarias
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                v-container(style='background-color:white !important;')
                    div(style="margin-top: 20px;")
                        v-flex(xs12 sm12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs6)
                                    v-form(enctype="multipart/form-data")
                                    v-textarea(id="mwhatsapp"
                                    height='100'
                                    outline
                                    v-model="form.textwhatsapp"
                                    prepend-inner-icon="description"
                                    label="Mensaje de whatsapp:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Mensaje que se envia por whatsapp en consulta de precio
                                    v-textarea(id="mwhatsapp"
                                    height='100'
                                    outline
                                    v-model="form.textcompartir"
                                    prepend-inner-icon="description"
                                    label="Mensaje de compartir:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Mensaje que se envia al compartir
                                    v-textarea(id="direccion"
                                    height='100'
                                    outline
                                    v-model="form.direccion"
                                    prepend-inner-icon="description"
                                    label="Dirección:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Dirección de la empresa.

                                    v-text-field(id="telefono"
                                    outline
                                    v-model="form.nwhatsapp"
                                    prepend-inner-icon="phone"
                                    label="Telefono de whatsapp:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Teléfono de Whatsapp. el formato debe ser +584123800046

                                    v-text-field(id="telefono"
                                    outline
                                    v-model="form.telefono"
                                    prepend-inner-icon="phone"
                                    label="Telefono:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Teléfono de la empresa.

                                    v-text-field(id="correo"
                                    outline
                                    v-model="form.correo"
                                    prepend-inner-icon="email"
                                    label="Correo:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Correo donde se enviarán las notificaciones.

                                    v-text-field(id="password"
                                    outline
                                    v-model="form.password"
                                    prepend-inner-icon="code"
                                    label="Contraseña:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Contraseña del correo donde se enviarán las notificaciones.

                                    v-text-field(id="correo"
                                    outline
                                    v-model="form.tasa"
                                    prepend-inner-icon="attach_money"
                                    label="Tasa del $:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Tasa del dolar
                                    //- v-text-field(id="facebook"
                                    //- outline
                                    //- v-model="form.facebook"
                                    //- prepend-inner-icon="group"
                                    //- label="Facebook:")
                                    //-   template(v-slot:prepend='')
                                    //-     v-tooltip(bottom='')
                                    //-       template(v-slot:activator='{ on }')
                                    //-         v-icon(v-on='on') help
                                    //-       | Ejemplo: "https://www.facebook.com/frutas-frutas-9480".

                                    //- v-text-field(id="whatsapp"
                                    //- outline
                                    //- v-model="form.whatsapp"
                                    //- prepend-inner-icon="group"
                                    //- placeholder="https://api.whatsapp.com/send?phone=TUNUMEROAQUI"
                                    //- label="Whatsapp:")
                                    //-   template(v-slot:prepend='')
                                    //-     v-tooltip(bottom='')
                                    //-       template(v-slot:activator='{ on }')
                                    //-         v-icon(v-on='on') help
                                    //-       | Ejemplo: "https://api.whatsapp.com/send?phone=18094408553".

                                    v-text-field(id="iva"
                                    outline
                                    v-model="form.iva"
                                    prepend-inner-icon="attach_money"
                                    label="Iva:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Ejemplo de número a colocar "0.12".

                                    v-text-field(outline
                                    v-model="form.versionApp"
                                    prepend-inner-icon="code"
                                    placeholder="Ingrese la version actual de la app ANDROID"
                                    label="Versión de la app:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Versión actual de la app ANDROID.

                                    v-text-field(outline
                                    v-model="form.versionAppIos"
                                    prepend-inner-icon="code"
                                    placeholder="Ingrese la version actual de la app IOS"
                                    label="Versión de la app:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Versión actual de la app IOS.
                                    v-text-field(id="token"
                                    outline
                                    v-model="form.token"
                                    prepend-inner-icon="code"
                                    placeholder="Ingrese el token para el envío de mensajes de texto"
                                    label="Token:")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Token para realizar el envío de mensajes de texto.

                                    v-text-field(id="token"
                                    outline
                                    v-model="form.tlfDelivery"
                                    prepend-inner-icon="phone"
                                    placeholder="Número teléfonico de delivery")
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | El número teléfonico que se usará para que los empleados reciban los datos del cliente para realizar el delivery.

                                    //- v-text-field(id="moneda"
                                    //- outline
                                    //- v-model="form.moneda"
                                    //- prepend-inner-icon="attach_money"
                                    //- label="Moneda:")
                                    //-   template(v-slot:prepend='')
                                    //-     v-tooltip(bottom='')
                                    //-       template(v-slot:activator='{ on }')
                                    //-         v-icon(v-on='on') help
                                    //-       | Tipo de moneda a usar, Ejemplo: $RD.

                                    //- img(:src='ruta + imagen', height='150', width='310', v-if='imagen && !form.imagen.imageUrl')
                                    //- img(:src='form.imagen.imageUrl', height='150', width='310', v-if='form.imagen.imageUrl')
                                    //- v-text-field(outline label='Selecione el logo de la empresa', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file')
                                    //-   template(v-slot:prepend='')
                                    //-     v-tooltip(bottom='')
                                    //-       template(v-slot:activator='{ on }')
                                    //-         v-icon(v-on='on') help
                                    //-       | Logo de la empresa.
                                    //- input(
                                    //-   type="file"
                                    //-   style="display: none;"
                                    //-   ref="image"
                                    //-   accept="image/*"
                                    //-   @change="onFilePicked")

                                    v-switch(v-model='form.pedidos' :label='`¿Habilitar pedidos?: ${sw1.toString()}`')
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Habilita los pedidos en la aplicación.

                                    v-switch(v-model='form.delivery' :label='`¿Habilitar delivery?: ${sw2.toString()}`')
                                      template(v-slot:prepend='')
                                        v-tooltip(bottom='')
                                          template(v-slot:activator='{ on }')
                                            v-icon(v-on='on') help
                                          | Habilita el delivery en la aplicación.

                                    v-flex(xs12)
                                      v-layout(wrap style='display: flex;justify-content: center;')
                                        v-flex(xs12 style='padding-right: 10px;')
                                          span(style='margin-left: 33px;') Horario
                                          v-select(v-model='diasel' :items='days' outline='' label='Día' multiple prepend-inner-icon="date_range")
                                            template(v-slot:prepend='')
                                              v-tooltip(bottom='')
                                                template(v-slot:activator='{ on }')
                                                  v-icon(v-on='on') help
                                                | Horario de la empresa.
                                    v-flex(xs12)
                                      v-layout(wrap style='display: flex;justify-content: center;')
                                        v-flex(xs6 v-if="diasel.length > 0" style='padding-right:5px;')
                                          v-dialog(
                                            ref='opens',
                                            v-model='modalTime',
                                            :return-value.sync='horai',
                                            persistent,
                                            lazy,
                                            full-width,
                                            width='290px')
                                            v-text-field(
                                            slot='activator',
                                            :value='displayTime',
                                            outline=''
                                            label='Inicio del bloque'
                                            prepend-inner-icon='access_time',
                                            readonly)
                                              template(v-slot:prepend='')
                                                v-tooltip(bottom='')
                                                  template(v-slot:activator='{ on }')
                                                    v-icon(v-on='on') help
                                                  | Hora de apertura y cierre del bloque.
                                            v-time-picker(v-model='horai', actions)
                                              v-spacer
                                              v-btn(flat,
                                                style='color: #1976d2 !important;',
                                                @click='modalTime = false') Cancelar
                                              v-btn(flat,
                                                style='color: #1976d2 !important;',
                                                @click='$refs.opens.save(horai)') OK
                                        v-flex(xs6 v-if='horai' style='padding-left:5px;')
                                          v-dialog(
                                            ref='opens2',
                                            v-model='modalTime2',
                                            :return-value.sync='horaf',
                                            persistent,
                                            lazy,
                                            full-width,
                                            width='290px')
                                            v-text-field(
                                            label='Cierre del bloque'
                                            slot='activator',
                                            :value='displayTime2',
                                            outline=''
                                            prepend-inner-icon='access_time',
                                            readonly)
                                            v-time-picker(v-model='horaf', actions)
                                              v-spacer
                                              v-btn(flat,
                                                style='color: #1976d2 !important;',
                                                @click='modalTime2 = false') Cancelar
                                              v-btn(flat,
                                                style='color: #1976d2 !important;',
                                                @click='$refs.opens2.save(horaf)') OK
                                    v-flex(xs12, style='margin-bottom:20px;display: flex;justify-content: center;')
                                      v-btn(v-if="displayTime && displayTime2 && horaf", style="background:#01458c;border-radius:10px;color:white" @click.native='prueba') Agregar
                                    v-flex(xs12 style='display: grid;justify-content: center;margin-bottom:10px')
                                      v-data-table.elevation-1(:headers='headers2', :items="form.horario", :pagination.sync="pagination", style="margin-top: 5px;")
                                        template(slot='items', slot-scope='props')
                                          td {{ props.item.day }}
                                          td(v-if='props.item.bloques && props.item.bloques.length > 0')
                                            div(v-for='(item,i) in props.item.bloques' style='display: flex;align-items: center;') 
                                              p {{item.inicio}} - {{item.fin}}
                                              v-btn.mx-0(icon @click='removerBloque(props.index, i)' style='padding-bottom: 10px;')
                                                v-icon(color='red') delete
                                          td
                                            v-btn.mx-0(icon @click='remover(props.index)')
                                              v-icon(color='red') delete_forever

                                    div
                                        v-flex(xs12 sm12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                                v-btn(style="background:#01458c;border-radius:10px;", @click.native='save') Guardar
                                                v-btn(style="background:grey;border-radius:10px;", @click.native='actualizartasa') Actualizar precios
                                           
          v-tab-item(:key='1')
            v-card(flat='')
              v-flex(xs12)
                v-dialog(v-model='dialog', persistent max-width='700px')
                      v-btn.mb-2(slot='activator', style="border-radius:5px;background-color:#01458c;") Nuevo Banco
                      v-container(style='background-color:white !important;max-width: 700px;')
                        v-card-title.justify-center
                            span.headline Banco
                        div(style="margin-top: 20px;")
                            v-flex(xs12 sm12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-flex(xs8)
                                      v-form(enctype="multipart/form-data")
                                        v-select(id="categoria"
                                        outline
                                        :items="options"
                                        v-model="form_bank.categoria"
                                        prepend-inner-icon="chevron_right",
                                        label="Seleccione la categoría de la cuenta:")
                                        
                                        v-text-field(id="nombre"
                                        v-if='form_bank.categoria === "Nacional" || form_bank.categoria === "Internacional" || form_bank.categoria === "Zelle"'
                                        outline
                                        v-model="form_bank.nombre"
                                        prepend-inner-icon="description"
                                        label="Nombre:")
                                        
                                        v-text-field(id="apellido"
                                        v-if='form_bank.categoria === "Nacional" || form_bank.categoria === "Internacional" || form_bank.categoria === "Zelle"'
                                        outline
                                        v-model="form_bank.apellido"
                                        prepend-inner-icon="description"
                                        label="Apellido:")

                                        v-text-field(id="identificacion"
                                        v-if='form_bank.categoria === "Nacional"'
                                        outline
                                        v-model="form_bank.identificacion"
                                        prepend-inner-icon="description"
                                        label="Identificación:")

                                        v-text-field(id="banco"
                                        v-if='form_bank.categoria === "Nacional" || form_bank.categoria === "Internacional"'
                                        outline
                                        v-model="form_bank.banco"
                                        prepend-inner-icon="description"
                                        label="Nombre del banco:")
                                        
                                        v-text-field(id="cuenta"
                                        v-if='form_bank.categoria === "Nacional" || form_bank.categoria === "Internacional"'
                                        outline
                                        v-model="form_bank.cuenta"
                                        prepend-inner-icon="description"
                                        label="Número de cuenta:")

                                        v-text-field(id="tipo"
                                        v-if='form_bank.categoria === "Nacional"'
                                        outline
                                        v-model="form_bank.tipo"
                                        prepend-inner-icon="description"
                                        label="Tipo de cuenta:")

                                        v-text-field(id="correo"
                                        v-if='form_bank.categoria === "Nacional" || form_bank.categoria === "Internacional" || form_bank.categoria === "Zelle"'
                                        outline
                                        v-model="form_bank.correo"
                                        prepend-inner-icon="description"
                                        label="Correo electrónico:")

                                        div
                                          v-flex(xs12 sm12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                              v-btn(style="background:grey;border-radius:10px;", @click.native='save_bank') Guardar
                                              v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir

                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs5)
                        v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Cuentas Bancarias
                    v-data-table.elevation-1(:headers='headers', :items="bancos", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td(v-if='props.item.correo') {{ props.item.correo }}
                        td(v-else) - -
                        td(v-if='props.item.categoria') {{ props.item.categoria }}
                        td(v-else) - -
                        td(v-if='props.item.banco') {{ props.item.banco }}
                        td(v-else) - -
                        td(v-if='props.item.nombre') {{ props.item.nombre }}
                        td(v-else) - -
                        td(v-if='props.item.apellido') {{ props.item.apellido }}
                        td(v-else) - -
                        td.justify-start.layout.px-0
                          v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -8px;')
                              v-icon(color='teal') edit
                          v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -8px;')
                              v-icon(color='pink') delete

</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mSettings",
  data: () => ({
    active: null,
    ruta: server + ":" + port,
    search2: "",
    search: "",
    modalTime: false,
    modalTime2: false,
    diasel: [],
    horai: "",
    horaf: "",
    days: [
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
      "domingo"
    ],
    dialog: false,
    options: ["Nacional", "Internacional", "Zelle"],
    pagination: {
      sortBy: "action", // The field that you're sorting by
      descending: false
    },
    headers2: [
      { text: "Día", value: "day" },
      { text: "Bloques", value: "bloques" },
      { text: "Acción", value: "action" }
    ],
    headers: [
      { text: "Correo", value: "correo" },
      { text: "Categoría", value: "categoria" },
      { text: "Banco", value: "banco" },
      { text: "Nombre", value: "nombre" },
      { text: "Apellido", value: "apellido" },
      { text: "Acciones" }
    ],
    sw1: "No",
    sw2: "No",
    bancos: [],
    form_bank: {
      nombre: "",
      apellido: "",
      identificacion: "",
      tipo: "",
      cuenta: "",
      banco: "",
      categoria: "",
      correo: ""
    },
    defaultBank: {
      nombre: "",
      apellido: "",
      identificacion: "",
      tipo: "",
      cuenta: "",
      banco: "",
      categoria: "",
      correo: ""
    },
    form: {
      pedidos: false,
      delivery: false,
      direccion: "",
      telefono: "",
      correo: "",
      tlfDelivery: "",
      versionApp: "",
      versionAppIos: "",
      password: "",
      token: "",
      iva: "",
      moneda: "",
      instagram: "",
      facebook: "",
      whatsapp: "",
      horario: [],
      textwhatsapp: "",
      textcompartir: "",
      nwhatsapp: "",
      tasa: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    imagen: "",
    editedIndex: -1,
    settings: [],
    defaultForm: {
      pedidos: false,
      delivery: false,
      direccion: "",
      tlfDelivery: "",
      telefono: "",
      token: "",
      horario: [],
      versionApp: "",
      versionAppIos: "",
      correo: "",
      password: "",
      iva: "",
      instagram: "",
      facebook: "",
      whatsapp: "",
      moneda: "",
      textwhatsapp: "",
      textcompartir: "",
      tasa: "",
      nwhatsapp: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    }
  }),
  watch: {
    "form.pedidos": function(val) {
      if (val === true) {
        this.sw1 = "Si";
      } else {
        this.sw1 = "No";
      }
    },
    "form.delivery": function(val) {
      if (val === true) {
        this.sw2 = "Si";
      } else {
        this.sw2 = "No";
      }
    }
  },
  computed: {
    displayTime() {
      if (this.horai) {
        let time = this.horai.split(":");
        let hours = (time[0] > 12) ? time[0] - 12 : time[0];
        let ampm = (time[0] > 12) ? "pm" : "am";
        time = `${hours}:${time[1]} ${ampm}`;
        return time;
      }
      return null;
    },
    displayTime2() {
      if (this.horaf) {
        let time = this.horaf.split(":");
        let hours = (time[0] > 12) ? time[0] - 12 : time[0];
        let ampm = (time[0] > 12) ? "pm" : "am";
        time = `${hours}:${time[1]} ${ampm}`;
        return time;
      }
      return null;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    removerBloque(index, i) {
      this.form.horario[index].bloques.splice(i, 1);
    },
    remover(key) {
      this.form.horario.splice(key, 1);
    },
    prueba() {
      for (let i = 0; i < this.diasel.length; i++) {
        let bool = false;
        this.form.horario.map(el => {
          if (el.day && el.day === this.diasel[i]) {
            bool = true;
            el.bloques.push({
              inicio: this.horai,
              fin: this.horaf
            });
          }
        });
        if (!bool) {
          this.form.horario.push({
            day: this.diasel[i],
            bloques: [
              {
                inicio: this.horai,
                fin: this.horaf
              }
            ]
          });
        }
      }
      this.diasel = [];
      this.horai = "";
      this.horaf = "";
      this.modalTime = false;
      this.displayTime;
      this.displayTime2;
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let peso = false;
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true;
          }
        }
        if (peso === false) {
          this.form.imagen.imageName = files[0].name;
          if (this.form.imagen.imageName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.form.imagen.imageUrl = fr.result;
            this.form.imagen.imageFile = files[0]; // this is an image file that can be sent to server...
          });
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, la imagen debe pesar menos de 4MB.",
            "error"
          );
          this.files = [];
          this.form.images = [];
        }
      } else {
        this.form.imagen.imageName = "";
        this.form.imagen.imageFile = "";
        this.form.imagen.imageUrl = "";
      }
    },
    initialize() {
      this.editedIndex = -1;
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      Api.get("settings").then(res => {
        this.settings = res.data;
        if (this.settings.length > 0) {
          this.form.direccion = this.settings[0].direccion;
          this.form.telefono = this.settings[0].telefono;
          this.form.iva = this.settings[0].iva;
          this.form.moneda = this.settings[0].moneda;
          this.form.tlfDelivery = this.settings[0].tlfDelivery;
          this.form.instagram = this.settings[0].instagram;
          this.form.facebook = this.settings[0].facebook;
          this.form.token = this.settings[0].token;
          this.form.whatsapp = this.settings[0].whatsapp;
          this.form.versionApp = this.settings[0].versionApp;
          this.form.versionAppIos = this.settings[0].versionAppIos;
          this.form.correo = this.settings[0].correo;
          this.form.pedidos = this.settings[0].pedidos;
          if (this.settings[0].delivery) {
            this.form.delivery = this.settings[0].delivery;
          }
          this.form.password = this.settings[0].password;
          this.form.textcompartir = this.settings[0].textcompartir;
          this.form.nwhatsapp = this.settings[0].nwhatsapp;
          this.form.textwhatsapp = this.settings[0].textwhatsapp;
          this.form.tasa = this.settings[0].tasa;
          if (this.settings[0].horario && this.settings[0].horario.length > 0) {
            this.form.horario = this.settings[0].horario;
          }
          this.imagen = this.settings[0].imagen;
        }
      });
      Api.get("bancos").then(res => {
        this.bancos = res.data;
      });
    },
    actualizartasa() {
      Api.post("productos/tasa", this.form).then(res => {
        console.log(res);
      });
    },
    
    close() {
      this.form_bank = Object.assign({}, this.defaultBank);
      this.dialog = false;
    },
    editItem(item) {
      this.editedIndex = this.bancos.indexOf(item);
      Object.keys(this.form_bank).map(key => {
        if (item[key]) this.form_bank[key] = item[key];
      });
      this.form_bank._id = item._id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("bancos/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    save_bank() {
      if (this.form_bank.categoria) {
        if (
          (this.form_bank.categoria === "Nacional" &&
            this.form_bank.nombre &&
            this.form_bank.apellido &&
            this.form_bank.identificacion &&
            this.form_bank.tipo &&
            this.form_bank.cuenta &&
            this.form_bank.banco &&
            this.form_bank.correo) ||
          (this.form_bank.categoria === "Internacional" &&
            this.form_bank.nombre &&
            this.form_bank.apellido &&
            this.form_bank.banco &&
            this.form_bank.correo) ||
          (this.form_bank.categoria === "Zelle" &&
            this.form_bank.nombre &&
            this.form_bank.apellido &&
            this.form_bank.correo)
        ) {
          if (this.editedIndex > -1) {
            Api.put("bancos", this.form_bank).then(res => {
              this.form_bank = Object.assign({}, this.defaultBank);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.initialize();
            });
          } else {
            Api.post("bancos", this.form_bank).then(res => {
              this.form_bank = Object.assign({}, this.defaultBank);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.initialize();
            });
          }
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, debe rellenar todos los campos.",
            "error"
          );
        }
      } else {
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe seleccionar una categoría para continuar con la operación.",
          "error"
        );
      }
    },
    save() {
      if (this.settings.length > 0) {
        this.form._id = this.settings[0]._id;
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.settings[0].imagen;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile) {
          data.append("imagen", this.form.imagen.imageFile);
        }
        this.actualizartasa()
        Api.put("settings", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile)
          data.append("imagen", this.form.imagen.imageFile);
        Api.post("settings", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      }
    }
  }
};
</script>
