<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Supervision
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Supervisión de Empleados
        v-dialog(v-model='dialog2', persistent max-width='800px')
              v-container(style='background-color:white !important;max-width: 800px;')
                v-card-title.justify-center
                    span.headline Detalles
                div(style="margin-top: 20px;")
                    v-flex(xs12 sm12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-flex(xs12)
                              h3(v-if='vitacora.length === 0' style='display: flex;justify-content: center;margin-top: 0px;') No se han encontrado resultados
                              v-card(v-for='item in vitacora', style='margin-bottom:5px;border-radius:10px').elevation-2
                                v-flex(xs12)
                                  div(v-if='!item.descripcion.antes' style='padding-bottom:10px') 
                                    div(style='position: relative')
                                      div(style='display:flex;justify-content: center;')
                                        span(style='position: absolute;display: flex;justify-content: center;font-weight: 500;text-decoration: underline;margin-bottom: 10px;padding-top:10px') Registro
                                      div(style='display:flex;justify-content: flex-end;')
                                        span(style='font-size: 13px;margin-bottom: 10px;padding-top: 15px;padding-right: 10px;') {{item.fecha | moment('DD-MM-YYYY h:mm A')}}
                                    span(style='margin: 10px;') {{item.descripcion.despues}}
                                  div(v-if='item.descripcion.antes && item.descripcion.antes.length > 0')
                                    v-flex(xs12)
                                      div(style='position: relative')
                                        div(style='display:flex;justify-content: center;')
                                          span(style='position: absolute;display: flex;justify-content: center;font-weight: 500;text-decoration: underline;margin-bottom: 10px;padding-top:10px') Modificación
                                        div(style='display:flex;justify-content: flex-end;')
                                          span(style='font-size: 13px;margin-bottom: 10px;padding-top: 15px;padding-right: 10px;') {{item.fecha | moment('DD-MM-YYYY h:mm A')}}
                                      v-layout(wrap style='margin: 10px;padding-bottom: 10px;')
                                        v-flex(xs6)
                                          div(v-for='item2 in item.descripcion.antes')
                                            span <b>(Antes)</b> {{item2}}
                                        v-flex(xs6)
                                          div(v-for='item3 in item.descripcion.despues')
                                            span <b>(Despues)</b>  {{item3}}
                              div
                                v-flex(xs12 sm12)
                                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:#01458c;border-radius:10px;", @click.native='close2') Salir
    template
        v-row(justify='center')
            v-dialog(v-model='dialog' fullscreen='' hide-overlay='' transition='dialog-bottom-transition')
                v-card
                    v-toolbar(dark='' color='#01458c')
                        v-btn(icon='' dark='' @click='close')
                            v-icon(style='color:white') close
                        v-toolbar-title(style='color:white') Detalle
                        v-spacer
                    v-list(three-line='' subheader='')
                        v-subheader Registros
                        v-flex(xs12)
                            v-layout(wrap)
                                a(v-for='item in iniciosres' @click="verd(item)")
                                    v-card.dbv.elevation-3
                                        v-flex(xs12)
                                            v-layout(wrap)
                                                v-flex(xs12 style='margin-bottom:10px')
                                                    div.centrar
                                                        span(style='font-weight: 500;text-decoration: underline;') {{item[0].empleado.nombre}} {{item[0].empleado.apellido}}
                                                v-flex(xs12)
                                                    div(v-if='item[0].tipo === "Cierre de sesión"')
                                                        v-flex(xs12).centrar
                                                            span(style='font-size:15px' v-if='item.length > 1') {{item[1].tipo}}: {{item[1].fecha | moment('DD-MM-YYYY / h:mm A')}}
                                                        v-flex(xs12).centrar
                                                            span(style='font-size:15px') {{item[0].tipo}}: {{item[0].fecha | moment('DD-MM-YYYY / h:mm A')}}
                                                    div(v-else)
                                                        v-flex(xs12).centrar
                                                            span(style='font-size:15px') {{item[0].tipo}}: {{item[0].fecha | moment('DD-MM-YYYY / h:mm A')}}
                                                        v-flex(xs12).centrar
                                                            span(style='font-size:15px' v-if='item.length > 1') {{item[1].tipo}}: {{item[1].fecha | moment('DD-MM-YYYY / h:mm A')}}
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:50%;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Empleados
        v-data-table.elevation-1(:headers='headers', :items="empleados", :search='search2', style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.nombre }}
            td {{ props.item.apellido }}
            td {{ props.item.email }}
            td.justify-start.layout.px-0
                    v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                        v-icon(color='teal') visibility
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mInicios",
  data: () => ({
    search2: '',
    form: {
      nombre: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    dialog: false,
    dialog2: false,
    inicios: [],
    iniciosres: [],
    empleados: [],
    vitacora: [],
    ruta: server + ":" + port,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Apellido", value: "apellido" },
      { text: "Usuario", value: "email" },
      { text: "Acciones" }
    ],
    editedIndex: -1,
    defaultForm: {
      nombre: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    verd(item) {
      if (item.length === 1) {
        Api.post('productos/supervision_single', item).then(res=>{
          this.vitacora = res.data
          this.dialog2 = true
        })
      }
      if (item.length === 2) {
        Api.post('productos/supervision_double', item).then(res=>{
          this.vitacora = res.data
          this.dialog2 = true
          console.log(res.data)
        })
      }
    },
    initialize() {
      Api.get("inicios").then(res => {
        let unicos = []
        this.inicios = res.data;
        let datos = []
        for (let i = 0; i < res.data.length; i++) {
            datos.push(res.data[i].empleado)
        }
        datos.filter(el=>{
            let i = unicos.findIndex(x=>x._id===el._id)
            if(i===-1) {
                unicos.push(el)
            }
        })
        this.empleados = unicos
      });
    },
    editItem(item) {
        this.iniciosres = []
        let datos = []
        for (let i = 0; i < this.inicios.length; i++) {
            if (item._id === this.inicios[i].empleado._id) {
                datos.push(this.inicios[i])
            }
        }
        let it = []
        let cont = 0
        for (let i = 0; i < datos.length; i++) {
            if (i === 0 && datos[i].tipo === 'Inicio de sesión') {
              let dt = []
              dt.push(datos[i])
              this.iniciosres.push(dt)
            } else {
                it.push(datos[i])
                cont++
                if (cont === 2) {
                    this.iniciosres.push(it)
                    it = []
                    cont = 0
                }
            }
        }
        this.dialog = true;
    },
    close2() {
      this.dialog2 = false
    },
    close() {
      this.dialog = false;
      this.initialize();
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/supervision.scss";
</style>

