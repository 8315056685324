<template lang="pug">
v-container
  v-layout(align-end='', justify-center='', text-xs-center='', wrap='')
    v-flex(xs12='' style='margin-top:0px;')
      img(src='../assets/logo3.jpeg', with='400' ,height='400' style='border-radius:10px')
    v-flex(mb-4='')
      h1.display-2.font-weight-bold.mb-3
        | Bienvenido
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  data: () => ({
    ruta: server + ":" + port,
    settings: {},
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader"
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify"
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify"
      }
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com"
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com"
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuetifyjs.com"
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs"
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify"
      }
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer"
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/layout/pre-defined"
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions"
      }
    ]
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      Api.get("settings").then(res => {
        this.settings = res.data[0];
      });
    }
  }
};
</script>

<style></style>
