<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Categoria
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Categorias
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#01458c", centered, dark)
          v-tab(:key='0', ripple='') Creación
          v-tab(v-if="mod"  :key='1', ripple='') Posicionamiento
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                v-dialog(v-model='dialog', persistent max-width='700px')
                      v-btn.mb-2(v-if="cre" slot='activator', style="border-radius:5px;background-color:#01458c;") Nueva Categoria
                      v-container(style='background-color:white !important;max-width: 700px;')
                        v-card-title.justify-center
                            span.headline Categoria
                        div(style="margin-top: 20px;")
                            v-flex(xs12 sm12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-flex(xs6)
                                      v-form(enctype="multipart/form-data")
                                        v-text-field(id="nombre"
                                        outline
                                        v-model="form.nombre"
                                        prepend-inner-icon="description"
                                        label="Nombre:")

                                        v-text-field(id="idcategoria"
                                        outline
                                        v-model="form.idcat"
                                        prepend-inner-icon="idcategoria"
                                        label="Id Categoria:")

                                        img(:src='ruta + img', height='150', width='310', v-if='img && !form.imagen.imageUrl')
                                        img(:src='form.imagen.imageUrl', height='150', width='310', v-if='form.imagen.imageUrl')
                                        v-text-field(outline label='Seleccione la imagen', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file')
                                        input(
                                          type="file"
                                          style="display: none;"
                                          ref="image"
                                          accept="image/*"
                                          @change="onFilePicked")

                                        v-container(fluid='')
                                          span Posición de la categoría
                                          v-row
                                            v-col(cols='12' sm='4' md='4')
                                              v-checkbox(v-model='form.dashboard' label='Dashboard' color='indigo' hide-details='')
                                            v-col(cols='12' sm='4' md='4')
                                              v-checkbox(v-model='form.menu' label='Menu' color='indigo' hide-details='')
                                            v-col(cols='12' sm='4' md='4')
                                              v-checkbox(v-model='form.barra_sup' label='Barra superior' color='indigo' hide-details='')

                                        div
                                          v-flex(v-if="loading === true", xs12 sm12 md12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                              v-progress-circular(indeterminate='', color='primary')
                                          v-flex(v-if="loading === false", xs12 sm12 md12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                              v-btn(style="background:grey;border-radius:10px;", @click.native='save') Guardar
                                              v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir

                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs5)
                        v-select(:items='items3' v-model="seleccion" label='Filtro de busqueda' outlined='' style='width:100%')
                      v-flex(xs2)
                      v-flex(xs5)
                        v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Categorias
                    v-data-table.elevation-1(:headers='headers', :items="categoria", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td {{ props.item.nombre }}
                        td(v-if='props.item.dashboard && props.item.dashboard === true') Si
                        td(v-if='props.item.dashboard && props.item.dashboard === false') No
                        td(v-if='!props.item.dashboard') No
                        td(v-if='props.item.menu && props.item.menu === true') Si
                        td(v-if='props.item.menu && props.item.menu === false') No
                        td(v-if='!props.item.menu') No
                        td(v-if='props.item.barra_sup && props.item.barra_sup === true') Si
                        td(v-if='props.item.barra_sup && props.item.barra_sup === false') No
                        td(v-if='!props.item.barra_sup') No
                        td(v-if='props.item.imagen')
                          img(:src='ruta + props.item.imagen', width='60', height='60', style='margin-top: 6px;')
                        td(v-else)
                          img(src='../assets/sf.png', width='60', height='60', style='margin-top: 6px;')
                        td.justify-start.layout.px-0
                                v-btn.mx-0(v-if="mod" icon, @click='editItem(props.item)', style='margin-top: 10px;')
                                    v-icon(color='teal') edit
                                v-btn.mx-0(v-if="elm" icon, @click='deleteItem(props.item)', style='margin-top: 10px;')
                                    v-icon(color='pink') delete
          v-tab-item(v-if="mod" :key='1')
            v-card(flat='')
              v-flex(xs12)
                v-layout(row wrap)
                  v-flex(md12 xs12, sm12)
                    v-card(style='border-radius: 10px;')
                      v-layout(row wrap)
                        v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                          h3 Orden de las categorías
                        v-flex(md4 sm4 xs4)
                          v-card(style='border-radius: 10px;margin: 10px;')
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              h3 Dashboard
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              draggable(:list="groups"  @update="checkGroups"  style='width:95%')     
                                div.item(v-for="(item, key) in groups" @mouseover="drage(item)" @mouseleave="leave(item)").divk
                                  v-icon.drag(v-if="item._id == drag._id") drag_indicator
                                  span.txth {{item.nombre}}
                                  div.menu(@mouseover="seleccionado(item)" @mouseleave="leave(item)")
                                    v-icon.icon fas fa-caret-down
                        v-flex(md4 sm4 xs4)
                          v-card(style='border-radius: 10px;margin: 10px;')
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              h3 Menu
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              draggable(:list="groups2"  @update="checkGroups2"  style='width:95%')     
                                div.item(v-for="(item, key) in groups2" @mouseover="drage(item)" @mouseleave="leave(item)").divk
                                  v-icon.drag(v-if="item._id == drag._id") drag_indicator
                                  span.txth {{item.nombre}}
                                  div.menu(@mouseover="seleccionado(item)" @mouseleave="leave(item)")
                                    v-icon.icon fas fa-caret-down
                        v-flex(md4 sm4 xs4)
                          v-card(style='border-radius: 10px;margin: 10px;')
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              h3 Barra Superior
                            v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                              draggable(:list="groups3"  @update="checkGroups3"  style='width:95%')     
                                div.item(v-for="(item, key) in groups3" @mouseover="drage(item)" @mouseleave="leave(item)").divk
                                  v-icon.drag(v-if="item._id == drag._id") drag_indicator
                                  span.txth {{item.nombre}}
                                  div.menu(@mouseover="seleccionado(item)" @mouseleave="leave(item)")
                                    v-icon.icon fas fa-caret-down
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
import draggable from 'vuedraggable'
export default {
  name: "mCategoria",
  components: {
    draggable
  },
  data: () => ({
    search2: '',
    active: null,
    groups: [],
    groups2: [],
    loading: false,
    groups3: [],
    drag: [],
    form: {
      nombre: "",
      dashboard: false,
      menu: false, 
      barra_sup: false,
      orden_dash: '',
      orden_menu: '',
      orden_sup: '',
      idcat: '',
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    dialog: false,
    categoria: [],
    categoriares: [],
    seleccion: '',
    ruta: server + ":" + port,
    img: "",
    headers: [
      { text: "Nombre", value: "nombre"},
      { text: "Dashboard", value: "dashboard"},
      { text: "Menu", value: "menu"},
      { text: "Barra Superior", value: "barra_sup"},
      { text: "Imagen", value: "imagen"},
      { text: "Acciones" }
    ],
    items3: ["Todos", "Dashboard", "Menu", "Barra Superior"],
    editedIndex: -1,
    defaultForm: {
      nombre: "",
      idcat: '',
      dashboard: false,
      menu: false, 
      barra_sup: false,
      orden_dash: '',
      orden_menu: '',
      orden_sup: '',
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    cre:"",
    mod:"",
    elm:""
  }),
  created() {
    this.verificacion()
    this.initialize();
  },
  watch: {
    seleccion() {
      if (this.seleccion === "Dashboard") {
        this.categoria = []
        for (let i = 0; i < this.categoriares.length; i++) {
          if (this.categoriares[i].dashboard === true) {
            this.categoria.push(this.categoriares[i])
          }
        }
      }
      if (this.seleccion === "Menu") {
        this.categoria = []
        for (let i = 0; i < this.categoriares.length; i++) {
          if (this.categoriares[i].menu === true) {
            this.categoria.push(this.categoriares[i])
          }
        }
      }
      if (this.seleccion === "Barra Superior") {
        this.categoria = []
        for (let i = 0; i < this.categoriares.length; i++) {
          if (this.categoriares[i].barra_sup === true) {
            this.categoria.push(this.categoriares[i])
          }
        }
      }
      if (this.seleccion === "Todos") {
        this.categoria = this.categoriares
      }
    },
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Categorías"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    drage (data) {
      this.drag = data
    },
    leave () {
      this.drag = []
      this.selected = []
    },
    seleccionado (data) {
      this.selected = data
    },
    checkGroups (data) {
      for (let i = 0; i < this.groups.length; i++) {
        this.groups[i].orden_dash = i + 1
      }
      Api.put("categoria/ordenar", this.groups).then(res=>{
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    checkGroups2 (data) {
      for (let i = 0; i < this.groups2.length; i++) {
        this.groups2[i].orden_menu = i + 1
      }
      Api.put("categoria/ordenar", this.groups2).then(res=>{
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    checkGroups3 (data) {
      for (let i = 0; i < this.groups3.length; i++) {
        this.groups3[i].orden_sup = i + 1
      }
      Api.put("categoria/ordenar", this.groups3).then(res=>{
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let peso = false;
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true;
          }
        }
        if (peso === false) {
          this.form.imagen.imageName = files[0].name;
          if (this.form.imagen.imageName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.form.imagen.imageUrl = fr.result;
            this.form.imagen.imageFile = files[0]; // this is an image file that can be sent to server...
          });
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, la imagen debe pesar menos de 4MB.",
            "error"
          );
          this.files = [];
          this.form.images = [];
        }
      } else {
        this.form.imagen.imageName = "";
        this.form.imagen.imageFile = "";
        this.form.imagen.imageUrl = "";
      }
    },
    initialize() {
      Api.get("categoria/admin").then(res => {
        console.log(res.data)
        this.categoria = []
        this.categoriares = []
        this.groups = []
        this.groups2 = []
        this.groups3 = []
        for (let i = 0; i < res.data.length; i++) {
          this.categoria.push(res.data[i]);
          this.categoriares.push(res.data[i]);
          if (res.data[i].dashboard === true) {
            this.groups.push(res.data[i]);
          }
          if (res.data[i].menu === true) {
            this.groups2.push(res.data[i]);
          }
          if (res.data[i].barra_sup === true) {
            this.groups3.push(res.data[i]);
          }
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.categoria.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.imagen) {
        this.img = item.imagen;
      }
      this.form.imagen = {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      };
      this.form._id = item._id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("categoria/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    close() {
      this.img = ""
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    save() {
      this.loading = true
      this.form.nombre = this.form.nombre.toLowerCase()
      if (!this.form.imagen.imageFile) {
        this.form.imagen = this.img;
      }
      const data = new FormData();
      Object.keys(this.form).map(key => {
        if (Array.isArray(this.form[key])) {
          this.form[key].forEach(val => {
            if (typeof val === "object" && val !== null) {
              return data.append(`${key}[]`, JSON.stringify(val));
            }
            return data.append(`${key}[]`, val);
          });
        } else if (
          typeof this.form[key] === "object" &&
          this.form[key] !== null
        ) {
          return data.append(key, JSON.stringify(this.form[key]));
        } else {
          return data.append(key, this.form[key]);
        }
      });
      if (this.form.imagen.imageFile)
        data.append("imagen", this.form.imagen.imageFile);
      if (this.editedIndex > -1) {
        Api.put("categoria", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.close()
            this.loading = false
            this.initialize();
          })
          .catch(err => {
            this.loading = false
            console.log(err);
            this.$message.error("A ocurrido un error." + err);
          });
      } else {
        Api.post("categoria", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.close()
            this.loading = false
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false
            this.$message.error("A ocurrido un error." + err);
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/categoria.scss";
</style>
