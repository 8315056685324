<template lang="pug">
  v-app
    Menu(v-if='sesion._id')
    v-content
      div(v-if='mostrar' style='width: 40%;display: flex;padding: 10px;background: #01458c;position: absolute;z-index: 1000;bottom:5px;right: 5px;border-radius: 10px;').elevation-3
        div(v-if='info.termino' style='position:absolute;z-index:1000;top:-8px;right:0')
          v-btn(icon @click='mostrar = false, police = false')
            v-icon(color='white' style='font-size: 26px;') cancel
        v-layout(wrap style='position:relative')
          v-flex(xs2 style='display: flex;justify-content: center;align-items: center;padding: 10px;')
            v-progress-circular(v-if='!info.termino' indeterminate='', color='white')
            v-icon(v-if='info.termino' color='white' style='font-size: 30px;') check
          v-flex(xs10 style='display: flex;flex-direction: column;text-align:start;')
            span(style='color:white;') Mensajes enviados {{info.cont}}/{{info.total}}
            span(style='color:white;') Mensajes no enviados {{info.contE}}/{{info.total}}
          v-flex(v-if='info && (info.message || info.success)' xs12 style='display:flex;justify-content:center;margin-top:5px;margin-bottom:5px;')
            div(style='height:1px;width:95%;background:white;')
          v-flex(v-if='info && (info.message || info.success)' xs12 style='text-align:center;')
            span(style='color:white;' v-if='info.message') {{info.message}}
            span(style='color:white;' v-if='info.success') {{info.success}}
      .container
        router-view

</template>

<script>
import Menu from "./components/menu.vue";
import Api from "@/services/methods";
export default {
  name: "App",
  components: { Menu },
  data: () => ({
    mostrar: false,
    info: {},
    token: "",
    police: false,
    sesion: []
  }),
  sockets: {
    confSMS(data) {
      if (data.empresa && data.empresa.toString() === this.token.toString()) {
        if (!this.police) {
          this.police = data.termino;
          this.info = data;
          this.mostrar = true;
        }
      } else {
        this.police = false;
        this.mostrar = false;
      }
    }
  },
  created() {
    // this.$store.commit("load", "");
    this.sesion = this.$store.state.merkafur;
    if (this.$route.meta.isPublic === false && !this.$store.state.token) {
      window.location.href = "/";
    }
    this.tokken();
  },
  methods: {
    tokken() {
      Api.get("settings").then(res => {
        if (res.data && res.data[0]) {
          this.token = res.data[0].token;
        }
      })
    },
    logout() {
      this.$store.dispatch("logout");
      this.$cookies.set("token", [], "5D", "");
      window.location.href = "/";
    }
  }
};
</script>
<style lang="sass">
@import '@/styles/index.scss';
</style>
<style>
::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #d5d5d5;
}
::-webkit-scrollbar-thumb:hover {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
  background-color: #24242494; 
}
</style>
