<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #pagos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: #01458c;")
            v-card-title.justify-center
              img(src='../assets/logo3.jpeg', with='60' ,height='60')
        v-card(color='', style="border-radius:5px;background-color: #d6d6d6")
            v-card-title(style="padding: 7px;").justify-center
                span.black--text  Administración de Pago de Caja
    v-dialog(v-model='dialog', persistent max-width='1028px')
      v-container(style='background-color:white !important;max-width: 1028px;')
        v-flex(xs12)
            template
                v-card(flat='')
                    v-flex(xs12 md12 sm12 style='margin-top:10px')
                        v-layout(row, wrap)
                            v-flex(xs12 style='display: flex;justify-content: center;align-items: center;')
                                div(style='display: flex;align-items: center;')
                                img(src='../assets/logo3.jpeg', with='60' ,height='60' style='border-radius:10px')
                                span(style='font-size: 30px;font-weight: 600;padding-left: 10px;') Merkafur
                            v-flex(xs12 style='height: 60px;margin-top: 20px;display: flex;justify-content: center;align-items: center;background: rgb(191, 190, 190);')
                                span(style='font-size: 25px;font-weight: 500;') {{form.categoria}}
                            v-flex(xs12 style='height: 60px;margin-top: 20px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Nombre: 
                                    v-flex(xs4)
                                        span {{form.nombre}} {{form.apellido}}
                                    v-flex(xs2)
                            v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Identificación: 
                                    v-flex(xs4)
                                        span {{form.identificacion}}
                                    v-flex(xs2)
                            v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Correo: 
                                    v-flex(xs4)
                                        span {{form.correo}}
                                    v-flex(xs2)
                            v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Teléfono: 
                                    v-flex(xs4)
                                        span {{form.telefono}}
                                    v-flex(xs2)
                            v-flex(xs12 style='height: 60px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Referencia: 
                                    v-flex(xs4)
                                        span {{form.referencia}}
                                    v-flex(xs2)
                            v-flex(xs12 v-if='form.banco' style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Banco: 
                                    v-flex(xs4)
                                        span {{form.banco}}
                                    v-flex(xs2)
                            v-flex(xs12 v-if='form.banco' style='height: 60px;display: flex;justify-content: center;align-items: center;background: #f3f3f3')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Monto: 
                                    v-flex(xs4)
                                        span {{form.monto}}
                                    v-flex(xs2)
                            v-flex(xs12 v-if='!form.banco' style='height: 60px;display: flex;justify-content: center;align-items: center;background: #fff')
                                v-layout(row, wrap style='line-height: 1;')
                                    v-flex(xs2)
                                    v-flex(xs4 style='display: flex;justify-content: flex-end;padding-right: 20px;')
                                        span(style='font-weight: 400;') Monto: 
                                    v-flex(xs4)
                                        span {{form.monto}}
                                    v-flex(xs2)
                            v-flex(xs12 v-if='form.image')
                                img(:src='ruta + form.image', style='width:100%')
                    v-flex(xs12 sm12 style='margin-top:20px')
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-btn(v-if='form.estatus === "Pendiente" && mod' style="background:green;border-radius:10px;", @click.native='save()') Aprobar
                            v-btn(v-if='form.estatus === "Pendiente" && mod' style="background:red;border-radius:10px;", @click.native='rech()') Rechazar
                            v-btn(style="background:#01458c;border-radius:10px;", @click.native='close') Salir
    div(style="margin-top:20px")
      v-flex(xs12)
        v-layout(wrap)
          v-flex(xs5)
            v-select(:items='items3' v-model="seleccion" label='Filtro de busqueda' outlined='' style='width:100%')
          v-flex(xs2)
          v-flex(xs5)
            v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:100%;')
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:#01458c;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Pagos
        v-data-table.elevation-1(:headers='headers', :items="pagos", :pagination.sync="pagination", :search='search', style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.fecha') {{ props.item.fecha | moment("DD-MM-YYYY") }}
            td(v-else) - -
            td(v-if='props.item.nombre') {{props.item.nombre}} {{props.item.apellido}}
            td(v-else) - -
            td(v-if='props.item.identificacion') {{ props.item.identificacion }}
            td(v-else) - -
            td(v-if='props.item.categoria') {{ props.item.categoria}}
            td(v-else) - -
            td(v-if='props.item.estatus') {{ props.item.estatus}}
            td.justify-start.layout.px-0
                v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                    v-icon(v-if="mod" color='teal') edit
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mOrden",
  data: () => ({
    search: '',
    items3: ["Todos", "Pendiente", "Aprobado", "Rechazado"],
    seleccion: '',
    form: {
        nombre              : "",
        apellido            : "",
        identificacion      : "",
        tipo                : "",
        banco               : "",
        categoria           : "",
        correo              : "",
        referencia          : "",
        fecha               : "",
        estatus             : "",
        cajero              : "",
        monto               : "",
        image               : "",
        nota: "",
        telefono: ""
    },
    ruta: server + ":" + port,
    dialog: false,
    pagination: {
      sortBy: 'fecha', // The field that you're sorting by
      descending: true
    },
    pagos:[],
    pagosres:[],
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Cliente", value: "nombre" },
      { text: "Identificación", value: "identificacion"},
      { text: "Tipo de pago", value: "categoria"},
      { text: "Estatus", value: "state" },
      { text: "Acciones"}
    ],
    editedIndex: -1,
    defaultForm: {
        nombre              : "",
        apellido            : "",
        identificacion      : "",
        tipo                : "",
        banco               : "",
        categoria           : "",
        correo              : "",
        referencia          : "",
        fecha               : "",
        estatus             : "",
        cajero              : "",
        monto               : "",
        image               : "",
        nota: "",
        telefono: ""
    },
    cre: "",
    mod: "",
    elm:""
  }),
  sockets: {
    pag(data) {
      this.initialize();
    }
  },
  watch: {
    seleccion() {
      if (this.seleccion === "Todos") {
        this.pagos = this.pagosres
      } else {
        this.pagos = []
        for (let i = 0; i < this.pagosres.length; i++) {
          if (this.pagosres[i].estatus === this.seleccion) {
            this.pagos.push(this.pagosres[i])
          }
        }
      }
    },
  },
  created() {
    this.verificacion()
    this.initialize();
  },
  methods: {
    par(data) {
      if (data % 2==0) {
          return false
      } else {
          return true
      }
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
        if (
          this.$store.state.merkafur.permisos[i].ruta ===
          "Pagos"
        ) {
          for (
            let j = 0;
            j < this.$store.state.merkafur.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.merkafur.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      Api.get("pagos/cajero").then(res => {
        this.pagos = res.data
        this.pagosres = res.data
      });
    },
    rech() {
      this.$swal.fire({
        title: "Motivo de Rechazo",
        input: 'textarea',
        inputPlaceholder: 'Ingrese el mensaje aquí...',
        inputAttributes: {
          'aria-label': 'Ingrese el mensaje aquí'
        },
        confirmButtonColor: "green",
        cancelButtonColor: "#01458c",
        showCancelButton: true
      })
      .then(result=>{
        if (result.value) {
          let info = {
            _id: this.form._id,
            nota: result.value,
            estatus: "Rechazado"
          }
          Api.put('pagos', info).then(res=>{
            this.$swal.fire(
              "Felicidades.!",
              "Pago rechazado exitosamente.",
              "success"
            );
            this.$socket.emit('actualizar_caja','h')
            this.close()
            this.initialize()
          }).catch(err=>{
            console.log(err)
            this.$swal.fire(
              "Oops... Error encontrado",
              "Problemas de conexión, intente nuevamente o contacte con el administrador.",
              "error"
            );
          })
        } else {
          if (!result.value && !result.dismiss) {
            this.$swal.fire({
              title: "Oops... Error encontrado",
              text: "Por favor ingrese una nota para continuar",
              type: "error",
            }).then(res=>{
              this.rech()
            })
          }
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.pagos.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    save() {
        let info = {
            _id: this.form._id,
            estatus: "Aprobado"
        }
        Api.put("pagos", info).then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Pago aprobado exitosamente.",
              "success"
            );
            this.$socket.emit('actualizar_caja','h')
            this.initialize();
        })
        .catch(err => {
            console.log(err);
            this.$message.error("A ocurrido un error." + err);
        });
    }
  }
};
</script>
<style lang="scss">
  .active{
      background: #fff !important;
  }
  .pasive {
    border-bottom: 1px solid !important;
  }
</style>