<template lang="pug">
  v-navigation-drawer#app-drawer(:mini-variant='mini', v-model='inputValue', app='', dark='', floating='', persistent='', permanent='', mobile-break-point='991', width='260')
    v-list
      v-list-tile(@click.stop='mini = !mini')
        v-list-tile-action
          v-icon menu
        v-list-tile-title {{ nombre }} {{ apellido }}
      v-list-tile(avatar='', tag='div')
        //- div(style='margin-left:45px')
        //-   img(v-if='mini === false',src='../assets/logo2.png', width='130' ,height='70')
        div(style='margin-left:0px')
          img(v-if='mini === true',src='../assets/logo3.jpeg', with='40' ,height='40', style='margin-left:20px;border-radius:10px')
    v-layout.fill-height(tag='v-list', column='')
      v-divider
      v-list-tile.v-list-item(v-for='(link, i) in items', :key='i', active-class='secundary', :to='link.link', avatar='' , v-if='link.permiso === mostrar(link.permiso)')
        v-list-tile-action
          v-icon.mgl {{ link.icon }}
        v-list-tile-title(v-text='link.title')
      v-list-tile.v-list-item(active-class='secundary', @click='logout' ,avatar='')
        v-list-tile-action
          v-icon.mgl lock_open
        v-list-tile-title Cerrar de sesión
</template>

<script>
import Api from "@/services/methods";
import { server, port } from "@/services/environment";
export default {
  name: "mHeader",
  data: () => ({
    ruta: server + ":" + port,
    chf: false,
    nombre: "",
    apellido: "",
    drawer: true,
    inputValue: true,
    mini: true,
    image:
      "https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg",
    items: [
      {
        icon: "home",
        title: "Inicio",
        link: "/home",
        requiresAuth: true
      },
      {
        icon: "poll",
        title: "Dashboard",
        link: "/dashboard",
        requiresAuth: true
      },
      {
        icon: "mail",
        title: "SMS",
        link: "/directorio",
        requiresAuth: true
      },
      {
        icon: "computer",
        title: "Cajero",
        link: "/cajero",
        permiso: "Cajero",
        requiresAuth: true
      },
      {
        icon: "group",
        title: "Clientes",
        link: "/clientes",
        permiso: "Clientes",
        requiresAuth: true
      },
      {
        icon: "alarm",
        title: "Supervisión de Emp.",
        permiso: "Supervisión de Empleados",
        link: "/supervision",
        requiresAuth: true
      },
      {
        icon: "shopping_cart",
        title: "Productos",
        permiso: "Productos",
        link: "/productos",
        requiresAuth: true
      },
      {
        icon: "shopping_cart",
        title: "Traslados",
        permiso: "Traslados",
        link: "/traslados",
        requiresAuth: true
      },
      {
        icon: "shopping_cart",
        title: "Inventario",
        permiso: "Inventario",
        link: "/inventario",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Categorías",
        permiso: "Categorías",
        link: "/categoria",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Subcategorías",
        permiso: "Categorías",
        link: "/subcategoria",
        requiresAuth: true
      },
      {
        icon: "image",
        title: "Banners",
        permiso: "Banners",
        link: "/banners",
        requiresAuth: true
      },
      {
        icon: "add_photo_alternate",
        title: "Historias",
        permiso: "Historias",
        link: "/historias",
        requiresAuth: true
      },
      {
        icon: "attach_money",
        title: "Pagos en Caja",
        permiso: "Pagos",
        link: "/pagos",
        requiresAuth: true
      },
      {
        icon: "local_shipping",
        title: "Ordenes",
        permiso: "Ordenes",
        link: "/orden",
        requiresAuth: true
      },
      // {
      //   icon: "person",
      //   title: "Clientes",
      //   link: "/clientes",
      //   requiresAuth: true
      // },
      {
        icon: "person",
        title: "Empleados",
        permiso: "Empleados",
        link: "/permisos",
        requiresAuth: true
      },
      {
        icon: "add_location",
        title: "Geocerca",
        permiso: "Geocerca",
        link: "/geocerca",
        requiresAuth: true
      },
      {
        icon: "assignment",
        title: "Reporte de Productos",
        permiso: "Reporte",
        link: "/reporte",
        requiresAuth: true
      },
      {
        icon: "settings",
        title: "Settings",
        permiso: "Configuraciones",
        link: "/settings",
        requiresAuth: true
      }
    ]
  }),
  created() {
    this.initialize();
    if (this.$store.state.merkafur) {
      this.nombre = this.$store.state.merkafur.nombre;
      this.apellido = this.$store.state.merkafur.apellido;
    }
    // this.$store.commit("load", "");
    // this.sesion = this.$store.state.merkafur;
  },
  computed: {
    login() {
      return this.$store.state.isUserLoggedIn;
    }
  },
  methods: {
    initialize() {},
    mostrar(item) {
      if (this.$store.state.merkafur.permisos) {
        for (let i = 0; i < this.$store.state.merkafur.permisos.length; i++) {
          if (this.$store.state.merkafur.permisos[i].ruta === item) {
            return this.$store.state.merkafur.permisos[i].ruta;
          }
        }
      }
    },
    showMenu(item) {
      if (this.$store.state.isUserLoggedIn && item.requiresAuth) {
        return true;
      } else if (!item.requiresAuth && !this.$store.state.isUserLoggedIn) {
        return true;
      }
      return false;
    },
    logout() {
      let info = {
        empleado: this.$store.state.merkafur._id,
        tipo: 'Cierre de sesión'
      }
      Api.post('inicios', info).then(res=>{
        this.$store.dispatch("logout");
        this.$cookies.set("token", [], "5D", "");
        window.location.href = "/";
        console.log('Registro exitoso')
      })

    }
  }
};
</script>
<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }
  .fill-height {
    height: auto !important;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .primary--text {
    color: white !important;
    caret-color: white !important;
  }
  .txt {
    height: 48px !important;
    font-size: 14px !important;
    font-weight: 300;
    padding: 9px;
    margin-left: -3px;
  }
  .brd {
    border-radius: 5px;
  }
  .v-list__group__header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    border-radius: 5px;
  }
  .mtd {
    margin-left: 12px;
    margin-right: 6px;
  }
  .mgl {
    margin-left: -4px;
  }
}
.v-navigation-drawer
  .v-list
  .v-list-item
  .v-list__tile.v-list__tile--active.secundary {
  background-color: #01458c !important;
  -webkit-box-shadow: 0 12px 20px -10px rgba(97, 159, 122, 0.28),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(97, 159, 122, 0.2) !important;
  box-shadow: 0 12px 20px -10px rgba(97, 159, 122, 0.28),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(97, 159, 122, 0.2) !important;
}
</style>
